import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Select, MenuItem, FormControl } from '@mui/material';

const useStyles = makeStyles({
  control: (props) => ({
    width: props.width,
  }),
});

const timezones = {
  asia: 'Asia/Manila',
  bogota: 'America/Bogota',
  us: 'America/New_York',
};

const Dropdown = ({ width, timezone, setPreferences }) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState(timezone);

  const handleChange = (event) => {
    const selectedValue = event.target.value;

    setValue(selectedValue);
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      timezone: selectedValue,
    }));
  };

  useEffect(() => {
    setValue(timezone);
  }, [timezone]);

  return (
    <FormControl className={classes.control}>
      <Select size="small" value={value} onChange={handleChange}>
        <MenuItem value={timezones.us}>Eastern Time (US & Canada)</MenuItem>
        <MenuItem value={timezones.asia}>Manila</MenuItem>
        <MenuItem value={timezones.bogota}>Bogota</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Dropdown;
