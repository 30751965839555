import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/styles';
import { Box, Typography, Paper, Chip } from '@mui/material';
import { useFirmTasks } from '../../../../../../context/firm_tasks';
import { usePermission } from '../../../../../../context/permission';

const useStyles = makeStyles({
  box: {
    flexBasis: (props) =>
      props.showPageCount
        ? props.angelUser
          ? '35%'
          : '40%'
        : props.angelUser
        ? '40%'
        : '55%',
    marginRight: '2px',
  },
  paper: {
    padding: '10px',
    minHeight: '44px',
    height: '100%',
  },
  typography: {
    paddingRight: 15,
    whiteSpace: 'normal',
  },
  chip: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#34a841',
    },
  },
  chip_tag: {
    marginRight: '2px',
  },
});

export default ({ task, onShowDetail }) => {
  const angelUser = usePermission()?.isAngel() ?? false;
  const { preferences } = useFirmTasks();
  const showPageCount = preferences.show_page_count === 'true';
  const classes = useStyles({ showPageCount, angelUser });
  const theme = useTheme();
  const [showTeam, setShowTeam] = useState(false);
  const timezone = task.team_timezone;

  const statusPaperStyle = () => {
    if (task.status === 'on_going') {
      return { borderLeft: `10px solid ${theme.palette.primary.light}` };
    } else if (task.status === 'paused') {
      return { borderLeft: `10px solid ${theme.palette.warning.light}` };
    } else if (task.status === 'completed') {
      return { borderLeft: `10px solid ${theme.palette.success.light}` };
    } else if (task.status === 'stucked') {
      return { borderLeft: `10px solid ${theme.palette.error.light}` };
    } else {
      return { borderLeft: '10px solid transparent' };
    }
  };

  const teams = {
    'America/Bogota': {
      team: '🇨🇴 Bogota',
      color: 'secondary',
    },
    'America/New_York': {
      team: '🇺🇸 New York',
      color: 'primary',
    },
    'Asia/Manila': {
      team: '🇵🇭 Manila',
      color: 'info',
    },
  };

  const onClickID = () => {
    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.open(`/app/task/${task.id}`, '_blank');
  };

  useEffect(() => {
    if (preferences) {
      setShowTeam(preferences.pass_the_baton === 'true');
    }
  }, [preferences]);

  const TagList = (tags) => {
    if (tags.length == 0) {
      return null
    } else {
      return <>
        {tags.map((tag) => {
          return (<Chip
            label={tag}
            color='primary'
            size='small'
            variant='outlined'
            className={classes.chip_tag}
          />)
        })}
      </>
    }
  }

  return (
    <Box className={classes.box}>
      <Paper
        square
        className={classes.paper}
        style={statusPaperStyle()}
        onDoubleClick={onShowDetail}
      >
        <Typography className={classes.typography}>
          {task.name}
          <br />
          <Chip
            label={'#' + task.id}
            size="small"
            color="success"
            className={classes.chip}
            onClick={onClickID}
          />
          &nbsp;
          {showTeam && (
            <Chip
              label={teams[timezone].team}
              color={teams[timezone].color}
              size="small"
              variant="outlined"
            />
          )}
          &nbsp;
          {task.tags.length > 0 && TagList(task.tags)}
        </Typography>
      </Paper>
    </Box>
  );
};
