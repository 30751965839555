import React, { useEffect, useState } from "react";
import _ from 'lodash';
import EditAutocomplete from "./edit_autocomplete";
import { Box } from '@mui/material';
import { STYLES } from '../styles';

export default ({columns, column, data, onChange}) => {
  const [local_data, setLocalData] = useState({});
  const column_def = _.find(columns, {'column': column});

  const column_1 = column_def.columns[0];
  const column_2 = column_def.columns[1];
  const column_3 = column_def.columns[2];

  const level_1 = _.find(columns, {'column': column_1}).options;
  const level_2 = _.find(columns, {'column': column_2}).options;
  const level_3 = _.find(columns, {'column': column_3}).options;

  const [value_1, setValue1] = useState('');
  const [value_2, setValue2] = useState('');
  const [value_3, setValue3] = useState('');

  useEffect(() => {
    setLocalData({...data});
  }, []);

  const onChangeLevel1 = (val) => {
    var target_value = _.find(level_1, {value: val[column_1]});
    var val_1 = target_value;
    var val_2 = '';
    var val_3 = '';
    onChangeLocal({val_1, val_2, val_3});
  }

  const onChangeLevel2 = (val) => {
    var target_value = _.find(level_2, {value: val[column_2]});
    var val_1 = {...value_1};
    var val_2 = target_value;
    var val_3 = '';

    if(target_value !== null) {
      val_1 = {
        value:  target_value.lvl_1_value,
        label:  target_value.lvl_1_label,
        code:   target_value.lvl_1_code,
        avatar: target_value.lvl_1_avatar,
      };
    }

    onChangeLocal({val_1, val_2, val_3})
  }

  const onChangeLevel3 = (val) => {
    var target_value = _.find(level_3, {value: val[column_3]});
    var val_1 = {...value_1};
    var val_2 = {...value_2};
    var val_3 = target_value;

    if(target_value !== null) {
      val_2 = {
        value:  target_value.lvl_2_value,
        label:  target_value.lvl_2_label,
        code:   target_value.lvl_2_code,
        avatar: target_value.lvl_2_avatar,
      };
      val_1 = {
        value:  target_value.lvl_1_value,
        label:  target_value.lvl_1_label,
        code:   target_value.lvl_1_code,
        avatar: target_value.lvl_1_avatar,
      };
    }

    onChangeLocal({val_1, val_2, val_3})
  }

  const onChangeLocal = ({val_1, val_2, val_3}) => {
    var new_data = {};
    new_data[column_1] = '';
    new_data[column_2] = '';
    new_data[column_3] = '';

    setValue1(val_1);
    setValue2(val_2);
    setValue3(val_3);

    if(val_1) { new_data[column_1] = val_1.value }
    if(val_2) { new_data[column_2] = val_2.value }
    if(val_3) { new_data[column_3] = val_3.value }

    const new_local_data = {
      ...local_data,
      ...new_data
    }

    setLocalData(new_local_data)
    onChange({...new_data});
  }

  return(
    <>
      <Box style={STYLES.inner_box}>
        <EditAutocomplete columns={columns} data={local_data} column={column_1} onChange={onChangeLevel1} />
      </Box>
      <Box style={STYLES.inner_box}>
        <EditAutocomplete columns={columns} data={local_data} column={column_2} onChange={onChangeLevel2} />
      </Box>
      <Box style={STYLES.inner_box}>
        <EditAutocomplete columns={columns} data={local_data} column={column_3} onChange={onChangeLevel3} />
      </Box>
    </>
  )

}
