import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles({
  control: (props) => ({
    width: props.width,
    marginTop: 10,
    marginLeft: 10,
    marginBottom: 20,
  }),
});

const Selector = ({ width, setSheetType }) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState('new');

  const handleChange = (event) => {
    const value = event.target.value;
    setValue(value);
    setSheetType(value);
  };

  return (
    <FormControl className={classes.control}>
      <Select size="small" value={value} onChange={handleChange}>
        <MenuItem value={'new'}>New</MenuItem>
        <MenuItem value={'existing'}>Existing</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Selector;
