import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';

import MultiplePhotoUpload        from '../../../../../../../shared/components/upload_photo/multiple';
import { API_PATH, API_TASKS }    from '../../../../../../../const/api_paths';
import { doAuthenticatedDelete }  from '../../../../../../../actions/_methods';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon     from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

import Stack          from '@mui/material/Stack';
import Link           from '@mui/material/Link';
import List           from '@mui/material/List';
import ListItem       from '@mui/material/ListItem';
import ListItemText   from '@mui/material/ListItemText';
import LoadingButton  from '@mui/lab/LoadingButton';
import Paper          from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%'
  },
  box: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '2px',
  },
  input: {
    minWidth: '770px',
    marginRight: '10px',
  },
  attachment_item: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  attachment_delete: {
    paddingTop: '0px !important'
  },
  attached_items: {
    maxHeight: '200px',
    overflow: 'auto',
    border: '1px solid #ffffff3b',
  },
  loading: {
    height: '100px',
    width: '100%'
  }
}));

export default ({task, default_files, read_mode}) => {
  const classes = useStyles();
  const [uploaded_files, setUploadedFiles] = useState([]);
  const [loading_files, setLoadingFiles] = useState(false);

  useEffect(() =>  {
    setUploadedFiles(default_files);
  },[])

  const task_file_url = () => {
    return API_PATH(`${API_TASKS}/${task.id}/task_files`)
  }

  const displayLoadingFiles = () => {
    setLoadingFiles(true);
  }

  const displayFiles = (files) => {
    setLoadingFiles(false);
    setUploadedFiles(files);
  }

  const localOnUpload = () => {
    displayLoadingFiles();
  }

  const localAfterUploadPhoto = (files) => {
    displayFiles(files);
  }

  const doDeleteFile = (file) => {
    const url = `${API_TASKS}/${task.id}/task_files/${file.id}`;
    doAuthenticatedDelete(url, {}, {
      success: onSuccessDelete,
      general: onSuccessDelete,
      error: onError,
    });
  }

  const onError = (res) => {
    alert("Please try again");
  }

  const onSuccessDelete = (files) => {
    alert("Task file deleted");
    displayFiles(files);
  }

  const onClickDelete = (file) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      displayLoadingFiles();
      doDeleteFile(file);
    }
  }

  const FilesLoading = () => (
    <LoadingButton className={classes.loading} loading loadingIndicator='Loading...' />
  )

  const FilesAttached = () => (
    <Stack className={classes.attached_items}>
      <List className={classes.list_items}>
        {uploaded_files.map(f =>
          <ListItem
            className={classes.attachment_item}
            secondaryAction={
              <IconButton edge='end' aria-label='delete' className={classes.attachment_delete}>
                <DeleteIcon onClick={() => onClickDelete(f)}/>
              </IconButton>
            }
          >
            <AttachFileIcon />
            <ListItemText
              primary={
                <Link 
                  href={f.url}
                  color='inherit'
                  download={f.name}
                  target='_blank'
                  textDecoration='none'>
                  {f.name}
                </Link>
              }
              secondary={f.type}
              primaryTypographyProps={{fontSize: '12px'}}
              secondaryTypographyProps={{fontSize: '10px'}}
            />
          </ListItem>,
        )}
      </List>
    </Stack>
  )

  return(
    <Paper elevation={4} className={classes.wrapper}>
      <Stack className={classes.box}>
        {!read_mode && <MultiplePhotoUpload
          url={task_file_url()}
          onUpload={localOnUpload}
          afterSave={localAfterUploadPhoto}
        />}
        {!loading_files && <FilesAttached />}
        {loading_files && <FilesLoading />}
      </Stack>
    </Paper>
  )
}