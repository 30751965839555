import React from 'react';
import { makeStyles } from '@mui/styles';
import { TableBody, TableCell, TableRow } from '@mui/material';

const useStyles = makeStyles({
  cell: {
    width: '130px',
    textAlign: 'center',
  },
});

const tableRowStyles = {
  '&:last-child td, &:last-child th': {
    border: 0,
  },
};

const ReportBody = ({ results = [] }) => {
  const classes = useStyles();

  return (
    <TableBody>
      {results.map((data, i) => (
        <TableRow key={`${data.firm} ${i}`} sx={tableRowStyles}>
          <TableCell className={classes.cell}>{data.firm}</TableCell>
          <TableCell className={classes.cell}>{data.monday}</TableCell>
          <TableCell className={classes.cell}>{data.tuesday}</TableCell>
          <TableCell className={classes.cell}>{data.wednesday}</TableCell>
          <TableCell className={classes.cell}>{data.thursday}</TableCell>
          <TableCell className={classes.cell}>{data.friday}</TableCell>
          <TableCell className={classes.cell}>{data.saturday}</TableCell>
          <TableCell className={classes.cell}>{data.total}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default ReportBody;
