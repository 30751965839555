import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Dropdown from '../../../../features/manage/firms/components/settings/Dropdown';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginLeft: 300,
    marginTop: 20,
    marginRight: 10,
  },
});

const EditTimeZone = ({ data, onChange }) => {
  const classes = useStyles();
  const [preferences, setPreferences] = useState(data.preferences);

  useEffect(() => {
    onChange({ preferences: preferences });
  }, [preferences]);


  return (
    <div className={classes.container}>
      <Typography variant="h6" component="h6">
        Timezone
      </Typography>
      <Dropdown
        width={260}
        timezone={preferences.timezone}
        setPreferences={setPreferences}
      />
    </div>
  );
};

export default EditTimeZone;
