import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";

import List from "./components";

import { API_REFERENCES } from "../../../const/api_paths";
import { doAuthenticatedGet } from "../../../actions/_methods";
import { groupByLevel } from "../../../utils/orgs";
import { useGlobal } from "../../context/global";
import { useReferences } from '../../context/references';

export default () => {
  const { loadOrganizations } = useReferences();
  const { setMyself } = useGlobal();
  const [firms, setFirms] = useState([]);
  const [_references, setRereferences] = useState([]);

  useEffect(() => {
    loadOrganizations();
    getReferences();
  }, []);

  const getReferences = () => {
    doAuthenticatedGet(
      API_REFERENCES,
      {},
      {
        success: onSuccessGetReferences,
        general: onSuccessGetReferences,
        error: (_res) => alert("Please try again..."),
      }
    );
  };

  const onSuccessGetReferences = (res) => {
    const options = res.organizations;
    const { level_1 } = groupByLevel(options);
    setRereferences(res);
    setFirms(level_1);
    setMyself(res.myself);
  };

  return (
    <Router>
      <List path="/" firms={firms} />
    </Router>
  );
};
