import React from "react";
import { makeStyles } from '@mui/styles';
import {
  Box,
  Typography,
  Paper,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '60px',
    marginRight: '2px',
  },
  paper: {
    padding: '10px 5px',
    minHeight: '44px',
    height: '100%',
    textAlign: 'center',
  }
}));

export default ({task, field}) => {
  const classes = useStyles();

  return(
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        <Typography>
          {task[field]}
        </Typography>
      </Paper>
    </Box>
  )
}