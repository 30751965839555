import React from 'react';
import { makeStyles } from '@mui/styles';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles({
  title: {
    color: '#23282C',
    textAlign: 'center',
  },
  link: {
    color: '#3059fb',
    cursor: 'pointer',
    textAlign: 'center',
  },
});

const columnWidths = [
  '15%', // Account
  '15%', // Location
  '15%', // Task Name
  '10%', // Date
  '9%', // Admin Estimate
  '10%', // Actual Work Hours
  '8%', // Variance
  '8%', // Link
];

const ReportBody = ({ results = [] }) => {
  const classes = useStyles();

  const onClickID = (link) => {
    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.open(link, '_blank');
  };

  return (
    <TableBody>
      {results.map((item) => (
        <TableRow key={item.id}>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[0] }}
          >
            {item.account}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[1] }}
          >
            {item.location}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[2] }}
          >
            {item.task}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[3] }}
          >
            {item.date}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[4] }}
          >
            {item.admin_estimate}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[5] }}
          >
            {item.actual_work}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[6] }}
          >
            {item.variance}
          </TableCell>
          <TableCell
            className={classes.link}
            onClick={() => onClickID(item.link)}
            style={{ width: columnWidths[7] }}
          >
            {item.id}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default ReportBody;
