import React, { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography } from '@mui/material';
import { IfAnyGranted } from 'react-authorization';
import { usePermission } from '../../../../../../context/permission';

const useStyles = makeStyles({
  item: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  delete_item: {
    paddingTop: '0px !important',
  },
  ruler: {
    width: '5px',
    height: '45px',
    background: 'red',
  },
  grayed: {
    background: '#d7d7d7',
  },
});

const Comment = ({ data, onClickDelete, carryOver }, ref) => {
  const classes = useStyles();
  const listItemClasses = `${classes.item} ${carryOver ? classes.grayed : ''}`;
  const { atleastManager, getMyRoles } = usePermission();

  const secondaryElements = (
    <div>
      {data.override_by && (
        <>
          Done By - {data.override_by}
          <br />
        </>
      )}
      PH - {data.created_at_ph}
      <br />
      US - {data.created_at_us}
    </div>
  );

  const deleteButton = (
    <IfAnyGranted expected={atleastManager()} actual={getMyRoles()}>
      <IconButton
        edge="end"
        aria-label="delete"
        className={classes.delete_item}
        onClick={() => onClickDelete(data)}
      >
        <DeleteIcon />
      </IconButton>
    </IfAnyGranted>
  );

  const verticalRuler = <div className={classes.ruler} />;
  const showSecondary = carryOver ? verticalRuler : deleteButton;

  return (
    <ListItem
      className={listItemClasses}
      secondaryAction={showSecondary}
      ref={ref}
    >
      <ListItemAvatar>
        <Tooltip title={data.commenter}>
          <Avatar src={data.commenter_pic} alt={data.commenter} />
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={data.body}
        secondary={secondaryElements}
        primaryTypographyProps={{ fontSize: '12px' }}
        secondaryTypographyProps={{ fontSize: '10px' }}
      />
    </ListItem>
  );
};
export default forwardRef(Comment);
