import React, {useEffect, useState} from "react";
import { Router } from "@reach/router";

import List from './components';
import Add from './components/add';

import { API_REFERENCES } from '../../../const/api_paths';
import { doAuthenticatedGet } from '../../../actions/_methods';
import { groupByLevel } from '../../../utils/orgs';
import { useGlobal } from "../../context/global";

export default () => {
  const { setMyself } = useGlobal()
  const [firms, setFirms] = useState([]);
  const [roles, setRoles] = useState([]);
  const [references, setRereferences] = useState([]);

  useEffect(() => {
    getReferences();
  }, []);

  const getReferences = () => {
    doAuthenticatedGet(API_REFERENCES, {}, {
      success: onSuccessGetReferences,
      general: onSuccessGetReferences,
      error: (res) => alert('Please try again...'),
    });
  }

  const onSuccessGetReferences = (res) => {
    const options = res.organizations;
    const {level_1} = groupByLevel(options);
    setRereferences(res);
    setFirms(level_1);
    setRoles(res.roles);
    setMyself(res.myself)
  }

  return (
    <Router>
      <Add path="add" />
      <List path="/" firms={firms} roles={roles} />
    </Router>
  )

}
