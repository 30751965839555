import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Accounts from './_accounts';
import FirmTags from './tags/FirmTags';
import ActionFirm from './_actions_firm';
import { useFirmsCtx } from '../context';

export default ({ firm }) => {
  const [expanded, setExpanded] = useState(false);
  const { openFirms } = useFirmsCtx();

  useEffect(() => {
    setExpanded(openFirms);
  }, [openFirms]);

  return (
    <TableRow key={`firm_${firm.id}`}>
      <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
        <Stack direction="row" spacing={2}>
          <Avatar alt={firm.name} src={firm.avatar} />
          <Stack direction="column" spacing={1}>
            <b>{firm.name}</b>
            <FirmTags tags={firm.tags} />
          </Stack>
        </Stack>
        <ActionFirm firm={firm} />
      </TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        {expanded ? (
          <Accounts firm={firm} />
        ) : (
          <Button
            onClick={() => {
              setExpanded(true);
            }}
          >
            Show Accounts and Locations
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
