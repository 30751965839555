import React, { createContext, useContext, useEffect, useState } from 'react';
import { TASK_CATEGORIES } from '../../const/api_paths';
import { doAuthenticatedGet } from '../../actions/_methods';

const TaskCategoryCtx = createContext();

const useTaskCategory = () => {
  const context = useContext(TaskCategoryCtx);

  if (!context) {
    throw new Error(
      `useTaskCategory must be used within a TaskCategoryContext`
    );
  }
  return context;
};

const TaskCategoryCtxProvider = ({ children }) => {
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [parentNode, setParentNode] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [taskCategories, setTaskCategories] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(new Set());

  const isTaskCategoriesPresent = taskCategories && taskCategories.length > 0;

  const fetchTaskCategories = () => {
    setLoading(true);
    doAuthenticatedGet(
      TASK_CATEGORIES,
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => console.error(res),
      }
    );
  };

  const updateExpandedKeys = (nodes, isExpanded, newExpandedKeys) => {
    nodes.forEach((node) => {
      if (isExpanded) {
        newExpandedKeys.add(node.id);
      } else {
        newExpandedKeys.delete(node.id);
      }

      if (node.children) {
        updateExpandedKeys(node.children, isExpanded, newExpandedKeys);
      }
    });
  };

  const expandAllNodes = (nodes, isExpanded) => {
    const newExpandedKeys = new Set(expandedKeys);

    updateExpandedKeys(nodes, isExpanded, newExpandedKeys);

    const updatedTree = nodes.map((node) => {
      const updatedNode = {
        ...node,
        expanded: isExpanded,
        children: node.children
          ? expandAllNodes(node.children, isExpanded)
          : [],
      };

      return updatedNode;
    });

    setExpandedKeys(newExpandedKeys);
    return updatedTree;
  };

  const enhanceNode = (node) => ({
    ...node,
    title: node.name,
    children:
      node.children && node.children.length > 0
        ? node.children.map(enhanceNode)
        : [],
  });

  const getParentNode = (node) => {
    const ancestry = node.ancestry ? node.ancestry.split('/') : [];
    const parentId = ancestry.length > 0 ? ancestry[ancestry.length - 1] : null;

    const findNodeById = (nodes, id) => {
      for (let node of nodes) {
        if (String(node.id) === id) {
          return node;
        }
        if (node.children) {
          const found = findNodeById(node.children, id);
          if (found) return found;
        }
      }
      return null;
    };

    return parentId ? findNodeById(treeData, parentId) : null;
  };

  const onSuccess = (res) => {
    setLoading(false);
    setTaskCategories(res);
  };

  const handleExpandAll = () => {
    const expandedTree = expandAllNodes(treeData, true);
    setTreeData(expandedTree);
  };

  const handleCollapseAll = () => {
    const collapsedTree = expandAllNodes(treeData, false);
    setTreeData(collapsedTree);
  };

  useEffect(() => {
    if (isTaskCategoriesPresent) {
      const enhancedData = taskCategories.map(enhanceNode);
      setTreeData(enhancedData);
    }
  }, [taskCategories]);

  const value = {
    treeData,
    loading,
    parentNode,
    currentNode,
    taskCategories,
    setTreeData,
    setLoading,
    setParentNode,
    getParentNode,
    setCurrentNode,
    setTaskCategories,
    fetchTaskCategories,
    handleExpandAll,
    handleCollapseAll,
    expandedKeys,
    setExpandedKeys,
  };

  return (
    <TaskCategoryCtx.Provider value={value}>
      {children}
    </TaskCategoryCtx.Provider>
  );
};

export { TaskCategoryCtx, TaskCategoryCtxProvider, useTaskCategory };
