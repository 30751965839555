import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Modal, Box, TextField } from '@mui/material';
import ToggleBar from '../ToggleBar';
import FilterButtons from '../../../FilterButtons';
import FilterDropdown from '../../../FilterDropdown';
import { referenceTypeOptions } from '../utils';
import { doAuthenticatedPost } from '../../../../../../../../actions/_methods';
import { ACCOUNTING_TASK_REFERENCES } from '../../../../../../../../const/api_paths';
import { useGlobal } from '../../../../../../../context/global';

const useStyles = makeStyles({
  box: {
    padding: 20,
    top: '50%',
    left: '50%',
    width: '30vw',
    borderRadius: '5px',
    position: 'absolute',
    backgroundColor: '#f8f8ff',
    transform: 'translate(-50%, -50%)',
  },
  container: {
    gap: 15,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 20,
  },
  text: {
    width: '100%',
  },
});

const AddReference = ({
  task,
  open,
  handleClose,
  referenceType,
  fetchReferences,
}) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [notes, setNotes] = useState('');
  const [active, setActive] = useState('true');
  const [type, setType] = useState(referenceType);
  const [linkModified, setLinkModified] = useState(false);

  const disableSave =
    name.trim() === '' || (linkModified && link.trim() === '');

  const handleChange = (event, type) => {
    const value = event.target.value;

    switch (type) {
      case 'type':
        setType(value);
        break;

      case 'name':
        setName(value);
        break;

      case 'link':
        setLink(value);
        setLinkModified(value !== '');
        break;

      case 'notes':
        setNotes(value);
        break;

      default:
        break;
    }
  };

  const referenceStatus = () => {
    return active === 'true' ? 'active' : 'inactive';
  };

  const onSave = () => {
    doAuthenticatedPost(
      ACCOUNTING_TASK_REFERENCES,
      {
        task_id: task.id,
        type: type.value,
        name: name.trim(),
        link: link.trim(),
        notes: notes.trim(),
        status: referenceStatus(),
      },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => alert('Please try again'),
      }
    );
  };

  const onSuccess = (res) => {
    const message = 'Successfully created task reference';

    prompt(message);
    fetchReferences();
    onClose();
  };

  const onClose = () => {
    resetDefaults();
    handleClose();
  };

  const resetDefaults = () => {
    setName('');
    setLink('');
    setNotes('');
    setActive('true');
  };

  useEffect(() => {
    setType(referenceType);
  }, [referenceType]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <div className={classes.header}>New Reference</div>
        <div className={classes.container}>
          <FilterDropdown
            width="100%"
            value={type}
            label="Reference Type"
            options={referenceTypeOptions}
            onChange={(e) => handleChange(e, 'type')}
          />

          <TextField
            required
            size="small"
            name="Name"
            label="Name"
            variant="outlined"
            value={name}
            className={classes.text}
            onChange={(e) => handleChange(e, 'name')}
          />

          <TextField
            size="small"
            name="Link"
            label="Link"
            variant="outlined"
            value={link}
            className={classes.text}
            onChange={(e) => handleChange(e, 'link')}
          />

          <TextField
            multiline
            rows={3}
            size="small"
            name="Notes"
            label="Notes"
            variant="outlined"
            value={notes}
            className={classes.text}
            onChange={(e) => handleChange(e, 'notes')}
          />

          <ToggleBar on={active} label={'Active'} handleChange={setActive} />
        </div>
        <FilterButtons
          labelSave="Save"
          labelCancel="Close"
          onClickSave={onSave}
          onClickCancel={onClose}
          disableSave={disableSave}
        />
      </Box>
    </Modal>
  );
};

export default AddReference;
