import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTaskCategory } from '../../context/task_categories';
import { Button } from '@mui/material';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 20,
    marginBottom: 18,
    gap: 15,
  },
  expand: {
    backgroundColor: '#3f7fb7',
    color: '#ffffff',
    height: 34,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2e6391',
    },
  },
});

const ExpandButtons = () => {
  const classes = useStyles();
  const { handleExpandAll, handleCollapseAll } = useTaskCategory();

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        className={classes.expand}
        onClick={() => handleExpandAll(true)}
      >
        Expand All
      </Button>
      <Button
        variant="contained"
        className={classes.expand}
        onClick={() => handleCollapseAll(false)}
      >
        Collapse All
      </Button>
    </div>
  );
};

export default ExpandButtons;
