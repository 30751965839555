import React, { createContext, useContext, useEffect, useState } from 'react';

const TaskCtx = createContext();

const useTask = () => {
  const context = useContext(TaskCtx);

  if (!context) {
    throw new Error(`useTask must be used within a TaskContext`);
  }
  return context;
};

const TaskCtxProvider = ({ children }) => {
  const [comments, setComments] = useState([]);

  const value = {
    comments,
    setComments,
  };

  return <TaskCtx.Provider value={value}>{children}</TaskCtx.Provider>;
};

export { TaskCtx, TaskCtxProvider, useTask };
