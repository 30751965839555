import React from 'react';
import { useGlobal } from '../../../../context/global';
import { IconButton } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { API_MANAGE_USERS } from '../../../../../const/api_paths';
import { doAuthenticatedDelete } from '../../../../../actions/_methods';
import { useFirmsCtx } from '../../context';

const DeleteUserButton = ({ firm, user }) => {
  const { prompt } = useGlobal();
  const { getUsers, setIsUsersLoading } = useFirmsCtx();

  const unassignUser = () => {
    const confirmed = window.confirm(
      `Are you sure you want to unassign this user?`
    );

    if (confirmed) {
      setIsUsersLoading(true);

      doAuthenticatedDelete(
        `${API_MANAGE_USERS}/${user.id}/delete_role`,
        { user_role_id: user.role_id },
        {
          general: onUnassignSuccess,
          success: onUnassignSuccess,
          error: onUnassignError,
        }
      );
    }
  };

  const onUnassignSuccess = () => {
    const message = `Successfully unassigned ${user.label} to ${firm.name}`;

    getUsers();
    prompt(message);
  };

  const onUnassignError = () => {
    const message = 'Something went wrong. Please try again';

    prompt(message, { variant: 'error' });
  };

  return (
    <IconButton color="error" onClick={unassignUser} aria-label="settings">
      <RemoveCircleIcon fontSize="small" />
    </IconButton>
  );
};

export default DeleteUserButton;
