import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { calculateRange } from '../utils';
import ReportDropdown from './ReportDropdown';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    gap: 20,
    width: '100%',
  },
  note: {
    color: '#0000005c',
    fontSize: 12,
    marginTop: 5,
  },
  date: {
    flexBasis: (props) => (props.showEstimateFilters ? '15%' : '15%'),
  },
  dropdown: {
    flexBasis: (props) => (props.showEstimateFilters ? '19%' : '20%'),
  },
  estimate: {
    flexBasis: '14%',
  },
});

const Filters = ({
  date,
  firms,
  results,
  setDate,
  setRange,
  setError,
  setFirmID,
  setResults,
  setAccountID,
  setLocationID,
  setAboveEstimate,
  setBelowEstimate,
  showEstimateFilters,
}) => {
  const classes = useStyles({ showEstimateFilters });
  const [firm, setFirm] = useState(null);
  const [account, setAccount] = useState(null);
  const [location, setLocation] = useState(null);
  const [aboveValue, setAboveValue] = useState(null);
  const [belowValue, setBelowValue] = useState(null);

  const onWeekChange = (event) => {
    const value = event.target.value;
    setDate(value);
  };

  const handleChange = (event, selected, type) => {
    const value = selected
      ? selected
      : event.target.value === ''
      ? null
      : event.target.value;

    switch (type) {
      case 'firm':
        setFirm(value);
        setAccount(null);
        setAccountID(null);
        setLocation(null);
        setLocationID(null);
        setFirmID(value ? value.id : null);
        break;

      case 'account':
        setAccount(value);
        setLocation(null);
        setLocationID(null);
        setAccountID(value ? value.id : null);
        break;

      case 'location':
        setLocation(value);
        setLocationID(value ? value.id : null);
        break;

      case 'above':
        setAboveValue(value);
        setAboveEstimate(value);
        break;

      case 'below':
        setBelowValue(value);
        setBelowEstimate(value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (date) setRange(calculateRange(date));
  }, [results]);

  return (
    <div className={classes.wrapper}>
      <TextField
        label="Date Range"
        type="week"
        className={classes.date}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        required
        onChange={onWeekChange}
      />

      <ReportDropdown
        label="Firm"
        value={firm}
        options={firms}
        disabled={false}
        setError={setError}
        setResults={setResults}
        noOptionsMessage="No Firms Available"
        dropdownClass={classes.dropdown}
        onChange={(e, value) => handleChange(e, value, 'firm')}
      />
    </div>
  );
};

export default Filters;
