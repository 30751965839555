import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';

import { doAuthenticatedDelete } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS_DELETE_LOCATION } from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({account, location}) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onDelete = () => {

    if(window.confirm("Are you sure to delete this location?")) {
      setSubmitting(true);

      const url = API_MANAGE_PROJECTS_DELETE_LOCATION;
      const data = { id: location.id };

      doAuthenticatedDelete(url, data, {
        general: onSuccessDelete,
        success: onSuccessDelete,
        error: onErrorDelete
      });
    }
  }

  const onSuccessDelete = (res) => {
    dispatch(
      projectActions.removeLocation({account_id: account.id, location: location})
    );
    setSubmitting(false);
  }

  const onErrorDelete = (res) => {
    alert('Please try again');
    setSubmitting(false);
  }

  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <b style={{marginRight: '20px'}}>{location.name}</b>
      <DeleteIcon onClick={onDelete}
      disabled={submitting}
      size="small" />
    </div>
  )

}
