import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Chip, TextField, Autocomplete } from '@mui/material';

const useStyles = makeStyles({
  container: {
    margin: 30,
  },
  note: {
    color: '#0000005c',
    fontSize: 12,
    marginTop: 5,
  },
});

const InputTags = ({ tags, setTags, values, setValues }) => {
  const classes = useStyles();
  const handleChange = (event, value) => {
    setValues(value);
    setTags(value);
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        multiple
        freeSolo
        size="small"
        value={values}
        options={[]}
        onChange={handleChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="Tags" variant="outlined" />
        )}
      />
      <span className={classes.note}>Press 'Enter' to set a tag</span>
    </div>
  );
};

export default InputTags;
