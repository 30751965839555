import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DropdownUsers from './DropdownUsers';
import { doAuthenticatedPost } from '../../../../actions/_methods';
import { REPORTS_ASSIGN_USER } from '../../../../const/api_paths';
import { useGlobal } from '../../../context/global';
import { useReportsCtx } from '../../context';

const useStyles = makeStyles({
  container: {
    margin: 30,
  },
  save: {
    backgroundColor: '#1976d2',
    color: '#ffffff',
    textTransform: 'none',
    height: 40,
    marginLeft: 20,
    '&:hover': {
      backgroundColor: '#175da2',
    },
  },
});

const AssignUser = ({ report }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { getUsers, unassignedUsers, setIsUsersLoading } = useReportsCtx();
  const [selectedUser, setSelectedUser] = useState('');

  const checkInputs = () => {
    return selectedUser && selectedUser.id;
  };

  const assignUser = () => {
    const inputsAreValid = checkInputs();

    if (inputsAreValid) {
      setIsUsersLoading(true);
      doAuthenticatedPost(
        REPORTS_ASSIGN_USER,
        {
          report_id: report.id,
          user_id: selectedUser.id
        },
        {
          general: onAssignSuccess,
          success: onAssignSuccess,
          error: onAssignError,
        }
      );
    } else {
      const message = 'Please select a user';
      prompt(message, { variant: 'error' });
    }
  };

  const onAssignSuccess = () => {
    const message = `Successfully shared ${report.name} to user ${selectedUser.label}`;
    setIsUsersLoading(false);
    prompt(message);
  };

  const onAssignError = () => {
    const message = 'Something went wrong. Please try again';
    setIsUsersLoading(false);
    prompt(message, { variant: 'error' });
  };

  return (
    <div className={classes.container}>
      <DropdownUsers
        setSelectedUser={setSelectedUser}
      />
      <Button variant="contained" className={classes.save} onClick={assignUser}>
        Share to this user
      </Button>
    </div>
  );
};

export default AssignUser;
