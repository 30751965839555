import loadable from '@loadable/component'
import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import 'react-quill/dist/quill.snow.css'
import {
  Box,
  TextField,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '2px',
    marginBottom: '50px',
    width: '100%',
  },
  input: {
    minHeight: '400px',
    width: '100%',
  }
}));

const name = 'description';
const title = 'Description';

const ReactQuill = loadable(() => import("react-quill"))

export default ({default_value, onChange}) => {
  const classes = useStyles();
  const [value, setValue] = useState(default_value);

  const onChangeLocal = (e) => {
    setValue(e)
    onChange(sendBackData(e));
  }

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
  ]

  return (
    <Box className={classes.box}>
      <ReactQuill
        theme='snow'
        value={value}
        onChange={onChangeLocal}
        modules={modules}
        formats={formats}
        className={classes.input}
      />
    </Box>
  )
}