import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip, Avatar, CardHeader, Chip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import Organizational from './row/Organizational';
import AssignedTo from './row/AssignedTo';
import DueDate from './show/_DueDate';
import MultiTimeTracker from './/row/multi_time_tracker/Main';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 'normal',
    margin: '1px',
  },
  header: {
    width: '100%',
  },
  chip: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#34a841',
    },
  },
}));

export default ({ task, preferences }) => {
  const classes = useStyles();
  const [showTeam, setShowTeam] = useState(false);
  const timezone = task.team_timezone;

  const orgData = () => {
    return task.organizational_structure[0];
  };

  const teams = {
    'America/Bogota': {
      team: '🇨🇴 Bogota',
      color: 'secondary',
    },
    'America/New_York': {
      team: '🇺🇸 New York',
      color: 'primary',
    },
    'Asia/Manila': {
      team: '🇵🇭 Manila',
      color: 'info',
    },
  };

  useEffect(() => {
    if (preferences) {
      setShowTeam(preferences.pass_the_baton === 'true');
    }
  }, [preferences]);

  const onClickID = () => {
    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.open(`/app/task/${task.id}`, '_blank');
  };

  return (
    <CardHeader
      className={classes.header}
      avatar={
        <Tooltip key={`org_tooltip_${Math.random()}`} title={orgData().name}>
          <Avatar
            key={`org_avatar_${Math.random()}`}
            alt={orgData().name}
            src={orgData().avatar}
            sx={{ width: 60, height: 60 }}
          >
            {orgData().code}
          </Avatar>
        </Tooltip>
      }
      title={
        <Typography variant="h6">
          <Chip
            label={'#' + task.id}
            size="small"
            color="success"
            className={classes.chip}
            onClick={onClickID}
          />
          {showTeam && (
            <Chip
              label={teams[timezone].team}
              color={teams[timezone].color}
              size="small"
              variant="outlined"
            />
          )}{' '}
          {task.name}
        </Typography>
      }
      subheader={<DueDate task={task} />}
    />
  );
};
