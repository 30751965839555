import React from "react";
import Button from '@mui/material/Button';

export default ({onMoveStep}) => {

  return (
    <>
      <h1>Step 3</h1>
      <Button
        variant="contained"
        onClick={() => onMoveStep(2)}
      >Backone step</Button>
    </>
  )

}
