import React from 'react';
import 'react-sortable-tree/style.css';
import { makeStyles } from '@mui/styles';
import { useTaskCategory } from '../../context/task_categories';
import SortableTree from 'react-sortable-tree';
import TaskCategory from './TaskCategory';

const useStyles = makeStyles({
  container: {
    height: 750,
    overflow: 'auto',
  },
  noMatch: {
    height: 750,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Content = ({
  noMatches,
  getNodeKey,
  handleVisibilityToggle,
  setShowAddModal,
  setShowEditModal,
  setShowDeleteModal,
  setShowInfoModal,
  searchString,
  searchMethod,
  searchFinishCallback,
}) => {
  const classes = useStyles();
  const { treeData, setTreeData } = useTaskCategory();

  if (treeData.length === 0) {
    return <div className={classes.noMatch}>No task categories found.</div>;
  }

  if (noMatches) {
    return <div className={classes.noMatch}>No matches were found.</div>;
  }

  return (
    <div className={classes.container}>
      <SortableTree
        treeData={treeData}
        searchMethod={searchMethod}
        onChange={setTreeData}
        getNodeKey={getNodeKey}
        searchFinishCallback={searchFinishCallback}
        searchQuery={searchString}
        searchFocusOffset={0}
        onVisibilityToggle={handleVisibilityToggle}
        generateNodeProps={({ node, path }) => ({
          title: (
            <TaskCategory
              node={node}
              path={path}
              setShowAddModal={setShowAddModal}
              setShowEditModal={setShowEditModal}
              setShowDeleteModal={setShowDeleteModal}
              setShowInfoModal={setShowInfoModal}
            />
          ),
        })}
      />
    </div>
  );
};

export default Content;
