import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Modal, Box, TextField } from '@mui/material';
import ToggleBar from '../ToggleBar';
import FilterButtons from '../../../FilterButtons';
import FilterDropdown from '../../../FilterDropdown';
import { referenceTypeOptions } from '../utils';
import { doAuthenticatedPost } from '../../../../../../../../actions/_methods';
import {
  ACCOUNTING_TASK_REFERENCES,
  ACCOUNTING_TASK_REFERENCE_VERSIONS,
} from '../../../../../../../../const/api_paths';
import { useGlobal } from '../../../../../../../context/global';

const useStyles = makeStyles({
  box: {
    padding: 20,
    top: '50%',
    left: '50%',
    width: '30vw',
    borderRadius: '5px',
    position: 'absolute',
    backgroundColor: '#f8f8ff',
    transform: 'translate(-50%, -50%)',
  },
  container: {
    gap: 15,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 20,
  },
  text: {
    width: '100%',
  },
});

const AddVersion = ({ open, reference, handleClose, fetchVersions }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [link, setLink] = useState('');
  const [notes, setNotes] = useState('');

  const disableSave = link.trim() === '';

  const handleChange = (event, type) => {
    const value = event.target.value;

    switch (type) {
      case 'link':
        setLink(value);
        break;

      case 'notes':
        setNotes(value);
        break;

      default:
        break;
    }
  };

  const onSave = () => {
    doAuthenticatedPost(
      ACCOUNTING_TASK_REFERENCE_VERSIONS,
      {
        link: link.trim(),
        notes: notes.trim(),
        reference_id: reference.id,
      },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => alert('Please try again'),
      }
    );
  };

  const onSuccess = (res) => {
    const message = 'Successfully created task reference version';

    prompt(message);
    fetchVersions();
    onClose();
  };

  const onClose = () => {
    resetDefaults();
    handleClose();
  };

  const resetDefaults = () => {
    setLink('');
    setNotes('');
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <div className={classes.header}>New Version</div>
        <div className={classes.container}>
          <TextField
            required
            size="small"
            name="Link"
            label="Link"
            variant="outlined"
            value={link}
            className={classes.text}
            onChange={(e) => handleChange(e, 'link')}
          />

          <TextField
            multiline
            rows={3}
            size="small"
            name="Notes"
            label="Notes"
            variant="outlined"
            value={notes}
            className={classes.text}
            onChange={(e) => handleChange(e, 'notes')}
          />
        </div>
        <FilterButtons
          labelSave="Save"
          labelCancel="Close"
          onClickSave={onSave}
          onClickCancel={onClose}
          disableSave={disableSave}
        />
      </Box>
    </Modal>
  );
};

export default AddVersion;
