import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import _ from 'lodash';
// import TextField from '@mui/material/TextField';
// import DeleteIcon from '@mui/icons-material/Delete';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import InputSelect from '../../../../board/components/_basic_filters/InputSelect';

import { WEEK_OPTIONS } from '../../../../board/const/ref';

import { doAuthenticatedPost } from '../../../../../actions/_methods';
import {
  API_MANAGE_PROJECTS_SET_FREQUENCY,
  API_MANAGE_PROJECTS_SET_FREQUENCY_WEEK,
} from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';


const options = [
  'Every Sunday',
  'Every Monday',
  'Every Tuesday',
  'Every Wednesday',
  'Every Thursday',
  'Every Friday',
  'Every Saturday',
  'First Day of Month',
  'Last Day of Month',
  'Middle of the Month',
  'Second Monday of the Month',
];

const biweekly_options = [
  'Bi-weekly Monday',
  'Bi-weekly Tuesday',
  'Bi-weekly Wednesday',
  'Bi-weekly Thursday',
  'Bi-weekly Friday',
  'Bi-weekly Saturday',
];

export default ({task}) => {
  const [selecting, setSelecting] = useState(false);
  const dispatch = useDispatch();

  const [week_option, setWeekOption] = useState(task.week_option);

  const handleChange = (e) => {
    const url = API_MANAGE_PROJECTS_SET_FREQUENCY.replace(':firm_id', task.firm_id);
    let data = { task_id: task.id, name: e.target.value, is_check: e.target.checked};

    if (week_option !== null) {
      data = { ...data, ...{ week_option: week_option } }
    }

    doAuthenticatedPost(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const handleChangeWeekOption = (sd) => {
    const url = API_MANAGE_PROJECTS_SET_FREQUENCY_WEEK.replace(':firm_id', task.firm_id);
    let data = { task_id: task.id };

    if (task.week_option !== '' && week_option !== null) {
      data = { ...data, ...{ week_option: sd } }
      doAuthenticatedPost(url, data, {
        general: onSuccess,
        success: onSuccess,
        error: onError
      });
    }
  }

  const handleChangeBiweekly = (e) => {
    if (week_option === null || week_option === undefined || week_option === '') {
      alert('Please provide a Week Option')
    } else {
      handleChange(e)
    }
  }

  const onSuccess = (res) => {
    dispatch(projectActions.updateCategoryTask({task: res.task}))
  }

  const onError = (res) => {
    console.log(res)
  }

  const isSelected = (name) => {
    if(_.find(task.frequencies, {name: name})) {
      return true;
    } else {
      return false;
    }
  }

  const onChangeWeekOption = (new_data) => {
    setWeekOption(new_data['week_option'])
    handleChangeWeekOption(new_data['week_option'])
  }

  const getWeekOptionLabel = (week_option) => {
    const chosen = _.find(WEEK_OPTIONS, { value: task.week_option });
    return chosen ? chosen.label : ''
  }

  return (
    <>
    <Button onClick={() => setSelecting(!selecting)}>Select Frequency</Button>
    {!selecting && <Box style={{ paddingLeft: '30px' }}>
        {task.frequencies.map((f) => { 
          return (
            <Typography variant="body2">{f.name}</Typography>
          )
        })}
      </Box>
    }
    {selecting && <>
      <Box style={{width: '100%', marginBottom: "15px"}}>
        <Paper elevation={3} style={{width: '100%', padding: "15px 20px"}}>
          {options.map((option) => {
            return(
              <div>
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      onChange={handleChange}
                      checked={isSelected(option)}
                      value={option}
                    />
                  } label={option} />
                </FormGroup>
              </div>
            )
          })}
            <Divider />
            <Box>
              <Typography variant="h6" style={{ width: '100%', padding: "10px" }}>Bi-weekly ({getWeekOptionLabel(task.week_option)})</Typography>
              <InputSelect name="week_option" title="Week" options={WEEK_OPTIONS} onChange={onChangeWeekOption} default_value={task.week_option} exclude_none={true} />
            </Box>
            {biweekly_options.map((option) => {
              return (
                <div>
                  <FormGroup>
                    <FormControlLabel control={
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        onChange={handleChangeBiweekly}
                        checked={isSelected(option)}
                        value={option}
                      />
                    } label={option} />
                  </FormGroup>
                </div>
              )
            })}
        </Paper>
      </Box>
    </>}
    </>
  )

}
