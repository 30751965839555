import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Button
} from '@mui/material';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";

export default ({start_date, end_date, onChange}) => {
  const [display_calendar, setDisplayCalendar] = useState(false);
  const [local_start_date, setLocalStartDate] = useState(start_date);
  const [local_end_date, setLocalEndDate] = useState(end_date);
  const [target_dates, setTargetDates] = useState({
    startDate: start_date,
    endDate: end_date,
    key: 'selection',
  });

  const onDateRangeChange = (ranges) => {
    setTargetDates(ranges.selection);
    setLocalStartDate(ranges.selection.startDate);
    setLocalEndDate(ranges.selection.endDate);
    onChange(ranges.selection);
  }

  const rangeReadable = () => {
    var start_date_readable = moment(local_start_date).format('ddd, MMM Do');
    var end_date_readable = moment(local_end_date).format('ddd, MMM Do');

    if(start_date_readable == end_date_readable) {
      return start_date_readable;
    } else {
      return `${start_date_readable} - ${end_date_readable}`;
    }
  }

  return(
    <>
      {!display_calendar &&
        <Button
          size="small"
          variant="contained"
          onClick={() => setDisplayCalendar(true)}
        >({rangeReadable()})</Button>}

      {display_calendar &&
        <Box>
          <Button
            size="small"
            variant="contained"
            onClick={() => setDisplayCalendar(false)}
          >Close Calendar</Button>
          <Divider />
          <DateRangePicker
            ranges={[target_dates]}
            onChange={onDateRangeChange}
            months={1}
            direction="vertical"
            scroll={{ enabled: true }}
          />
        </Box>
      }
    </>
  )
}