import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {
  TextField,
  Autocomplete,
  Stack
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: '250px',
    marginRight: '10px',
  },
  option_style: {
    display: 'flex',
    fontSize: '11pt',
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #555',
    marginBottom: '0',
  }
}));

export default ({columns, column, data, onChange}) => {
  const classes     = useStyles();
  const column_def  = _.find(columns, {'column': column});
  const label       = column_def.header;
  const options     = column_def.options;

  const [value, setValue] = useState([]);
  const [defVal, setDefVal] = useState([]);

  useEffect(() => {
    console.log(data[column])
    if (data !== undefined && data[column] !== undefined) {
      var vals = data[column].map(v => v.value);
      var new_values = _.filter(options, (o) => {
        return vals.includes(o.value);
      });

      setValue(new_values)
    } 
  }, [data]);

  const onChangeLocal = (e, v) => {
    setValue(v);
    onChange(sendBackData(v));
  }

  const sendBackData = (target_value) => {
    let new_data = {};
    new_data[column] = target_value;
    return new_data;
  }

  const OptionComponent = ({option, props}) => (
    <div className={classes.option_style} {...props}>
      {option.label}
    </div>
  );

  const InputComponent = ({params, label}) => (
    <TextField
      {...params}
      label={label}
      variant="outlined"
    />
  );

  return(
    <Stack spacing={3} sx={{ width: 500 }}>
      <Autocomplete
        multiple
        size="small"
        value={value}
        // defaultValue={defVal}
        options={options}
        className={classes.input}
        onChange={onChangeLocal}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option ? option.label : ''}
        renderOption={(props, option) => <OptionComponent {...{option, props}} />}
        renderInput={(params) => <InputComponent {...{params, label}} />}
      />
    </Stack>
  )
}

