import React, { useState, createContext, useContext, useEffect } from 'react';

import { API_FILTERED_USERS, REPORTS_ASSIGNED_USERS, API_SEARCH_FILTERED_USERS } from '../../const/api_paths';
import { doAuthenticatedGet } from '../../actions/_methods';

const ReportsCtx = createContext();

const useReportsCtx = () => {
  const context = useContext(ReportsCtx);

  if (!context) {
    throw new Error(`useReportsCtx must be used within a ReportsContext`);
  }
  return context;
};

const ReportsCtxProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [openReports, setOpenReports] = useState(false);
  const [unassignedUsers, setUnassignedUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [dropdown_users, setDropdownUsers] = useState([]);

  const getUsers = () => {
    if(users.length == 0) {
      doAuthenticatedGet(
        API_FILTERED_USERS,
        {},
        {
          success: onGetUserSuccess,
          general: onGetUserSuccess,
          error: (res) => alert('Please try again...'),
        }
      );
    } else {
      setIsUsersLoading(false);
    }
  };

  const getSearchUsers = (searchTerm) => {
    if(searchTerm) {
      doAuthenticatedGet(
        API_SEARCH_FILTERED_USERS,
        { q: searchTerm },
        {
          success: (res) => { setDropdownUsers(res) },
          general: (res) => { setDropdownUsers(res) },
          error: (res) => alert('Please try again...'),
        }
      );
    } else {
      setDropdownUsers([]);
    }
  };

  const getAssignedUsers = (report) => {
    doAuthenticatedGet(
      REPORTS_ASSIGNED_USERS,
      {report_id: report.id},
      {
        success: onGetAssignedUserSuccess,
        general: onGetAssignedUserSuccess,
        error: (res) => alert('Please try again...'),
      }
    );
  };

  const onGetUserSuccess = (res) => {
    setUsers(res);
    setIsUsersLoading(false);
  };

  const onGetAssignedUserSuccess = (res) => {
    setAssignedUsers(res);
    setIsUsersLoading(false);
  };

  const reloadData = (type) => {
    setIsLoading(type);
  };

  const value = {
    users,
    dropdown_users,
    // reports,
    assignedUsers,
    reloadData,
    isLoading,
    openReports,
    setIsLoading,
    setOpenReports,
    isUsersLoading,
    setIsUsersLoading,
    getUsers,
    // getReports,
    getAssignedUsers,
    unassignedUsers,
    setUnassignedUsers,
    getSearchUsers,
  };

  return <ReportsCtx.Provider value={value}>{children}</ReportsCtx.Provider>;
};

export { ReportsCtx, ReportsCtxProvider, useReportsCtx };
