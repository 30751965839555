import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  ListItemText,
  Checkbox,
  Chip,
  Box,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    width: (props) => props.width,
  },
  input: {
    width: '100%',
    marginRight: '10px',
    '& > div > div': {
      padding: '8px 10px',
      fontSize: '12pt',
      minWidth: '85px',
    },
  },
  menuItem: {
    padding: '0px',
  },
}));

export default ({ name, title, default_value, options, onChange, width }) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState(default_value);

  const onChangeLocal = (e) => {
    const target_value = e.target.value;
    setValue(target_value);
    onChange(sendBackData(target_value));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  };

  return (
    <div className={classes.container}>
      <FormControl variant="outlined" className={classes.input}>
        <InputLabel id="demo-simple-select-outlined-label" size="small">
          {title}
        </InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={onChangeLocal}
          label={title}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.3 }}>
              {selected.length > 0 &&
                selected.map((s) => (
                  <Chip
                    key={s}
                    label={_.find(options, ['value', s]).label}
                    size="small"
                  />
                ))}
            </Box>
          )}
          multiple
        >
          {options.map((o) => (
            <MenuItem
              className={classes.menuItem}
              key={`basic_filter_input_${Math.random()}`}
              value={o.value}
            >
              <Checkbox checked={value.indexOf(o.value) > -1} size="small" />
              <ListItemText primary={o.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
