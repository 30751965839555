import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

import DueDate from "../../../board/components/_tasks/variants/_default/show/_DueDate";
import Organization from "../../../board/components/_tasks/variants/_default/show/_Organization";
import Tags from "../../../board/components/_tasks/variants/_default/show/_Tags";
import Description from "../../../board/components/_tasks/variants/_default/show/_Description";

const useStyles = makeStyles((theme) => ({
  inner_box: {
    display: "flex",
    marginTop: "10px",
  },
  inner_box_spaced: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
  },
}));

export default ({ taskRequest }) => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <h2 className={classes.title}>{taskRequest.name}</h2>

        <Typography variant="body2" sx={{ color: "info.dark" }}>
          {_.compact([
            taskRequest.parent_category_name,
            taskRequest.task_category_name,
          ]).join(" >> ")}
        </Typography>
      </Box>

      <Box className={classes.inner_box_spaced}>
        <DueDate task={taskRequest} />
        <Organization task={taskRequest} />
      </Box>

      <Box className={classes.inner_box}>
        <Tags task={taskRequest} />
      </Box>

      <Box className={classes.inner_box}>
        <Description
          task_description={taskRequest.description}
          minHeight={"100px"}
        />
      </Box>
    </>
  );
};
