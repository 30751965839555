import React from "react";
import moment from "moment";
import { Typography } from "@mui/material";

export default ({ task }) => {
  const readableDate = () => {
    let startDate = moment(task.start_date).format("MMM Do YYYY");
    if (startDate === "Invalid date") {
      return "Not yet set";
    }
    return startDate;
  };

  if (!task.start_date) {
    return null;
  }

  return (
    <>
      <Typography variant="h8">
        Start date: {readableDate()}
      </Typography>
    </>
  );
};
