import React from 'react';
import Filters from './Filters';
import { makeStyles } from '@mui/styles';
import FilterButtons from './FilterButtons';

const useStyles = makeStyles({
  wrapper: {
    gap: 20,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ebe4e430',
    padding: '20px 10px 20px 10px',
  },
});

const FilterBar = ({
  firm,
  users,
  account,
  location,
  minEstHrs,
  maxEstHrs,
  setAccount,
  setLocation,
  subCategory,
  setMinEstHrs,
  setMaxEstHrs,
  assignedUsers,
  onFilterReset,
  onFilterApply,
  setSubCategory,
  parentCategory,
  setSelectedWeek,
  setAssignedUsers,
  setParentCategory,
  topLevelCategories,
  subLevelCategories,
  selectedFrequencies,
  setSelectedFrequencies,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Filters
        firm={firm}
        users={users}
        account={account}
        location={location}
        minEstHrs={minEstHrs}
        maxEstHrs={maxEstHrs}
        setAccount={setAccount}
        setLocation={setLocation}
        subCategory={subCategory}
        setMinEstHrs={setMinEstHrs}
        setMaxEstHrs={setMaxEstHrs}
        assignedUsers={assignedUsers}
        parentCategory={parentCategory}
        setSubCategory={setSubCategory}
        setSelectedWeek={setSelectedWeek}
        setAssignedUsers={setAssignedUsers}
        setParentCategory={setParentCategory}
        topLevelCategories={topLevelCategories}
        subLevelCategories={subLevelCategories}
        selectedFrequencies={selectedFrequencies}
        setSelectedFrequencies={setSelectedFrequencies}
      />
      <FilterButtons
        labelCancel="Reset"
        labelSave="Apply Filter"
        onClickSave={onFilterApply}
        onClickCancel={onFilterReset}
      />
    </div>
  );
};

export default FilterBar;
