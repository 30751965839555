import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import {
  Box,
  TextField,
  Chip,
  Avatar,
  Autocomplete,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'inline-block',
    marginRight: '2px',
  },
  input: {
    width: '250px',
    marginRight: '10px',
  },
  avatar_wrapper: {
    '& > div': {
      width: '28px',
      height: '28px',
      marginRight: '10px',
    }
  },
}));

const option_style = {
  display: 'flex',
  fontSize: '11pt',
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '1px solid #555',
  marginBottom: '0',
};

const name = 'assigned_to';
const title = 'Assign To';

export default ({default_value, options, onChange}) => {
  const classes = useStyles();
  const [value, setValue] = useState(default_value);

  const onChangeLocal = (e, v) => {
    setValue(v);
    onChange(sendBackData(v));
  }

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  }

  const OptionComponent = ({option, props}) => {
    const avatar = option.avatar ? <Avatar src={option.avatar}>{option.code}</Avatar>: <Avatar>{option.code}</Avatar>;
    return <div style={option_style} {...props}>
      <span className={classes.avatar_wrapper}>{avatar}</span>
      {option.label}
    </div>
  };

  const InputComponent = ({params, title}) => (
    <TextField
      {...params}
      label={title}
      variant="outlined"
    />
  );

  const tagComponent = (tagValue, getTagProps, classes) => {
    return tagValue.map((option, index) => {
      const avatar = option.avatar ? <Avatar src={option.avatar}>{option.code}</Avatar>: <Avatar>{option.code}</Avatar>;
      return <Chip
        avatar={avatar}
        label={option.label}
        variant="outlined"
        className={classes.tag}
        {...getTagProps({ index })}
      />
    })
  };

  return(
    <Box className={classes.box}>
      <Autocomplete
        multiple
        size="small"
        options={options}
        limitTags={1}
        className={classes.input}
        onChange={onChangeLocal}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderTags={(value, props) => tagComponent(value, props, classes)}
        renderOption={(props, option) => <OptionComponent {...{option, props}} />}
        renderInput={(params) => <InputComponent {...{params, title}} />}
      />
    </Box>
  )
}