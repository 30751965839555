import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  lock: {
    color: '#284b6d',
  },
}));

const ProtectFirmButton = ({ isFirmProtected, setShowProtectFirm }) => {
  const classes = useStyles();

  const onClick = () => {
    setShowProtectFirm(true);
  };

  return (
    <IconButton
      color="default"
      className={classes.button}
      onClick={onClick}
      aria-label="Toggle firm protection"
    >
      {isFirmProtected ? (
        <LockIcon fontSize="small" className={classes.lock} />
      ) : (
        <LockOpenIcon fontSize="small" className={classes.lock} />
      )}
    </IconButton>
  );
};

export default ProtectFirmButton;
