import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { filterReport, getCurrentWeek, calculateRange } from '../utils';
import FirmAccount from './FirmAccount';
import { useReferences } from '../../context/references';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    gap: 30,
  },
  note: {
    color: '#0000005c',
    fontSize: 12,
    marginTop: 5,
  },
});

const Filters = ({
  results,
  setWeek,
  setRange,
  setDisplayedResults,
  setOrganization,
}) => {
  const classes = useStyles();
  const currentWeek = getCurrentWeek();
  const [data, setData] = useState({});
  const { organizations, loadOrganizations } = useReferences();

  useEffect(() => {
    loadOrganizations()
  }, []);

  useEffect(() => {
    setDisplayedResults(filterReport(results, data));
  }, [results, data]);

  useEffect(() => {
    setWeek(currentWeek);
    setRange(calculateRange(currentWeek));
  }, []);

  const onWeekChange = (event) => {
    const value = event.target.value;
    setWeek(value);
  };

  const onChangeOrganization = (new_data) => {
    setData({ ...data, ...new_data });
    console.log(new_data)
    setOrganization(new_data)
  };

  return (
    <div className={classes.wrapper}>
      <FirmAccount options={organizations} onChange={onChangeOrganization} />
      <TextField
        label="Date Range"
        type="week"
        className={classes.dateRange}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        onChange={onWeekChange}
      />
    </div>
  );
};

export default Filters;
