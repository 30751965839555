import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import { useFirmsCtx } from '../context';
import FilterText from './_filter_text';
import FilterSelect from './_filter_select';

import FilterDropdown from './_filter_dropdown';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    gap: 30,
    padding: '16px 16px 0 16px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '10px',
  },
}));

const statusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

const FirmFilters = () => {
  const classes = useStyles();
  const {
    filters,
    loadFirms,
    firstLoad,
    setFilters,
    setOpenFirms,
    defaultFilters,
  } = useFirmsCtx();

  const onFilterChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const toggleFirmDetails = (filters) => {
    const accountIsPresent = filters.account !== (null || '');
    const locationIsPresent = filters.location !== (null || '');
    const showFirmDetails = accountIsPresent || locationIsPresent;

    setOpenFirms(showFirmDetails);
  };

  const onSearch = () => {
    loadFirms('primary');
    toggleFirmDetails(filters);
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  const isEmptyValue = (value) => {
    return (
      value === '' ||
      value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    );
  };

  const onClear = () => {
    setFilters(defaultFilters);
  };

  useEffect(() => {
    const areFiltersEmpty = Object.values(filters).every((value) =>
      isEmptyValue(value)
    );

    if (!firstLoad) {
      if (areFiltersEmpty) {
        loadFirms('primary');
        setOpenFirms(false);
      }

      if (filters.tags.length > 0) {
        loadFirms('primary');
      }
    }
  }, [filters]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container} onKeyDown={onEnterPress}>
        {FilterText('firm', 'Firm', filters.firm, onFilterChange, '25%')}
        {FilterText(
          'account',
          'Account',
          filters.account,
          onFilterChange,
          '25%'
        )}
        {FilterText(
          'location',
          'Location',
          filters.location,
          onFilterChange,
          '25%'
        )}
        {FilterDropdown('tags', 'Tags')}
        {FilterSelect(
          'status',
          'Status',
          filters.status,
          statusOptions,
          onFilterChange,
          '25%'
        )}
      </Box>
      <Box className={classes.container}>
        <Button variant="outlined" color="info" onClick={onClear}>
          Clear
        </Button>
        <Button variant="contained" color="success" onClick={onSearch}>
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default FirmFilters;
