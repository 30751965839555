import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import List from './components';
import { FirmsCtxProvider } from './context';
import { useReferences } from "../../context/references";

export default () => {
  const { loadConnectedUsers } = useReferences();

  useEffect(() => {
    loadConnectedUsers();
  }, []);

  return (
    <FirmsCtxProvider>
      <Router>
        <List path="/" />
      </Router>
    </FirmsCtxProvider>
  );
};
