import React from 'react';
import Button from '@mui/material/Button';

import {
  statusColor,
  statusLabel
} from '../../../../../utils/task_status';

export default ({task}) => {

  return(
    <>
      <Button
        variant="contained"
        style={statusColor(task.status)}
        disableElevation
      >{statusLabel(task.status)}</Button>
    </>
  )

}
