import React from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash'

export default ({task}) => {

  return(
    <Typography variant="body2" sx={{color: 'info.dark'}}>
      {_.compact([task.task_category_parent_name, task.task_category_sub_name]).join(" >> ")}
    </Typography>
  )

}