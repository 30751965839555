import React from 'react';
import Chip from '@mui/material/Chip';

export default ({task}) => {

  return(
    <div>
      {task.tags.map(tag => <Chip
        label={tag}
        variant="outlined"
        style={{marginRight: '3px'}}
      />)}
    </div>
  )

}
