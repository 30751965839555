import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles({
  box: {
    height: 39,
    padding: 10,
    width: '100%',
    display: 'flex',
    color: '#3059fb',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const Toggle = ({ label, setShow }) => {
  const classes = useStyles();
  const [toggled, setToggled] = useState(false);

  const handleToggle = () => {
    const newToggledState = !toggled;

    setShow(newToggledState);
    setToggled(newToggledState);
  };

  return (
    <Box className={classes.box} onClick={handleToggle}>
      <Typography variant="body1">{label}</Typography>
      {toggled ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
    </Box>
  );
};

export default Toggle;
