import React, { useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { doAuthenticatedPost } from '../../../../../../actions/_methods';
import { API_MANAGE_PROJECTS } from '../../../../../../const/api_paths';

export default ({onMoveStep}) => {
  const [firm_name, setFirmName] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setFirmName(e.target.value);
  };

  const onSubmit = () => {
    setSubmitting(true);

    const data = {
      name: firm_name
    };

    doAuthenticatedPost(API_MANAGE_PROJECTS, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const onSuccess = (res) => {
    window.location = `/app/manage/projects/step_2/${res.firm.id}`;
  }

  const onError = (res) => {
    alert('Please try again')
    setSubmitting(false)
  }

  return (
    <>
      <h1>Set Firm Name</h1>
      <TextField
        label="Firm name"
        variant="outlined"
        value={firm_name}
        onChange={handleChange}
        disabled={submitting}
        size="small"
      />
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={submitting}
        style={{marginLeft: '10px'}}
      >Submit</Button>
    </>
  )

}
