export const groupByLevel = (options) => {
  var level_1 = []
  var level_2 = []
  var level_3 = []

  options.forEach(lvl_1 => {
    level_1.push({
      value:  lvl_1.id,
      label:  lvl_1.name,
      code:   lvl_1.code,
      avatar: lvl_1.avatar
    });

    lvl_1.children.forEach(lvl_2 => {
      level_2.push({
        value:    lvl_2.id,
        label:    lvl_2.name,
        code:     lvl_2.code,
        avatar:   lvl_2.avatar,
        group:    lvl_1.name,
        lvl_1_value:  lvl_1.id,
        lvl_1_label:  lvl_1.name,
        lvl_1_code:   lvl_1.code,
        lvl_1_avatar: lvl_1.avatar,
      });

      lvl_2.children.forEach(lvl_3 => {
        level_3.push({
          value:    lvl_3.id,
          label:    lvl_3.name,
          code:     lvl_3.code,
          avatar:   lvl_3.avatar,
          group:    `${lvl_1.name} - ${lvl_2.name}`,
          lvl_1_value:  lvl_1.id,
          lvl_1_label:  lvl_1.name,
          lvl_1_code:   lvl_1.code,
          lvl_1_avatar: lvl_1.avatar,
          lvl_2_value:  lvl_2.id,
          lvl_2_label:  lvl_2.name,
          lvl_2_code:   lvl_2.code,
          lvl_2_avatar: lvl_2.avatar,
        });
      })
    })
  });

  return {level_1, level_2, level_3}
}