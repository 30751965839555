import React from 'react';
import { makeStyles } from '@mui/styles';
import { Select, InputLabel, FormControl, MenuItem } from '@mui/material';

const useStyles = makeStyles({
  input: (props) => ({
    flexBasis: props.width,
    marginRight: '10px',
    '& > div > div': {
      padding: '8px 10px',
      fontSize: '12pt',
      minWidth: '85px',
    },
  }),
});

const FilterSelect = (name, label, value, options, onChange, width) => {
  const classes = useStyles({ width });

  return (
    <div>
      <FormControl variant="outlined" className={classes.input}>
        <InputLabel id="demo-simple-select-outlined-label" size="small">
          {label}
        </InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={onChange}
          label={label}
          name={name}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((o) => {
            return (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterSelect;
