import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

const useStyles = makeStyles({
  textfield: (props) => ({
    width: props.width,
  }),
});

const EstimatedHours = ({ label, width, setInput, estimate }) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState('');

  const onChange = (event) => {
    const item = event.target.value;

    setValue(item);
    setInput(item);
  };

  useEffect(() => {
    const noEstimate = estimate === null;
    setValue(noEstimate ? '' : estimate);
  }, [estimate]);

  return (
    <TextField
      label={label}
      size="small"
      type="number"
      className={classes.textfield}
      InputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]',
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default EstimatedHours;
