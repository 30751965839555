import { API_MANAGE_RECURRING_TASKS } from '../../../const/api_paths';

export const MODEL_DEF = {
  api_path: API_MANAGE_RECURRING_TASKS,
  model_name: 'recurring_task',
  peer_name: 'recurring_tasks',
  columns: [
    {column: 'firm_id',         header: "Firm",         type: 'autocomplete'},
    {column: 'account_id',      header: "Account",      type: 'autocomplete'},
    {column: 'location_id',     header: "Location",     type: 'autocomplete'},
    {column: 'category_id',     header: "Category",     type: 'autocomplete'},
    {column: 'name',            header: "Name",         type: 'string'},
    {column: 'description',     header: "Description",  type: 'string'},
    {column: 'tags',            header: "Tags",         type: 'string'},
    {column: 'frequency',       header: "Frequency",    type: 'string'},
    {column: 'when_to_create',  header: "Create On",    type: 'string'},
    {column: 'status',          header: "Status",       type: 'string'},
    {column: 'organizational', type: 'organizational', columns: ['firm_id', 'account_id', 'location_id']},
    
  ],
  listable_columns: [
    {column: 'name',        header: 'Name'},
    {column: 'firm_id',     header: 'Firm'},
    {column: 'account_id',  header: 'Account'},
    {column: 'location_id', header: 'Location'},
    {column: 'category_id', header: 'Category'},
    {column: 'status',      header: 'Status'},
  ],
  creatable_columns: [
    ['name'],
    ['organizational'],
    ['category_id', 'tags'],
    ['description'],
    ['frequency', 'when_to_create', 'status'],
  ],
  updatable_columns: [
    ['name'],
    ['organizational'],
    ['category_id', 'tags'],
    ['description'],
    ['frequency', 'when_to_create', 'status'],
  ]
};