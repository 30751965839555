import React, { useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({message, open, severity, onCloseHandler}) => {
  const [local_message, setLocalMessage] = React.useState();
  const [local_open, setLocalOpen] = React.useState(false);
  const [local_severity, setLocalSeverity] = React.useState('success');

  useEffect(() => {
    setLocalMessage(message);
    setLocalOpen(open);
    setLocalSeverity(severity);
  }, [message, open, severity])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setLocalOpen(false);
    if(onCloseHandler) {
      onCloseHandler();
    }
  };

  return(
    <Snackbar open={local_open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={local_severity} sx={{ width: '100%' }}>
        {local_message}
      </Alert>
    </Snackbar>
  )
}