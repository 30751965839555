import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';


import { doAuthenticatedGet } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS } from '../../../../../const/api_paths';

import { projectActions } from '../../../../../store/project_slice';

import AddAccount from './_add_account';
import AccountInfo from './_account_info';
import AddLocation from './_add_location';
import LocationInfo from './_location_info';

export default ({firm_id}) => {
  const firm = useSelector(state => state.project.firm);
  const accounts = useSelector(state => state.project.accounts);
  const dispatch = useDispatch();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const url = `${API_MANAGE_PROJECTS}/${firm_id}`;
    doAuthenticatedGet(url, {}, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const onSuccess = (res) => {
    dispatch(
      projectActions.setFirmAndAccounts(res)
    )
  }

  const onError = (res) => {
    alert('Sorry please try again')
  }

  const onSubmit = () => {
    window.location = `/app/manage/projects/step_3/${firm_id}`;
  }

  return (
    <>
      <h1>Step 2 - {firm.name}</h1>

      <div>
        <AddAccount firm_id={firm_id} />
      </div>

      {accounts.map((account) => {
        return(
          <Card sx={{marginBottom: "20px", padding: "15px", display: "flex"}}>
            <div style={{width: "300px"}}>
              <AccountInfo account={account} />
            </div>
            <div style={{flexGrow: "1"}}>
              <AddLocation account={account} />
              {account.locations.map((location) => {
                return(
                  <>
                    <Box style={{width: '100%', marginTop: "10px"}}>
                      <Paper elevation={1} style={{width: '100%', padding: "5px 15px"}}>
                        <LocationInfo account={account} location={location} />
                      </Paper>
                    </Box>
                  </>
                )
              })}
            </div>
          </Card>
        )
      })}

      <div>
        <Button
          variant="contained"
          onClick={onSubmit}
        >Next Step</Button>
      </div>


      {/* <hr/>
      <span>TODO: Ability to edit account</span>
      <hr/>
      <span>TODO: Ability to edit location</span>
      <hr/>
 */}

    </>
  )

}
