import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const Selector = ({scope, onChange}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Scope</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={scope}
        onChange={handleChange}
      >
        <FormControlLabel value="firm-wide" control={<Radio />} label="Firm Wide" />
        <FormControlLabel value="account-wide" control={<Radio />} label="Account Wide" />
        <FormControlLabel value="location" control={<Radio />} label="Location Base" />
      </RadioGroup>
    </FormControl>
  );
}

export default Selector;