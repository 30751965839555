import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Avatar, TextField, FormControl } from '@mui/material';

const useStyles = makeStyles({
  control: {
    width: 260,
  },
  listbox: {
    maxHeight: 200,
    overflow: 'auto',
  },
  label: {
    color: '#00000029',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 28,
      height: 28,
      marginRight: 10,
    },
  },
  optionStyle: {
    fontSize: '11pt',
    padding: 10,
    cursor: 'pointer',
    borderBottom: '1px solid #00000029',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
});

const defaultStyles = {
  width: 260,
};

const CustomOption = ({ option, classes, ...props }) => {
  const avatar = <Avatar src={option.profile_pic}>{option.code}</Avatar>;
  return (
    <div {...props} className={classes.optionStyle}>
      <span className={classes.avatarWrapper}>{avatar}</span>
      {option.label}
    </div>
  );
};

const DropdownUsers = ({
  users,
  styles,
  label,
  setSelectedUser,
  controlClassName,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedUser(newValue);
  };

  return (
    <FormControl className={controlClassName || classes.control}>
      <Autocomplete
        size="small"
        value={value}
        onChange={handleChange}
        options={users}
        getOptionLabel={(option) => option.label || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || 'Select a user'}
            variant="outlined"
            InputLabelProps={{ className: classes.label }}
          />
        )}
        ListboxProps={{ className: classes.listbox }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <CustomOption
            {...props}
            option={option}
            classes={classes}
            key={option.id}
          />
        )}
        sx={styles || defaultStyles}
      />
    </FormControl>
  );
};

export default DropdownUsers;
