import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { useGlobal } from '../../../features/context/global';
import { doAuthenticatedPost } from '../../../actions/_methods';
import { STOP_IMPERSONATE } from '../../../const/api_paths';
import { setAuthToken } from '../../../actions/_auth';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: 16,
    right: 800,
    display: 'flex',
    zIndex: '10000',

    '@media (max-width: 1610px)': {
      top: 16,
      right: 660,
    },

    '@media (max-width: 1500px)': {
      top: 16,
      right: 600,
    },
  },
});

const StopImpersonate = () => {
  const classes = useStyles();
  const { isImpersonating, setIsImpersonating } = useGlobal();

  const onStopImpersonate = () => {
    setOriginalToken();
    stopImpersonation();
  };

  const setOriginalToken = () => {
    const localStorage = typeof window !== 'undefined' && window.localStorage;
    const originalToken = localStorage.getItem('billebotOriginalToken');
    setAuthToken(originalToken);
    localStorage.removeItem('billebotOriginalToken');
  };

  const stopImpersonation = () => {
    setIsImpersonating(false);
    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.location = '/app/all';
  };

  return (
    isImpersonating && (
      <Button
        size="small"
        variant="contained"
        color="error"
        className={classes.container}
        onClick={onStopImpersonate}
      >
        Stop Impersonate
      </Button>
    )
  );
};

export default StopImpersonate;
