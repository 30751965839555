import React from 'react';
import { makeStyles } from '@mui/styles';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles({
  title: {
    color: '#23282C',
    textAlign: 'center',
  },
  link: {
    color: '#3059fb',
    cursor: 'pointer',
    textAlign: 'center',
  },
});

const columnWidths = [
  '15%', // Account
  '15%', // Parent
  '15%', // Child
  '20%', // Description
  '5%', // Location Count
  '10%', // Assignee
  '2.5%', // TE
  '2.5%', // AT
  '2.5%', // Diff
  '2.5%', // %
];

const ReportBody = ({ results = [] }) => {
  const classes = useStyles();

  const onClickID = (link) => {
    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.open(link, '_blank');
  };

  return (
    <TableBody>
      {results.map((item) => (
        <TableRow key={item.id}>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[0] }}
          >
            {item[0]}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[1] }}
          >
            {item[1]}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[2] }}
          >
            {item[2]}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[3] }}
          >
            {item[3]}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[4] }}
          >
            {item[4]}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[5] }}
          >
            {item[5]}
          </TableCell>
          <TableCell
            className={classes.title}
            style={{ width: columnWidths[6] }}
          >
            {item[6]}
          </TableCell>
          <TableCell
            className={classes.link}
            style={{ width: columnWidths[7] }}
          >
            {item[7]}
          </TableCell>
          <TableCell
            className={classes.link}
            style={{ width: columnWidths[8] }}
          >
            {item[8]}
          </TableCell>
          <TableCell
            className={classes.link}
            style={{ width: columnWidths[9] }}
          >
            {item[9]}%
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default ReportBody;
