import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const ManageUsersButton = ({ setShowAddUser }) => {
  const classes = useStyles();

  return (
    <IconButton
      color="info"
      className={classes.button}
      onClick={() => setShowAddUser(true)}
      aria-label="settings"
    >
      <PersonAddAltIcon fontSize="small" />
    </IconButton>
  );
};

export default ManageUsersButton;
