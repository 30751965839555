import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import _ from 'lodash';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { doAuthenticatedPost } from '../../../../../actions/_methods';
import {
  API_MANAGE_PROJECTS_SET_FIRM_WIDE,
  API_MANAGE_PROJECTS_UNSET_FIRM_WIDE,
  API_MANAGE_PROJECTS_SET_ACCOUNT_WIDE,
  API_MANAGE_PROJECTS_UNSET_ACCOUNT_WIDE,
  API_MANAGE_PROJECTS_SET_LOCATION_WIDE,
  API_MANAGE_PROJECTS_UNSET_LOCATION_WIDE,
  API_MANAGE_PROJECTS_SET_WORK_AS_ONE
} from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({task}) => {
  const [selecting, setSelecting] = useState(false);
  const [task_for, setTaskFor] = useState('selected_each');
  const accounts = useSelector(state => state.project.accounts)
  const dispatch = useDispatch();

  const onClickFirmWide = (e) => {
    if(e.target.checked) {
      doSetFirmWide()
    } else {
      doUnsetFirmWide()
    }
  }

  const handleChangeTaskFor = (e) => {
    setTaskFor(e.target.value);

    const url = API_MANAGE_PROJECTS_SET_WORK_AS_ONE.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id, task_for: e.target.value };
    console.log('---------------')
    console.log(data)
    console.log('---------------')

    doAuthenticatedPost(url, data, {
      general: onSuccessUpdateTask,
      success: onSuccessUpdateTask,
      error: onError
    });
  }

  const handleChangeAccount = (e) => {
    if(e.target.checked) {
      doSetAccountWide(e.target.value)
    } else {
      doUnsetAccountWide(e.target.value)
    }
  };

  const handleChangeLocation = (e, account_id) => {
    if(e.target.checked) {
      doSetLocationWide(account_id, e.target.value)
    } else {
      doUnsetLocationWide(account_id, e.target.value)
    }
  };

  const onSuccessUpdateTask = (res) => {
    dispatch(projectActions.updateCategoryTask({task: res.task}))
  }

  const doSetFirmWide = () => {
    const url = API_MANAGE_PROJECTS_SET_FIRM_WIDE.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id };
    doUpdateLocations(url, data);
  }

  const doUnsetFirmWide = () => {
    const url = API_MANAGE_PROJECTS_UNSET_FIRM_WIDE.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id };
    doUpdateLocations(url, data);
  }

  const doSetAccountWide = (account_id) => {
    const url = API_MANAGE_PROJECTS_SET_ACCOUNT_WIDE.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id, account_id: account_id };
    doUpdateLocations(url, data);
  }

  const doUnsetAccountWide = (account_id) => {
    const url = API_MANAGE_PROJECTS_UNSET_ACCOUNT_WIDE.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id, account_id: account_id };
    doUpdateLocations(url, data);
  }

  const doSetLocationWide = (account_id, location_id) => {
    const url = API_MANAGE_PROJECTS_SET_LOCATION_WIDE.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id, account_id: account_id, location_id: location_id };
    doUpdateLocations(url, data);
  }

  const doUnsetLocationWide = (account_id, location_id) => {
    const url = API_MANAGE_PROJECTS_UNSET_LOCATION_WIDE.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id, account_id: account_id, location_id: location_id };
    doUpdateLocations(url, data);
  }

  const doUpdateLocations = (url, data) => {
    doAuthenticatedPost(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const onSuccess = (res) => {
    dispatch(projectActions.setCategoryTaskLocations({task: res.task}))
  }

  const onError = (res) => {
    console.log(res)
  }

  const isFirmSelected = (firm_id) => {
    if(_.find(task.locations, {firm_id: firm_id, account_id: null, location_id: null})) {
      return true;
    } else {
      return false;
    }
  }

  const isAccountSelected = (account_id) => {
    if(isFirmSelected(task.firm_id) || _.find(task.locations, {account_id: account_id, location_id: null})) {
      return true;
    } else {
      return false;
    }
  }

  const isLocationSelected = (account_id, location_id) => {
    if(isFirmSelected(task.firm_id) || isAccountSelected(account_id) || _.find(task.locations, {location_id: location_id})) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Button onClick={() => setSelecting(!selecting)}>Select Accounts or Locations</Button>
      {!selecting &&
        <Box style={{ paddingLeft: '30px' }}>
          {isFirmSelected(task.firm_id) && <Typography variant="body2">*** Firm-Wide</Typography>}
          {!isFirmSelected(task.firm_id)
            &&
            <>
              {accounts.map((account) => {
                return (
                  <>
                    {isAccountSelected(account.id) && <Typography variant="body2">*** {account.name} (Account-Wide)</Typography>}
                    {account.locations.map((location) => {
                      return (
                        <>
                          {isLocationSelected(account.id, location.id) && <Typography variant="body2" style={{ paddingLeft: '30px' }}>{location.name} (Location)</Typography>}
                        </>
                      )
                    })}
                  </>
                )
              })}
            </>
          }
        </Box>
      }
    {selecting && <>
      <Box style={{width: '100%', marginBottom: "15px"}}>
        <Paper elevation={3} style={{width: '100%', padding: "15px 20px"}}>
          <div>
            <FormGroup>
              <InputLabel id={`task_for_label_${task.id}`}>Task for</InputLabel>
              <Select
                labelId={`task_for_label_${task.id}`}
                value={task_for}
                label="Task for"
                onChange={handleChangeTaskFor}
              >
                <MenuItem value={'selected_each'}>Selected (Each Item)</MenuItem>
                <MenuItem value={'selected_account'}>Selected Account (Grouped)</MenuItem>
                <MenuItem value={'work_as_one'}>Work as One</MenuItem>
              </Select>
            </FormGroup>
          </div>
          <hr/>
          <div>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox 
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  checked={isFirmSelected(task.firm_id)}
                  onChange={onClickFirmWide}
                />
              } label="Firm-wide" />
            </FormGroup>
          </div>
          <>
            {accounts.map((account) => {
              return(
                <div>
                  <FormGroup>
                    <FormControlLabel control={
                      <Checkbox 
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        style={{paddingLeft: '50px'}}
                        checked={isAccountSelected(account.id)}
                        onChange={handleChangeAccount}
                        value={account.id}
                      />
                    } label={account.name} />
                  </FormGroup>
                  <div style={{paddingLeft: '100px'}}>
                    {account.locations.map((location) => {
                      return(
                        <FormGroup>
                          <FormControlLabel control={
                            <Checkbox
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                              checked={isLocationSelected(account.id, location.id)}
                              onChange={(e) => handleChangeLocation(e, account.id)}
                              value={location.id}
                            />
                          } label={location.name} />
                        </FormGroup>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </>
        </Paper>
      </Box>
    </>}
    </>
  )

}
