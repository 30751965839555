import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const ApplyTagsButton = ({ setShowTags }) => {
  const classes = useStyles();

  return (
    <IconButton
      color="warning"
      className={classes.button}
      onClick={() => setShowTags(true)}
      aria-label="settings"
    >
      <LocalOfferIcon fontSize="small" />
    </IconButton>
  );
};

export default ApplyTagsButton;
