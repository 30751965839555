import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  button: {
    padding: 0,
    margin: 0,
  },
  icon: {
    fontSize: '48px',
  },
});

const ToggleButton = ({ setOptions }) => {
  const [isToggled, setIsToggled] = useState('true');
  const classes = useStyles();

  const toggle = () => {
    return isToggled === 'true' ? 'false' : 'true';
  };

  const handleClick = () => {
    const newToggleState = toggle(isToggled);

    setIsToggled(newToggleState);
    setOptions((prevPreferences) => ({
      ...prevPreferences,
      ['include_headers']: newToggleState,
    }));
  };

  return (
    <IconButton onClick={handleClick} className={classes.button}>
      {isToggled === 'true' ? (
        <ToggleOnIcon className={classes.icon} color="success" />
      ) : (
        <ToggleOffIcon className={classes.icon} />
      )}
    </IconButton>
  );
};

export default ToggleButton;
