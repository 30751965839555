import React, { useState, useCallback } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useAccountingProjectTasks } from '../../../../../../features/context/accounting_project_tasks';

const Scope = ({scope, accounts, locations, work_as_one}) => {
  const [show_list, setShowList] = useState(false);
  const { show_locations } = useAccountingProjectTasks(); 

  const onShowList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowList(!show_list);
  }

  return (
    <>
      {scope == 'firm-wide' && 
        <Chip label="Firm-Wide" color="primary" size="small" />
      }
      {scope == 'account-wide' && 
        <div>
          <Stack direction="row" spacing={1}>
            <Chip label="Account-Wide" color="primary" size="small" />
            {work_as_one && <Chip label="Work-as-One" color="primary" variant="outlined" size="small" />}
            {!work_as_one && <Chip label="Each Location" color="success" variant="outlined" size="small" />}
            <Chip label={accounts.length} color="secondary" size="small" onClick={onShowList} />
          </Stack>
          <div style={{marginTop: '5px'}}>
            {(show_list || show_locations) && <Stack spacing={1} sx={{marginTop: 1}}>
              {accounts.map((account) => (
                <Chip key={account.id} label={account.name} variant="outlined" size="small" />
              ))}
            </Stack>}
          </div>
        </div>
      }
      {scope == 'location' && 
        <div>
          <Stack direction="row" spacing={1}>
            {accounts.map((account) => (
              <Chip key={account.id} label={account.name} color="success" size="small" />
            ))}
            <Chip label="Locations" color="primary" size="small" />
            {work_as_one && <Chip label="Work-as-One" color="primary" variant="outlined" size="small" />}
            {!work_as_one && <Chip label="Each Selected" color="success" variant="outlined" size="small" />}
            <Chip label={locations.length} color="secondary" size="small" onClick={onShowList} />
          </Stack>
          <div style={{marginTop: '5px'}}>
            {(show_list || show_locations) && <Stack spacing={1} sx={{marginTop: 1}}>
              {locations.map((location) => (
                <Chip key={location.id} label={location.name} variant="outlined" size="small" />
              ))}
            </Stack>}
          </div>
        </div>
      }
    </>
  );
}

export default Scope;