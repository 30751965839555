import React from "react";
import { Router } from "@reach/router";
import New from './components/new';
import Step2 from './components/step_2';
import Step3 from './components/step_3';

export default () => {

  return (
    <Router>
      <New path="/new" />
      <Step2 path="/step_2/:firm_id" />
      <Step3 path="/step_3/:firm_id" />
    </Router>
  )

}
