import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  cell_large: {
    width: '300px',
  },
  cell: {
    width: '130px',
    textAlign: 'center'
  },
  date_range: {
    fontSize: '20px',
    marginLeft: '5px',
  }
}));

export default ({ results, date_range_str }) => {
  const classes = useStyles();
  const [local_results, setLocalResults] = useState({})

  useEffect(() => {
    console.log('results', results)
    setLocalResults(results);
  }, [results]);

  if(_.isEmpty(local_results)) return null;
  return (
    <>
    <Typography gutterBottom variant="h4" component="div">
      Weekly state of Frittata tasks
    </Typography>
    <Typography gutterBottom variant="h6" component="div">
      {local_results.date}
    </Typography>
    <ul>
      <li>
        <h4>There are {local_results.tasks_info.total} tasks in operations this week,</h4>
        <ul>
          <li>Completed Tasks: {local_results.tasks_info.completed}</li>
          <li>
            Uncompleted Tasks: {local_results.tasks_info.uncompleted}
            <ul>
              <li>Stucked: {local_results.tasks_info.stuck}</li>
              <li>On-Going: {local_results.tasks_info.on_going}</li>
              <li>Paused: {local_results.tasks_info.paused}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h4>Total spent time spent:</h4>
        <ul>
          <li>Raw: {local_results.tasks_info.total_time.raw_hours}</li>
          <li>Billable: {local_results.tasks_info.total_time.billable_hours}</li>
          <li>Admin Estimates: {local_results.tasks_info.total_time.admin_estimated_hours}</li>
        </ul>
      </li>

      <li>
        <h4>Firms that has Stuck Today:</h4>
        <ul>
          {local_results.stuck.map((firm, index) => {
            return(
            <li>
              <a href={`/app/firm/${firm.firm_id}`} target="_blank">{firm.firm}</a>
              <ul>
                {firm.tasks.map((task, i) => {
                  return(
                    <li>
                      ID: <a href={`/app/task/${task.task_id}`} target="_blank">{task.task_id}</a> ({task.task_name})
                      <ul>
                        {task.comment.map((comment, j) => {
                          return(
                            <li>{comment}</li>
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
                
              </ul>
            </li>
            )
          })}
        </ul>
      </li>
      <li>
        <h4>Firms that has Paused This Week:</h4>
        <ul>
          {local_results.paused.map((firm, index) => {
            return(
            <li>
              <a href={`/app/firm/${firm.firm_id}`} target="_blank">{firm.firm}</a>
              <ul>
                {firm.tasks.map((task, i) => {
                  return(
                    <li>
                      ID: <a href={`/app/task/${task.task_id}`} target="_blank">{task.task_id}</a> ({task.task_name})
                    </li>
                  )
                })}
                
              </ul>
            </li>
            )
          })}
        </ul>
      </li>
      <li>
        <h4>Firms that has On-Going This Week</h4>
        <ul>
          {local_results.on_going.map((firm, index) => {
            return(
            <li>
              <a href={`/app/firm/${firm.firm_id}`} target="_blank">{firm.firm}</a>
              <ul>
                {firm.tasks.map((task, i) => {
                  return(
                    <li>
                      ID: <a href={`/app/task/${task.task_id}`} target="_blank">{task.task_id}</a> ({task.task_name})
                    </li>
                  )
                })}
                
              </ul>
            </li>
            )
          })}
        </ul>
      </li>
    </ul>
    </>
  )

}