import React, { useEffect, useState } from 'react';
import _ from 'lodash';

export default ({columns, column, data}) => {
  const [value, setValue] = useState('');
  const column_def  = _.find(columns, {'column': column});
  const options     = column_def.options;

  useEffect(() => {
    const selected_option = _.find(options, {'value': data[column]});
    if(selected_option) {
      setValue(selected_option.label);
    }
  }, []);

  return (
    <span>{value}</span>
  )
}