import React from "react";
import { Router } from "@reach/router";

import List from './components'
import Add from './components/add'

export default () => {

  return (
    <Router>
      <Add path="add" />
      <List path="/" />
    </Router>
  )

}
