import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useFirmsCtx } from '../context';
import { TextField, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { API_MANAGE_ACCOUNTS } from '../../../../const/api_paths';
import { doAuthenticatedPut } from '../../../../actions/_methods';

const useStyles = makeStyles((theme) => ({
  form_wrapper: {
    display: 'flex',
    width: '500px',
    padding: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

export default ({ account, afterSave, onCancel }) => {
  const classes = useStyles();
  const { setIsLoading } = useFirmsCtx();
  const [value, setValue] = useState(account.name);
  const [local_account, setLocalAccount] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  const onSaveLocal = () => {
    setIsLoading('secondary');

    const data = {
      name: value.trim(),
    };

    doAuthenticatedPut(`${API_MANAGE_ACCOUNTS}/${local_account.id}`, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError,
    });
  };

  const onSuccess = (res) => {
    afterSave(res.account);
  };

  const onError = (res) => {
    alert(`Please try again...`);
  };

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);
  };

  useEffect(() => {
    setLocalAccount(account);
  }, [account]);

  useEffect(() => {
    setDisableButton(value.trim() === '');
  }, [value]);

  return (
    <div className={classes.form_wrapper}>
      <TextField
        variant="outlined"
        size="small"
        label="Account"
        value={value}
        onChange={onChange}
      />
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<SaveIcon />}
        onClick={onSaveLocal}
        disabled={disableButton}
      >
        Save
      </Button>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.button}
        startIcon={<CancelIcon />}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </div>
  );
};
