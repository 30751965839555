import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Button
} from '@mui/material';

import { API_PATH } from "../../../../const/api_paths";

export default ({data, view_mode}) => {

  const doReport1 = () => {
    const target_url = API_PATH(`/tasks/report_1.csv?${dataToParams()}`);
    window.location = target_url

  }

  const doReport2 = () => {
    const target_url = API_PATH(`/tasks/report_2.csv?${dataToParams()}`);
    window.location = target_url
  }

  const dataToParams = () => {
    const new_data = {...data};

    if (view_mode === 'uncompleted') {
      new_data.uncompleted = true;
    }

    Object.keys(new_data).forEach(key => {
      if (new_data[key] === null) {
        delete new_data[key];
      }
    });

    return new URLSearchParams(new_data).toString();
  }

  return(
    <div>
      <Button variant="outlined" onClick={doReport1}>Task Updates</Button>
      &nbsp;
      <Button variant="outlined" onClick={doReport2}>Daily Summary</Button>
      <pre>
        {/* {data} */}
      </pre>
    </div>
  )
}