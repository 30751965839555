import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default ({task}) => {

  return(
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">User</TableCell>
            <TableCell align="right">Minutes</TableCell>
            <TableCell align="right">Hours</TableCell>
            <TableCell align="right">Invoices</TableCell>
            <TableCell align="right">Pages</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {task.status_per_user.map(user =>
            <TableRow
              key={user.user_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                {user.display_name}
              </TableCell>
              <TableCell align="right">{user.total_minutes} mins</TableCell>
              <TableCell align="right">{user.total_hours} hrs</TableCell>
              <TableCell align="right">{user.total_invoices} invoice</TableCell>
              <TableCell align="right">{user.total_pages} page</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )

}
