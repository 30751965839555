import React, { useState, createContext, useContext, useEffect } from 'react';

import { API_FILTERED_USERS, API_MANAGE_FIRMS } from '../../../const/api_paths';
import { doAuthenticatedGet } from '../../../actions/_methods';

const FirmsCtx = createContext();

const useFirmsCtx = () => {
  const context = useContext(FirmsCtx);

  if (!context) {
    throw new Error(`useFirmsCtx must be used within a FirmsContext`);
  }
  return context;
};

const defaultFilters = {
  firm: '',
  account: '',
  location: '',
  status: '',
  tags: [],
};

const FirmsCtxProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [tags, setTags] = useState([]);
  const [firms, setFirms] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [totalPages, setTotalPages] = useState(0);
  const [totalFirms, setTotalFirms] = useState(0);
  const [noResult, setNoResult] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [openFirms, setOpenFirms] = useState(false);
  const [unassignedUsers, setUnassignedUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(false);

  const loadFirms = (type) => {
    if (type) setIsLoading(type);

    doAuthenticatedGet(
      API_MANAGE_FIRMS,
      {
        page: page,
        ...filters,
      },
      {
        general: onLoadFirmsSuccess,
        success: onLoadFirmsSuccess,
        error: (res) => alert('Please try again...'),
      }
    );
  };

  const getUsers = () => {
    doAuthenticatedGet(
      API_FILTERED_USERS,
      {},
      {
        success: onGetUserSuccess,
        general: onGetUserSuccess,
        error: (res) => alert('Please try again...'),
      }
    );
  };

  const onLoadFirmsSuccess = (res) => {
    setTags(res.tags);
    setFirms(res.firms);
    setIsLoading(null);
    setTotalFirms(res.total_firms);
    setTotalPages(res.total_pages);
    setNoResult(res.firms.length === 0);
  };

  const onGetUserSuccess = (res) => {
    setUsers(res);
    setIsUsersLoading(false);
  };

  const reloadData = (type) => {
    setIsLoading(type);
    loadFirms();
  };

  useEffect(() => {
    loadFirms('secondary');
    setFirstLoad(false);
  }, [page]);

  const value = {
    firms,
    users,
    tags,
    loadFirms,
    reloadData,
    isLoading,
    openFirms,
    setIsLoading,
    setOpenFirms,
    isUsersLoading,
    setIsUsersLoading,
    getUsers,
    unassignedUsers,
    setUnassignedUsers,
    setPage,
    totalPages,
    filters,
    setFilters,
    defaultFilters,
    firstLoad,
    noResult,
    totalFirms,
  };

  return <FirmsCtx.Provider value={value}>{children}</FirmsCtx.Provider>;
};

export { FirmsCtx, FirmsCtxProvider, useFirmsCtx };
