import React from 'react';
import { TaskCategoryCtxProvider } from '../../context/task_categories';
import TaskCategories from './TaskCategories';

const TaskCategoriesWithContext = () => {
  return (
    <TaskCategoryCtxProvider>
      <TaskCategories />
    </TaskCategoryCtxProvider>
  );
};

export default TaskCategoriesWithContext;
