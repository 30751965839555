import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Modal } from '@mui/material';
import { useTaskCategory } from '../../context/task_categories';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    backgroundColor: '#f8f8ff',
    borderRadius: '5px',
  },
  header: {
    display: 'flex',
    fontWeight: 500,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 24,
    paddingRight: 24,
    color: '#FFFFFF',
    backgroundColor: '#333232',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
  },
  title: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    display: 'flex',
    minHeight: 50,
    padding: 12,
    width: '100%',
    borderBottom: '1px solid #00000029',
  },
  item: {
    width: 100,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  value: {
    width: '100%',
    maxHeight: 150,
    overflow: 'auto',
    paddingLeft: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
});

const InfoItem = ({ title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.itemContainer}>
      <div className={classes.item}>{title}:</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

const TaskCategoryInfo = ({ open, handleClose }) => {
  const classes = useStyles();
  const { currentNode: node } = useTaskCategory();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <div className={classes.header}>Category Information</div>
        <div className={classes.container}>
          <InfoItem title="Title" value={node?.name || 'N/A'} />
          <InfoItem title="Description" value={node?.description || 'N/A'} />
          <InfoItem title="Status" value={node?.status || 'N/A'} />
        </div>
      </Box>
    </Modal>
  );
};

export default TaskCategoryInfo;
