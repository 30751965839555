export const modalStyles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 450,
    backgroundColor: '#f8f8ff',
    borderRadius: '5px',
  },
  cancel: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
  delete: {
    backgroundColor: '#cd0000',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#a10c0c',
    },
  },
  header: {
    display: 'flex',
    fontWeight: 500,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 24,
    paddingRight: 24,
    color: '#FFFFFF',
    backgroundColor: '#333232',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 24,
    marginRight: 24,
    paddingTop: 24,
    paddingBottom: 24,
    gap: 10,
    borderTop: '1px solid #00000029',
  },
  title: {
    marginTop: 15,
    marginBottom: 15,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
}