import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DropdownUsers from './DropdownUsers';
import DropdownRoles from './DropdownRoles';
import { doAuthenticatedPost } from '../../../../../actions/_methods';
import { API_MANAGE_USERS } from '../../../../../const/api_paths';
import { useGlobal } from '../../../../context/global';
import { useFirmsCtx } from '../../context';

const useStyles = makeStyles({
  container: {
    margin: 30,
  },
  save: {
    backgroundColor: '#1976d2',
    color: '#ffffff',
    textTransform: 'none',
    height: 40,
    marginLeft: 20,
    '&:hover': {
      backgroundColor: '#175da2',
    },
  },
});

const AssignUser = ({ firm }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { getUsers, unassignedUsers, setIsUsersLoading } = useFirmsCtx();
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  const checkInputs = () => {
    const isUserSelected = selectedUser && selectedUser.id;
    const isRoleSelected = selectedRole && selectedRole !== '';

    return isUserSelected && isRoleSelected;
  };

  const assignUser = () => {
    const inputsAreValid = checkInputs();

    if (inputsAreValid) {
      setIsUsersLoading(true);
      doAuthenticatedPost(
        `${API_MANAGE_USERS}/${selectedUser.id}/add_role`,
        {
          firm_id: firm.id,
          name: selectedRole,
        },
        {
          general: onAssignSuccess,
          success: onAssignSuccess,
          error: onAssignError,
        }
      );
    } else {
      const message = 'Please select both the user and the role.';
      prompt(message, { variant: 'error' });
    }
  };

  const onAssignSuccess = () => {
    const message = `Successfully assigned ${selectedUser.label} to ${firm.name}`;

    getUsers();
    prompt(message);
  };

  const onAssignError = () => {
    const message = 'Something went wrong. Please try again';

    prompt(message, { variant: 'error' });
  };

  return (
    <div className={classes.container}>
      <DropdownUsers
        users={unassignedUsers}
        setSelectedUser={setSelectedUser}
      />
      <DropdownRoles setSelectedRole={setSelectedRole} />
      <Button variant="contained" className={classes.save} onClick={assignUser}>
        Assign User
      </Button>
    </div>
  );
};

export default AssignUser;
