import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, TextField, FormControl, CircularProgress } from '@mui/material';
import { API_SEARCH_FILTERED_USERS } from '../../../../const/api_paths';
import { useReportsCtx } from "../../../reports/context.js";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const useStyles = makeStyles({
  control: {
    width: 260,
  },
  listbox: {
    maxHeight: 200,
    overflow: 'auto',
  },
  label: {
    color: '#00000029',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 28,
      height: 28,
      marginRight: 10,
    },
  },
  optionStyle: {
    fontSize: '11pt',
    padding: 10,
    cursor: 'pointer',
    borderBottom: '1px solid #00000029',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
});

const defaultStyles = {
  width: 260,
};

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    window.clearTimeout(window.timeout);
    window.timeout = window.setTimeout(() => func.apply(context, args), wait);
  };
}


const CustomOption = ({ option, classes, ...props }) => {
  const avatar = <Avatar src={option.profile_pic}>{option.code}</Avatar>;
  return (
    <div {...props} className={classes.optionStyle}>
      <span className={classes.avatarWrapper}>{avatar}</span>
      {option.label}
    </div>
  );
};

const DropdownUsers = ({
  styles,
  setSelectedUser,
  controlClassName,}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { getSearchUsers, dropdown_users  } = useReportsCtx();

  const doDebouncedUser = (searchTerm) => {
    getSearchUsers(searchTerm)
  }

  const debouncedFetchData = debounce(doDebouncedUser, 500);

  const handleInputChange = (event) => {
    if(event && event.target) {
      debouncedFetchData(event.target.value)
    }
  };

  const handleChange = (event, new_value) => {
    setValue(new_value)
    setSelectedUser(new_value)
  };

  useEffect(() => { console.log('Value:', value)}, [value])

  return (
    <FormControl className={controlClassName || classes.control}>
      <Autocomplete
        value={value}
        onInputChange={handleInputChange}
        onChange={handleChange}
        options={dropdown_users}
        autoComplete
        getOptionLabel={(option) => option.label || ''}
        filterMode="suggest"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for a user"
            InputProps={{
              ...params.InputProps,
              endAdornment: isLoading ? (
                <CircularProgress color="inherit" size="small" />
              ) : null,
            }}
            InputLabelProps={{ className: classes.label }}
          />
        )}
        ListboxProps={{ className: classes.listbox }}
        renderOption={(props, option) => (
          <CustomOption
            {...props}
            option={option}
            classes={classes}
            key={option.id}
          />
        )}
        sx={styles || defaultStyles}
      />
    </FormControl>
  );
};

export default DropdownUsers;