import React, { useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import debounce from 'lodash.debounce';

const useStyles = makeStyles({
  container: {
    marginRight: '10px',
    width: (props) => props.width,
  },
  input: {
    width: '100%',
  },
});

export default ({ name, title, default_value, onChange, width }) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState(default_value);

  const onChangeLocal = (e) => {
    const target_value = e.target.value;
    setValue(target_value);
    onChange(sendBackData(target_value));
  };

  const debouncedChangeHandler = useCallback(debounce(onChangeLocal, 300), []);

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  };

  return (
    <div className={classes.container}>
      <TextField
        size="small"
        variant="outlined"
        className={classes.input}
        label={title}
        default_value={value}
        onChange={debouncedChangeHandler}
      />
    </div>
  );
};
