import React from "react";
import Status from './_SetStatus';
import Text from './_Text';
import Hours from './_Hours';
import Dates from './_Dates';

export default ({type, task, field, onChange}) => {

  if(type === 'status') {
    return <Status task={task} field={field} onChange={onChange} />
  } else if(type === 'hours') {
    return <Hours task={task} field={field} onChange={onChange} />
  } else if(type === 'dates') {
    return <Dates task={task} field={field} onChange={onChange} />
  } else {
    return <Text task={task} field={field} onChange={onChange} />
  }

}
