import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const AccountingTaskButton = ({ firm }) => {
  const classes = useStyles();

  const onClick = () => {
    window.location = `/app/manage/accounting_task/${firm.id}`;
  };

  return (
    <IconButton
      color="default"
      className={classes.button}
      onClick={onClick}
      aria-label="settings"
    >
      <AssignmentIcon fontSize="small" />
    </IconButton>
  );
};

export default AccountingTaskButton;
