import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Selector from './_selector';
import AccountWideSelector from './_account_wide_selector';
import LocationSelector from './_location_selector';

const TaskFor = ({ firm_id, onChange, task }) => {
  const [scope, setScope] = useState('firm-wide');
  const [account, setAccount] = useState(null);
  const [locations, setLocations] = useState([]);
  const [work_as_one, setWorkAsOne] = useState(false);

  useEffect(() => {
    if(task) {
      updateData({
        scope: task.scope,
        account: task.accounts && task.accounts.length > 0 ? task.accounts[0] : null,
        locations: task.locations,
        work_as_one: task.work_as_one
      });
    }
  }, [task]);

  const triggerChange = (data) => {
    if(onChange) {
      onChange(data);
    }
  }

  const newData = (data) => {
    let new_data = {
      scope,
      account,
      locations,
      work_as_one
    }

    if(data.scope !== undefined) { new_data.scope = data.scope }
    if(data.account !== undefined) { new_data.account = data.account }
    if(data.locations !== undefined) { new_data.locations = data.locations }
    if(data.work_as_one !== undefined) { new_data.work_as_one = data.work_as_one }

    return new_data;
  }

  const updateData = (data) => {
    data = newData(data);
    setScope(data.scope);
    setAccount(data.account);
    setLocations(data.locations);
    setWorkAsOne(data.work_as_one);
    triggerChange(data);
  }

  const handleChangeScope = (new_scope) => {
    if(new_scope == 'firm-wide') {
      updateData({scope: new_scope, account: null, locations: [], work_as_one: false});
    } else {
      updateData({scope: new_scope});
    }
  }

  const handleChangeAccountLocation = ({account, locations}) => {
    updateData({
      account: account,
      locations: locations,
    });
  }

  return (
    <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
      <div>
        <Selector scope={scope} onChange={(e) => handleChangeScope(e)} />
      </div>
      {scope == 'account-wide' && 
        <AccountWideSelector
          firm_id={firm_id}
          account={account}
          work_as_one={work_as_one}
          onChange={(e) => updateData({account: e})}
          onChangeWorkAsOne={(e) => updateData({work_as_one: e})}
        />
      }
      {scope == 'location' && 
        <LocationSelector
          firm_id={firm_id}
          account={account}
          locations={locations}
          work_as_one={work_as_one}
          default_locations={task?.locations}
          onChangeAccount={(e) => handleChangeAccountLocation(e)}
          onChangeLocations={(e) => updateData({locations: e})}
          onChangeWorkAsOne={(e) => updateData({work_as_one: e})}
        />
      }
    </Box>
  );
}

export default TaskFor;