import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { API_TASKS } from '../../../../../../../const/api_paths';
import { doAuthenticatedDelete } from '../../../../../../../actions/_methods';
import { useTask } from '../../../../../../context/task';

import AlertInfo from '../../../../../../../shared/components/alerts/info';
import { useFirmTasks } from '../../../../../../context/firm_tasks';

import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import AddComment from './AddComment';
import PreviewNightlyReport from './PreviewNightlyReport';
import CommentList from './CommentList';
import CommentHeader from './CommentHeader';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '2px',
    width: '100%',
  },
  loading: {
    height: '100px',
    width: '100%',
  },
}));

const CommentSection = ({
  task,
  default_comments,
  previousComments,
  onChange,
}) => {
  const classes = useStyles();
  const { preferences } = useFirmTasks();
  //const [comments, setComments] = useState([]);
  const { comments, setComments } = useTask();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [open, setOpen] = useState(false);
  const showNightlyReport = preferences.nightly_report === 'true';

  useEffect(() => {
    if (default_comments) {
      setComments(default_comments);
    }
  }, []);

  const doDelete = (comment) => {
    const url = `${API_TASKS}/${task.id}/task_comments/${comment.id}`;
    doAuthenticatedDelete(
      url,
      {},
      {
        success: onSuccessDelete,
        general: onSuccessDelete,
        error: onError,
      }
    );
  };

  const onError = (res) => {
    alert('Please try again');
  };

  const onSuccessDelete = (comments) => {
    alertInfo('Comment deleted');
    setLoading(false);
    setComments(comments);
  };

  const alertInfo = (modal_message) => {
    setOpen(true);
    setMessage(modal_message);
    setSeverity('success');
  };

  const resetAlertInfo = () => {
    setOpen(false);
    setMessage('');
    setSeverity('success');
  };

  const onClickDelete = (file) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      setLoading(true);
      doDelete(file);
      onChange();
    }
  };

  const onAddComment = (res) => {
    setComments(res);
    onChange();
  };

  return (
    <>
      <Stack className={classes.box}>
        <CommentHeader task={task} setComments={setComments} />

        {loading ? (
          <LoadingButton
            className={classes.loading}
            loading
            loadingIndicator="Loading..."
          />
        ) : (
          <CommentList
            comments={comments}
            onDelete={onClickDelete}
            previousComments={previousComments}
          />
        )}

        <AddComment task={task} onSubmit={(res) => onAddComment(res)} />
        {showNightlyReport && (
          <PreviewNightlyReport comments={comments} task={task} />
        )}
      </Stack>

      <AlertInfo
        open={open}
        message={message}
        severity={severity}
        onCloseHandler={resetAlertInfo}
      />
    </>
  );
};

export default CommentSection;
