import React from "react";
import _ from 'lodash';
import TaskGroup from "./_TaskGroup";

export default ({task_groups, filters}) => {

  const taskGroups = () => {
    try {
      if(filters.firm_id) {
        return _.filter(task_groups, {id: filters.firm_id})
      } else {
        return task_groups
      }
    } catch(err) {
      return task_groups
    }
  }

  return(
    <>
      {taskGroups().map(task_group => <TaskGroup
        key={`task_group_${Math.random()}`}
        task_group={task_group}
        filters={filters}
      />)}
    </>
  )
}