import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, CardContent, Card, CardHeader } from '@mui/material';
import { SpinnerWithBackdrop } from '../../../shared/components/utils/_spinner';
import { doAuthenticatedGet } from '../../../actions/_methods';
import { API_PATH, DATA_REPORTS, FIRMS_WEEKLY_AP_TRANSACTION_COUNT_REPORT } from '../../../const/api_paths';
import FilterBar from './FilterBar';
import Report from './report/Report';
import ErrorMessage from './report/ErrorMessage';
import { useReferences } from "../../context/references";

const useStyles = makeStyles({
  card: {
    marginTop: '10px',
    border: '1px solid #00000029',
  },
  dateRange: {
    width: 250,
  },
  buttonsContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
  header_box: {
    display: 'flex',
  },
});

const headerStyles = {
  color: '#23282C',
  fontWeight: 500,
};

const DataReport = ({ type }) => {
  const classes = useStyles();
  const [firms, setFirms] = useState([]);
  const [date, setDate] = useState(null);
  const [range, setRange] = useState(null);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFirms, setSelectedFirms] = useState(null);
  const dataType = type === 'week' ? 'Weekly' : 'Monthly';
  const { organizations, loadOrganizations } = useReferences();

  useEffect(() => {
    loadOrganizations()
  }, []);

  const downloadAPTransactionReport = () => {
    const queryParams = new URLSearchParams({
      firm_ids: selectedFirms,
      date: date,
      period_type: type,
    }).toString();
    const endpoint = API_PATH(`${FIRMS_WEEKLY_AP_TRANSACTION_COUNT_REPORT}.csv?${queryParams}`);

    window.location = endpoint;
  };

  const generateReport = () => {
    const endpoint = `${DATA_REPORTS}.json?`;
    setLoading(true);
    setError(null);

    doAuthenticatedGet(
      endpoint,
      {
        firm_ids: selectedFirms,
        date: date,
        period_type: type,
      },
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const downloadReport = () => {
    const queryParams = new URLSearchParams({
      firm_ids: selectedFirms,
      date: date,
      period_type: type,
    }).toString();
    const endpoint = API_PATH(`${DATA_REPORTS}.csv?${queryParams}`);

    window.location = endpoint;
  };

  const onSuccess = (response) => {
    setResults(response.data);
    setLoading(false);
  };

  const onError = (response) => {
    setError(response.error);
    setLoading(false);
  };

  useEffect(() => {
    setFirms(organizations);
  }, [organizations]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={`Data Report -- ${dataType}`}
        className={classes.header}
        titleTypographyProps={headerStyles}
      />
      <CardContent>
        <Box>
          <FilterBar
            type={type}
            date={date}
            firms={firms}
            error={error}
            results={results}
            setDate={setDate}
            setRange={setRange}
            selectedFirms={selectedFirms}
            generateReport={generateReport}
            downloadReport={downloadReport}
            downloadAPTransactionReport={downloadAPTransactionReport}
            setSelectedFirms={setSelectedFirms}
          />
          {loading ? (
            <SpinnerWithBackdrop />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : results.length > 0 ? (
            <Report type={type} results={results} resultsRange={range} />
          ) : (
            <ErrorMessage message="Please select a firm." />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default DataReport;
