import React from 'react';
import _ from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { useReferences } from '../../../../context/references';

const AccountWideSelector = ({firm_id, account, work_as_one, onChange, onChangeWorkAsOne}) => {
  const { organizations, loaded_organizations } = useReferences();

  const handleChange = (event, newValue) => {
    onChange(newValue)
  };

  return (
    <>
      {!loaded_organizations && <div sx={{marginTop: '20px'}}>loading firm account and locations...</div>}
      {loaded_organizations && <div sx={{marginTop: '20px'}}>
        <Autocomplete
          value={account}
          disablePortal
          options={_.find(organizations, {id: firm_id})?.children.map((account) => {
            return {
              id: account.id,
              label: account.name
            }
          })}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select Account" size="small" />}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              checked={work_as_one}
              onChange={(e) => onChangeWorkAsOne(e.target.checked)}
            />
          }
          label="Work as one"
        />
      </div>}
    </>
  )
}

export default AccountWideSelector;