import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import _ from 'lodash';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { doAuthenticatedPost, doAuthenticatedGet } from '../../../../../actions/_methods';
import {
  API_MANAGE_PROJECTS_SET_USERS
} from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({task}) => {
  const dispatch = useDispatch();
  const [selecting, setSelecting] = useState(false);
  const [value, setValue] = useState([]);
  const users = useSelector(state => state.project.users);

  useEffect(() => {
    setValue(task.users);
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
    var user_ids = newValue.map((u) => { return u.id });

    const url = API_MANAGE_PROJECTS_SET_USERS.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id, user_ids: user_ids };

    doAuthenticatedPost(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const onSuccess = (res) => {
    dispatch(projectActions.updateCategoryTask({task: res.task}))
  }

  const onError = (res) => {
    console.log(res)
  }

  const filterOptions = (options, state) => {
    let newOptions = [];
    options.forEach((element) => {
      if (
        element.label
          .replace(",", "")
          .toLowerCase()
          .includes(state.inputValue.toLowerCase())
      )
        newOptions.push(element);
    });

    return newOptions;
  };

  return (
    <>
    <Button onClick={() => setSelecting(!selecting)}>Assign User</Button>
    {!selecting && <Box style={{ paddingLeft: '30px' }}>
      {task.users.map((u) => {
        return (
          <Chip style={{ marginRight: '2px' }} label={u.label} />
        )
      })}
    </Box>
    }
    {selecting && <>
      <Box style={{width: '100%', marginBottom: "15px"}}>
        <Paper elevation={3} style={{width: '100%', padding: "15px 20px"}}>
          <Autocomplete
              multiple
              value={value}
              onChange={(event, newValue) => {
                // setValue(newValue)
                handleChange(newValue);
              }}
              size="small"
              options={users}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Users" placeholder="Users" />
              )}
              filterOptions={filterOptions}
              renderOption={(props, item) => (<li {...props} key={`options-${item.id}`}> {item.label} </li>)}
            />
        </Paper>
      </Box>
    </>}
    </>
  )

}
