import React, { useState, useEffect } from 'react';
import { Table, TableContainer, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sortData } from '../utils';
import ReportHeader from './ReportHeader';
import ReportBody from './ReportBody';
import ReportTitle from './ReportTitle';

const useStyles = makeStyles({
  container: {
    marginTop: 30,
  },
});

const Report = ({ results, range, title, maxHeight = 590 }) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState([]);
  const [sortKey, setSortKey] = useState('account');
  const [sortAscending, setSortAscending] = useState(true);
  const showResults = results.length > 0;

  useEffect(() => {
    setReportData(results);
  }, [results]);

  useEffect(() => {
    const sortedResults = sortData(results, sortKey, sortAscending);
    setReportData(sortedResults);
  }, [sortKey, sortAscending]);

  return (
    <div className={classes.container}>
      <ReportTitle range={range} title={title} showResults={showResults} />
      <TableContainer component={Paper} sx={{ maxHeight: maxHeight }}>
        <Table stickyHeader sx={{ minWidth: 900 }}>
          <ReportHeader
            sortKey={sortKey}
            setSortKey={setSortKey}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
          />
          <ReportBody results={reportData} />
        </Table>
      </TableContainer>
    </div>
  );
};

export default Report;
