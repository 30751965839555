import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  box: {
    flexBasis: '5%',
    marginRight: '2px',
  },
  paper: {
    padding: '10px 5px',
    minHeight: '44px',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '5%',
  },
}));

export default ({ task, field }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        <Typography>{task[field] || 0}</Typography>
      </Paper>
    </Box>
  );
};
