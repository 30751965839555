import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TextField,
  Box,
  Button,
  OutlinedInput,
  ListItemText,
  Stack,
} from "@mui/material";

import Organizational from "../../board/components/_basic_filters/Organizational";
import { API_PATH } from '../../../const/api_paths'
import { useReferences } from "../../context/references";

const statuses = ["completed", "paused", "on_going"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default () => {
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedRaw, setSelectedRaw] = useState(true);
  const [selectedBillable, setSelectedBillable] = useState(false);
  const [filters, setFilters] = useState({raw_selected: true});
  const { organizations, loadOrganizations } = useReferences();

  useEffect(() => {
    loadOrganizations()
  }, []);

  const handleChangeStatuses = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStatuses(
      typeof value === 'string' ? value.split(',') : value,
    );

    const statuses = typeof value === 'string' ? value.split(',') : value;
    setFilters({...filters, statuses: statuses.join(',')});
  };

  const handleGenerateReport = () => {
    const target_url = API_PATH(`/tasks/weekly_summary.csv?${dataToParams()}`);
    window.location = target_url
  };

  const dataToParams = () => {
    const new_data = {...filters};

    Object.keys(new_data).forEach(key => {
      if (new_data[key] === null) {
        delete new_data[key];
      }
    });

    return new URLSearchParams(new_data).toString();
  }

  const onChangeOrganization = (new_data) => {
    setFilters({...filters, ...new_data});
  }

  const handleChangeWeek = (event) => {
    console.log(event.target.value);
    setFilters({...filters, week: event.target.value});
  };

  const handleChangeRawHourOption = (event) => {
    setSelectedRaw(event.target.checked)
    setFilters({ ...filters, raw_selected: event.target.checked });
  };

  const handleChangeBillableHourOption = (event) => {
    setSelectedBillable(event.target.checked)
    setFilters({ ...filters, billable_selected: event.target.checked });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Stack direction="row" spacing={2}>
        <FormControl fullWidth={true}>
          <Box display="inline-flex" justifyContent="space-between">
            <Organizational
              onChange={onChangeOrganization}
              options={organizations}
            />
          </Box>
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          label="Date Range"
          type="week"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeWeek}
        />
        <FormControl fullWidth={true}>
          <InputLabel id="lbl_statuses">Statuses</InputLabel>
          <Select
            multiple
            labelId="lbl_statuses"
            value={selectedStatuses}
            onChange={handleChangeStatuses}
            input={<OutlinedInput label="Statuses" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                <ListItemText primary={status} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControl fullWidth={true}>
          <Box display="inline-flex">
            <FormControlLabel
              control={
                <Checkbox checked={selectedRaw} onChange={handleChangeRawHourOption} />
              }
              label="Raw Hours"
            />
            <FormControlLabel
              control={
                <Checkbox checked={selectedBillable} onChange={handleChangeBillableHourOption} />
              }
              label="Billable Hours"
            />
          </Box>
        </FormControl>
      </Stack>
      <Button variant="contained" color="primary" onClick={handleGenerateReport}>
        Generate Report
      </Button>
    </Box>
  );
}

