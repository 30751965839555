import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    height: 100,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    borderBottom: '1px solid #00000029',
  },
  message: {
    fontSize: 18,
    color: '#000000a8',
  },
  status: {
    fontWeight: 600,
  },
});

const ProtectConfirmation = ({ isFirmProtected }) => {
  const classes = useStyles();
  const protectedStatus = isFirmProtected ? 'unprotected' : 'protected';

  return (
    <div className={classes.container}>
      <span className={classes.message}>
        Set this firm as{' '}
        <span className={classes.status}>{protectedStatus}</span>?
      </span>
    </div>
  );
};

export default ProtectConfirmation;
