import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import Toggle from '../Toggle';
import Text from '../Text';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    borderBottom: '1px solid #00000029',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  regularPad: {
    padding: '20px 20px 20px 20px',
  },
  smallPad: {
    padding: '10px 20px 10px 20px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
  },
});

const Options = ({ updatedLocation, setUpdatedLocation }) => {
  const [showInputs, setShowInputs] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setShowInputs(updatedLocation.auto_count === 'true');
  }, [updatedLocation]);

  return (
    <div className={classes.body}>
      <div className={classes.section}>
        <div className={`${classes.content} ${classes.smallPad} `}>
          <span className={classes.title}>Autocount</span>
          <Toggle
            on={updatedLocation.auto_count}
            preferenceKey="auto_count"
            setPreferences={setUpdatedLocation}
          />
        </div>
      </div>
      {showInputs && (
        <>
          <div className={classes.section}>
            <div className={`${classes.content} ${classes.smallPad} `}>
              <span className={classes.title}>Slug</span>
              <Text
                name="slug"
                label="Slug"
                stateKey="slug"
                required={true}
                stateSetter={setUpdatedLocation}
                existingValue={updatedLocation.slug}
              />
            </div>
          </div>
          <div className={classes.section}>
            <div className={`${classes.content} ${classes.smallPad} `}>
              <span className={classes.title}>Password</span>
              <Text
                name="password"
                label="Password"
                type="password"
                required={true}
                stateKey="password"
                stateSetter={setUpdatedLocation}
                existingValue={updatedLocation.password}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Options;
