import React from 'react';
import { Chip } from '@mui/material';

export default ({column, data}) => {
  const user_roles = data[column];

  const RolesInTable = () => {
    if(user_roles && user_roles.length > 0) {
      return(
        <table>
          <tbody>
            {user_roles.map((user_role) => {
              return(
                <tr>
                  <td>
                    <Chip label={user_role.name} color="primary" />
                  </td>
                  <td style={{paddingLeft: '10px'}}>{user_role.firm_name}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      );
    } else {
      return <span>---</span>;
    }
  }

  return (
    <RolesInTable />
  )
}