import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';

export default ({task}) => {
  const creator = task.creator;

  if (!creator) {
    return null;
  }

  return(
    <div>
      <Typography variant="h8">
        Created by:
      </Typography>
      <Chip
        avatar={<Avatar alt={creator.label} src={creator.avatar} />}
        label={creator.label}
        variant="outlined"
        style={{marginLeft: '3px'}}
      />
    </div>
  )
}
