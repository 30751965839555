import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { doAuthenticatedPut } from '../../../../../actions/_methods';
import { API_MANAGE_FIRMS } from '../../../../../const/api_paths';
import { useGlobal } from '../../../../context/global';
import { useFirmsCtx } from '../../context';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    marginTop: 40,
    padding: '20px 20px 0 20px',
    borderTop: '1px solid #00000029',
  },
  cancel: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
  save: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const Footer = ({ firm, tags, handleClose }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { loadFirms } = useFirmsCtx();

  const handleSave = () => {
    doAuthenticatedPut(
      `${API_MANAGE_FIRMS}/${firm.id}`,
      {
        tags: tags,
      },
      {
        general: onSuccess,
        success: onSuccess,
        error: onError,
      }
    );
  };

  const onSuccess = () => {
    const message = 'Successfully applied tags';

    loadFirms('primary');
    prompt(message);
  };

  const onError = () => {
    const message = 'Something went wrong. Please try again';

    prompt(message, { variant: 'error' });
  };

  return (
    <div className={classes.footer}>
      <Button
        variant="contained"
        className={classes.cancel}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button variant="contained" className={classes.save} onClick={handleSave}>
        Apply tags
      </Button>
    </div>
  );
};

export default Footer;
