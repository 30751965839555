import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { doAuthenticatedPut } from '../../../actions/_methods';
import ViewRow from './_view_row';
import EditRow from './_edit_row';

export default ({api_path, model_name, columns, listable_columns, updatable_columns, data}) => {
  const [mode, setMode] = useState('view');
  const [local_data, setLocalData] = useState({});
  const isManagingUsers = columns.some(column => column.column === 'email')

  useEffect(() => {
    setLocalData({...data});
  }, [data])

  const onUpdate = (new_data) => {
    doAuthenticatedPut(api_path + `/${data.id}`, new_data, {
      general: onSuccessUpdate,
      success: onSuccessUpdate,
      error: onErrorUpdate
    });
  }

  const onSuccessUpdate = (res) => {
    alert('Successfully updated')
    setLocalData(res[model_name])
    setMode('view')
  }

  const onErrorUpdate = (res) => {
    const errors = Object.keys(res.errors).map(key => key + ': ' + res.errors[key].join()).join("\n");
    alert(`Please try again...\n${errors}`);
  }

  return <>
    {mode == 'view' && <ViewRow
      columns={columns}
      listable_columns={listable_columns}
      data={local_data}
      setMode={setMode}
      isManagingUsers={isManagingUsers}
    />}
    {mode == 'edit' && <EditRow
      columns={columns}
      updatable_columns={updatable_columns}
      data={local_data}
      table_column_count={listable_columns.length + 1}
      setMode={setMode}
      onUpdate={onUpdate}
      path={api_path + `/${data.id}`}
    />}
  </>
}
