import React from 'react';
import { makeStyles } from '@mui/styles';
import ReportList from '../reports/list';

import { ReportsCtxProvider } from '../reports/context';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    marginBottom: '12px',
    padding: '12px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <ReportsCtxProvider>
      <ReportList />
    </ReportsCtxProvider>
  );
};
