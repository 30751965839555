import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { doAuthenticatedGet } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS_CATEGORIES } from '../../../../../const/api_paths';

import { projectActions } from '../../../../../store/project_slice';

import Category from './_category';

export default ({firm_id}) => {
  const firm = useSelector(state => state.project.firm);
  const categories = useSelector(state => state.project.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const url = API_MANAGE_PROJECTS_CATEGORIES.replace(':firm_id', firm_id);
    doAuthenticatedGet(url, {}, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const onSuccess = (res) => {
    dispatch(
      projectActions.setCategoriesEtc(res)
    )
  }

  const onError = (res) => {
    alert('Sorry please try again')
  }

  return (
    <>
      <h1>Step 3 - {firm.name}</h1>
      {categories.map((category) => {
        return(
          <Category category={category} firm_id={firm_id} />
        )
      })}
    </>
  )

}
