import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { STYLES } from './styles';
import DisplayElement from './micro_components/display_element';
import { IfAnyGranted } from 'react-authorization';
import { usePermission } from '../../../features/context/permission';
import { useGlobal } from '../../../features/context/global';
import { doAuthenticatedPost } from '../../../actions/_methods';
import { IMPERSONATE_USER } from '../../../const/api_paths';
import { setAuthToken } from '../../../actions/_auth';

const useStyles = makeStyles({
  impersonate: {
    marginTop: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ({
  columns,
  listable_columns,
  data,
  setMode,
  isManagingUsers,
}) => {
  const classes = useStyles();
  const { atleastAdmin, getMyRoles } = usePermission();
  const {
    isImpersonating,
    setIsImpersonating,
    setImpostor,
    storeOriginalToken,
    myself,
  } = useGlobal();

  const isActive = data?.status !== 'inactive';
  const isCurrentUser = data?.id === myself?.id;
  const showImpersonate = !isImpersonating && isActive && !isCurrentUser;

  const EditButton = () => (
    <Button variant="contained" size="small" onClick={() => setMode('edit')}>
      Edit
    </Button>
  );

  const onImpersonate = () => {
    storeOriginalToken();

    doAuthenticatedPost(
      IMPERSONATE_USER,
      { id: data.id },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccess = (res) => {
    setIsImpersonating(true);
    setAuthToken(res.token);
    setImpostor(res.impostor);

    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.location = '/app/all';
  };

  return (
    <tr>
      {listable_columns.map((col) => {
        return (
          <td style={STYLES.td} key={`datatable_td_${Math.random()}`}>
            <DisplayElement
              columns={columns}
              column={col['column']}
              data={data}
            />
          </td>
        );
      })}
      <td style={STYLES.td_end}>
        <div className={classes.container}>
          <EditButton />
          {isManagingUsers && (
            <IfAnyGranted expected={atleastAdmin()} actual={getMyRoles()}>
              {showImpersonate && (
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  className={classes.impersonate}
                  onClick={onImpersonate}
                >
                  Impersonate
                </Button>
              )}
            </IfAnyGranted>
          )}
        </div>
      </td>
    </tr>
  );
};
