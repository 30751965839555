import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Stack,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import Organizational from './row/Organizational';
import AssignedTo from './row/AssignedTo';
import TaskRowHeader from './_TaskRowHeader';
import MultiTimeTracker from './row/multi_time_tracker/Main';
import { useFirmTasks } from '../../../../../context/firm_tasks';
import PendingAcknowledgements from '../../../../../manage/firms/components/accounting-task/table/manage-card/pending-items/PendingAcknowledgements';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    marginBottom: '12px',
    padding: '12px',
  },
  left: {
    width: '88%',
  },
  right: {
    width: '12%',
    paddingTop: '16px',
    minHeight: '203px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'normal',
    margin: '1px',
  },
  header: {
    width: '100%',
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: '74px',
  },
  expand: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: '16px',
    marginBottom: '4px',
  },
  hours: {
    '& p': {
      fontSize: '.9rem',
    },
  },
}));

export default ({
  task,
  show_task_info,
  onToggleShowTaskInfo,
  onChange,
  preferences,
}) => {
  const classes = useStyles();
  const { fetchReferences, showPendingItems, setShowPendingItems } =
    useFirmTasks();
  const isAccountingTask = task?.accounting_project_task_id !== null;

  useEffect(() => {
    if (isAccountingTask) fetchReferences(task);
  }, [task]);

  return (
    <Card elevation={3} className={classes.card} key={`task-row-${task.id}`}>
      <Box className={classes.left}>
        <TaskRowHeader task={task} preferences={preferences} />
        <CardContent className={classes.content}>
          <Grid
            container
            justifyContent="space-between"
            direction="column"
            alignItems="flex-start"
          >
            <Organizational task={task} />
            <AssignedTo task={task} />
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              className={classes.hours}
            >
              <Typography>{task['work_hours']} work hours</Typography>
              <Typography>{task['billable_hours']} billable hours</Typography>
              <Typography>
                {task['admin_est_hours']} admin estimated hours
              </Typography>
              <Typography>
                {task['client_est_hours']} client estimated hours
              </Typography>
              <Typography>{task['last_count']} last count</Typography>
            </Stack>
          </Grid>
        </CardContent>
      </Box>
      <Box className={classes.right}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <MultiTimeTracker task={task} onChange={onChange} />
          <IconButton aria-label="settings" className={classes.expand}>
            <ExpandMoreIcon
              fontSize="large"
              onClick={() => onToggleShowTaskInfo(!show_task_info)}
            />
          </IconButton>
        </Grid>
      </Box>

      <PendingAcknowledgements
        open={showPendingItems}
        task={task}
        handleClose={() => setShowPendingItems(false)}
      />
    </Card>
  );
};
