import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTaskCategory } from '../../context/task_categories';
import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import { TASK_CATEGORY } from '../../../const/api_paths';
import { doAuthenticatedPut } from '../../../actions/_methods';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 400,
    maxWidth: 800,
    height: 40,
  },
  title: {
    height: 40,
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 450,
    color: '#121212',
    flexWrap: 'wrap',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    fontSize: 14.5,
    lineHeight: 1.3,
    paddingTop: 2,
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 5,
  },
  icon: {
    cursor: 'pointer',
  },
});

const TaskCategory = ({
  node,
  path,
  setShowAddModal,
  setShowEditModal,
  setShowDeleteModal,
  setShowInfoModal,
}) => {
  const classes = useStyles();
  const {
    setLoading,
    setParentNode,
    setCurrentNode,
    setTaskCategories,
    getParentNode,
  } = useTaskCategory();
  const isActive = node && node.status === 'active';

  const prepareModalState = (event, showModalSetter) => {
    event.stopPropagation();
    setCurrentNode(node);
    setParentNode(getParentNode(node));
    showModalSetter(true);
  };

  const handleAddIconClick = (event) => {
    prepareModalState(event, setShowAddModal);
  };

  const handleEditIconClick = (event) => {
    prepareModalState(event, setShowEditModal);
  };

  const handleDeleteIconClick = (event) => {
    prepareModalState(event, setShowDeleteModal);
  };

  const handleInfoIconClick = (event) => {
    prepareModalState(event, setShowInfoModal);
  };

  const endpoint = () => {
    return TASK_CATEGORY.replace(':id', node.id);
  };

  const onToggleTaskCategory = (status) => {
    const requiredParams = {
      task_category: { status },
    };

    setLoading(true);
    doAuthenticatedPut(endpoint(), requiredParams, {
      success: onToggleSuccess,
      general: onToggleSuccess,
      error: (res) => console.error(res),
    });
  };

  const onToggleSuccess = (res) => {
    setTaskCategories(res);
    setLoading(false);
  };

  const handleToggleStatus = (status) => {
    onToggleTaskCategory(status);
  };

  return (
    <Box component="div" className={classes.box}>
      <div className={classes.title}>{node.title}</div>
      <div className={classes.container}>
        <AddCircleOutlineIcon
          color="default"
          className={classes.icon}
          onClick={handleAddIconClick}
        />
        <EditOutlinedIcon
          color="info"
          className={classes.icon}
          onClick={handleEditIconClick}
        />
        {isActive ? (
          <ToggleOnOutlinedIcon
            color="success"
            className={classes.icon}
            onClick={() => handleToggleStatus('inactive')}
          />
        ) : (
          <ToggleOffOutlinedIcon
            className={classes.icon}
            color="action"
            onClick={() => handleToggleStatus('active')}
          />
        )}

        <HighlightOffIcon
          color="error"
          className={classes.icon}
          onClick={handleDeleteIconClick}
        />
        <InfoOutlinedIcon
          color="secondary"
          className={classes.icon}
          onClick={handleInfoIconClick}
        />
      </div>
    </Box>
  );
};

export default TaskCategory;