import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Paper, Avatar, Tooltip } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';

const useStyles = makeStyles((theme) => ({
  box: {
    flexBasis: '10%',
    marginRight: '2px',
  },
  paper: {
    padding: '4px 10px',
    display: 'flex',
    minHeight: '44px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexBasis: '10%',
  },
  avatar_group: {
    '& > div': {
      width: '35px',
      height: '35px',
    },
  },
  loc_name: {
    marginTop: '-15px',
    fontSize: '12px',
    textAlign: 'center',
  },
}));

export default ({ task }) => {
  const classes = useStyles();

  const locName = (org_structure) => {
    try {
      if (org_structure && org_structure[2]) {
        return (
          <>
            <div className={classes.loc_name}>
              <i>{org_structure[2].name}</i>
            </div>
          </>
        );
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  };

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        <AvatarGroup max={3} className={classes.avatar_group}>
          {task.organizational_structure.map((org) => (
            <Tooltip key={`org_tooltip_${Math.random()}`} title={org?.name}>
              <Avatar
                key={`org_avatar_${Math.random()}`}
                alt={org?.name}
                src={org?.avatar}
              >
                {org?.code}
              </Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      </Paper>
      {locName(task.organizational_structure)}
    </Box>
  );
};
