import React from 'react';
import { Box, Chip, Typography } from '@mui/material';

export default ({ task }) => {

  if (task.work_as_one_locations.length > 0) {
    return (
      <Box  p={1} sx={{ background: "#fafafa", width: "100%" }} >
        <Typography variant="body2" gutterBottom="true">Work as one target locations: </Typography>
        {
          task.work_as_one_locations.map(loc => <Chip
            label={loc.label}
            variant="outlined"
            style={{ marginRight: "3px" }}
            size="small"
          />)
        }
      </Box >
    )
  } else {
    return null
  }
}
