import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, CardContent, Card, CardHeader } from '@mui/material';
import { API_PATH, SCOPE_VARIANCE_REPORT } from '../../../const/api_paths';
import { doAuthenticatedGet } from '../../../actions/_methods';
import FilterBar from '../time_variance/FilterBar';
import ReportContent from './ReportContent';
import { useReferences } from "../../context/references";

const useStyles = makeStyles({
  card: {
    marginTop: '10px',
    border: '1px solid #00000029',
  },
});

const headerStyles = {
  color: '#23282C',
  fontWeight: 500,
};

const ScopeVarianceReport = () => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [firms, setFirms] = useState([]);
  const [range, setRange] = useState(null);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firmID, setFirmID] = useState(null);
  const [noData, setNoData] = useState(false);
  const [accountID, setAccountID] = useState(null);
  const [locationID, setLocationID] = useState(null);
  const [addedResults, setAddedResults] = useState([]);
  const [deletedResults, setDeletedResults] = useState([]);
  const isRequiredFieldsMissing = firmID === null || date === null;
  const { organizations, loadOrganizations } = useReferences();

  useEffect(() => {
    loadOrganizations()
  }, []);

  useEffect(() => {
    setError(null);
    setResults([]);
  }, [firmID]);

  useEffect(() => {
    setFirms(organizations);
  }, [organizations]);

  useEffect(() => {
    const resultIsEmpty = results.length === 0;
    setAddedResults(resultIsEmpty ? [] : results[0].added_tasks);
    setDeletedResults(resultIsEmpty ? [] : results[1].deleted_tasks);
  }, [results]);

  const generateReport = () => {
    setLoading(true);
    setError(null);
    setResults([]);

    doAuthenticatedGet(
      SCOPE_VARIANCE_REPORT,
      {
        date: date,
        firm_id: firmID,
        ...(accountID ? { account_id: accountID } : {}),
        ...(locationID ? { location_id: locationID } : {}),
      },
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const downloadReport = () => {
    const queryParams = new URLSearchParams({
      date: date,
      firm_id: firmID,
      ...(accountID ? { account_id: accountID } : {}),
      ...(locationID ? { location_id: locationID } : {}),
    }).toString();
    const endpoint = API_PATH(`${SCOPE_VARIANCE_REPORT}.csv?${queryParams}`);

    window.location = endpoint;
  };


  const onSuccess = (response) => {
    setResults(response.data);
    setNoData(response?.data.length === 0);
    setLoading(false);
  };

  const onError = (response) => {
    setError(response.error);
    setLoading(false);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Scope Variance Report"
        titleTypographyProps={headerStyles}
      />
      <CardContent>
        <Box>
          <FilterBar
            date={date}
            firms={firms}
            results={results}
            setDate={setDate}
            setRange={setRange}
            setError={setError}
            setFirmID={setFirmID}
            setResults={setResults}
            setAccountID={setAccountID}
            setLocationID={setLocationID}
            generateReport={generateReport}
            downloadReport={downloadReport}
            isRequiredFieldsMissing={isRequiredFieldsMissing}
          />
          <ReportContent
            range={range}
            error={error}
            noData={noData}
            loading={loading}
            results={results}
            addedResults={addedResults}
            deletedResults={deletedResults}
            isRequiredFieldsMissing={isRequiredFieldsMissing}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ScopeVarianceReport;
