import React from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

const useStyles = makeStyles({
  timePicker: (props) => ({
    width: props.width,
  }),
});

const CustomTimePicker = ({ value, label, onChange, width = 200 }) => {
  const classes = useStyles({ width });

  const handleTimeChange = (event) => {
    const time = event.target.value;

    onChange(time);
  };

  return (
    <TextField
      size="small"
      type="time"
      label={label}
      value={value}
      onChange={handleTimeChange}
      className={classes.timePicker}
    />
  );
};

export default CustomTimePicker;
