import React from 'react';
import { makeStyles } from '@mui/styles';
import { API_MANAGE_FIRMS } from '../../../../../const/api_paths';
import { Box, Modal } from '@mui/material';
import ProtectConfirmation from './ProtectConfirmation';
import Header from '../tags/Header';
import Footer from './Footer';
import { doAuthenticatedPut } from '../../../../../actions/_methods';
import { useFirmsCtx } from '../../context';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    backgroundColor: '#f8f8ff',
    padding: 16,
    borderRadius: '5px',
  },
});

const ProtectFirm = ({
  firm,
  open,
  afterSave,
  handleClose,
  isFirmProtected,
}) => {
  const classes = useStyles();
  const { setIsLoading } = useFirmsCtx();

  const onSave = () => {
    const updatedStatus = isFirmProtected ? 'false' : 'true';

    setIsLoading('secondary');
    doAuthenticatedPut(
      `${API_MANAGE_FIRMS}/${firm.id}`,
      {
        protected: updatedStatus,
      },
      {
        general: (res) => onSuccess(res, updatedStatus),
        success: (res) => onSuccess(res, updatedStatus),
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccess = (res, updatedStatus) => {
    const firmStatus = updatedStatus === 'true' ? 'protected' : 'unprotected';
    const message = `Successfully updated. Firm is now ${firmStatus}`;

    afterSave(res.firm, message);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <Header firm={firm} />
        <ProtectConfirmation isFirmProtected={isFirmProtected} />
        <Footer onSave={onSave} onCancel={handleClose} />
      </Box>
    </Modal>
  );
};

export default ProtectFirm;
