import { API_HOST } from '../../../const/site'
import { API_MANAGE_USERS } from '../../../const/api_paths';

export const MODEL_DEF = {
  api_path: API_MANAGE_USERS,
  model_name: 'user',
  peer_name: 'users',
  columns: [
    {column: 'profile_pic',   header: 'Profile Pic',  type: 'profile_pic', api_host: API_HOST},
    {column: 'firstname',     header: 'Firstname',    type: 'string'},
    {column: 'lastname',      header: 'Lastname',     type: 'string'},
    {column: 'email',         header: 'Email',        type: 'string'},
    {column: 'roles',         header: 'Roles',        type: 'user_roles'},
    {column: 'status',        header: 'Status',       type: 'status', default: 'active'},
  ],
  listable_columns: [
    {column: 'profile_pic',   header: 'Profile Pic'},
    {column: 'firstname',     header: 'Firstname'},
    {column: 'lastname',      header: 'Lastname'},
    {column: 'email',         header: 'Email'},
    {column: 'status',        header: 'Status'},
    {column: 'roles',         header: 'Roles'},
  ],
  creatable_columns: [
    ['firstname', 'lastname', 'email'],
    ['status']
  ],
  updatable_columns: [
    ['firstname', 'lastname', 'email'],
    ['profile_pic'],
    ['roles'],
    ['status']
  ]
};