import React from 'react';
import TaskWrapper from './_TaskWrapper';
import { useFirmTasks } from '../../../../../context/firm_tasks';
import { TaskCtxProvider } from '../../../../../context/task';

export default () => {
  const { firmTasks } = useFirmTasks();

  return (
    <>
      {firmTasks.map((task) => (
        <TaskCtxProvider>
          <TaskWrapper key={`task-wrapper-${task.id}`} task={task} />
        </TaskCtxProvider>
      ))}
    </>
  );
};
