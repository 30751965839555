import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import TaskGroups from './_default/TaskGroups';
import { groupTasks, extractFirms } from '../../../actions/tasks';
import { useFirmTasks } from '../../../../context/firm_tasks';
import { useReferences } from '../../../../context/references';

export default ({ group_by, filters }) => {
  const { tasks } = useFirmTasks();
  const { organizations } = useReferences();
  const [task_groups, setTaskGroups] = useState([]);

  useEffect(() => {
    setTaskGroups(groupRawTasks(tasks, organizations));
  }, [tasks, organizations]);

  const getGroupings = (organizations) => {
    if (group_by == 'accounts') {
      return extractFirms('firm_id', organizations);
    } else {
      return organizations;
    }
  };

  const groupRawTasks = (tasks, organizations) => {
    return groupTasks(getGroupings(organizations), tasks, group_by);
  };

  return (
    <TaskGroups
      task_groups={task_groups}
      group_by={group_by}
      filters={filters}
    />
  );
};
