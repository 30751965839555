import React from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';

const STYLES = {
  element: {
    marginRight: '10px',
    width: '100%',
  }
}

export default ({columns, column, data, onChange}) => {
  const label = _.find(columns, {'column': column}).header;

  const onChangeLocal = (e) => {
    let new_data = {}
    new_data[column] = e.target.value;
    onChange(new_data);
  }

  return (<>
    <TextField
      defaultValue={data[column]}
      size="small"
      label={label}
      variant="outlined"
      style={{width: '100%'}}
      onChange={onChangeLocal}
      style={STYLES.element}
    />
  </>)
}
