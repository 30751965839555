import React from 'react';
import DefaultView from './variants/DefaultView';

export default ({ group_by, view_mode, filters }) => {
  if (view_mode == 'default') {
    return <DefaultView group_by={group_by} filters={filters} />;
  } else {
    return <DefaultView group_by={group_by} filters={filters} />;
  }
};
