import React from 'react';
import { makeStyles } from '@mui/styles';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

const useStyles = makeStyles({
  title: {
    color: '#23282C',
    fontWeight: 500,
    textAlign: 'center',
  },
});

const headers = [
  { id: 'account', label: 'Account' },
  { id: 'location', label: 'Location' },
  { id: 'task', label: 'Task Name' },
  { id: 'date', label: 'Date' },
  { id: 'admin_estimate', label: 'Admin Estimate' },
  { id: 'actual_work', label: 'Actual Work Hours' },
  { id: 'variance', label: 'Variance' },
  { id: 'link', label: 'Link' },
];

const columnWidths = [
  '15%', // Account
  '15%', // Location
  '15%', // Task Name
  '10%', // Date
  '9%', // Admin Estimate
  '10%', // Actual Work Hours
  '8%', // Variance
  '8%', // Link
];

const ReportHeader = ({
  sortKey,
  setSortKey,
  sortAscending,
  setSortAscending,
}) => {
  const classes = useStyles();

  const handleSort = (newSortKey) => {
    setSortKey(newSortKey);
    setSortAscending(!sortAscending);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => {
          return (
            <TableCell
              key={header.id}
              className={classes.title}
              style={{
                width: columnWidths[headers.indexOf(header)],
                cursor: 'pointer',
              }}
              onClick={() => handleSort(header.id)}
              role="button"
              tabIndex={0}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {header.label}
                {sortKey === header.id &&
                  (sortAscending ? (
                    <ArrowDropUpOutlinedIcon color="info" />
                  ) : (
                    <ArrowDropDownOutlinedIcon color="error" />
                  ))}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default ReportHeader;
