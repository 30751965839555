import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  commonText: {
    color: '#23282C',
    fontWeight: 500,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 14,
    paddingRight: 14,
  },
  range: {
    marginBottom: 10,
    marginLeft: 14,
  },
});

const ReportTitle = ({ range, title, showResults }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      {title && <p className={classes.commonText}>{title}</p>}
      {showResults && (
        <p className={`${classes.commonText} ${title ? '' : classes.range}`}>
          {range}
        </p>
      )}
    </div>
  );
};

export default ReportTitle;
