import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Name from "./_add_task/Name";
import AccountLocation from "./_add_task/AccountLocation";
import DueDate from "./_add_task/DueDate";
import TaskCategory from "./_add_task/TaskCategory";
import Tags from "./_add_task/Tags";
import Description from "./_add_task/Description";
import { doAuthenticatedPost } from "../../../actions/_methods";
import { API_TASK_REQUESTS } from "../../../const/api_paths";
import { useSnackbar } from "notistack";
import { useFirmTasks } from "../../context/firm_tasks";
import { Box, Card, CardHeader, CardContent, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  inner_box: {
    display: "flex",
    marginTop: "25px",
  },
  buttons_box: {
    marginTop: "25px",
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel-btn": {
      marginLeft: "15px",
    },
  },
}));

export default ({
  onCloseRequestTask,
  onAddData,
  group_by,
  group_id,
  filters,
}) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const { preferences } = useFirmTasks();
  const allowAddName = preferences.firm_v2 === "false";

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    clearData();
  }, []);

  const clearData = () => {
    setData({});
    setData({ firm_id: filters.firm_id });
  };

  const doRequestTask = (params) => {
    doAuthenticatedPost(API_TASK_REQUESTS, params, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  };

  const onClickRequestTask = () => {
    let error = validateTaskRequest();
    if (error.length > 0) {
      enqueueSnackbar(error.join("\n"), { variant: "error" });
    } else {
      doRequestTask(data);
    }
  };

  const validateTaskRequest = () => {
    let error = [];
    if (
      data["task_category_id"] == undefined ||
      data["task_category_id"] == ""
    ) {
      error.push("Sub Category is required.");
    }

    return error;
  };

  const onChange = (new_data) => {
    const params = { ...data, ...new_data };
    setData(params);
  };

  const onSuccess = (res) => {
    clearData();
    enqueueSnackbar("You have successfully requested a task.", {
      variant: "success",
    });
    onCloseRequestTask();
  };

  const onError = (res) => {
    enqueueSnackbar("An ERROR occured, please try again.", {
      variant: "error",
    });
  };

  const SaveTaskRequestButton = () => (
    <Button
      variant="contained"
      color="primary"
      disabled={false}
      onClick={onClickRequestTask}
    >
      Request Task
    </Button>
  );

  const CancelButton = () => (
    <Button
      variant="contained"
      className="cancel-btn"
      onClick={onCloseRequestTask}
    >
      Cancel
    </Button>
  );

  return (
    <Card className={classes.box} id="request_task_section">
      <CardHeader title="Request a new Task" />
      <CardContent>
        {allowAddName && (
          <Box>
            <Name onChange={onChange} />
          </Box>
        )}
        <Box className={classes.inner_box}>
          <AccountLocation
            onChange={onChange}
            firm_id={filters.firm_id}
            {...{ group_by, group_id }}
          />
        </Box>
        <Box className={classes.inner_box}>
          <DueDate onChange={onChange} />
          <TaskCategory onChange={onChange} required={true} />
        </Box>
        <Box className={classes.inner_box}>
          <Tags onChange={onChange} />
        </Box>
        <Box className={classes.inner_box}>
          <Description onChange={onChange} />
        </Box>
        <Box className={classes.buttons_box}>
          <SaveTaskRequestButton />
          <CancelButton />
        </Box>
        <Box>{data.length}</Box>
      </CardContent>
    </Card>
  );
};
