import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Stack } from '@mui/material';

const useStyles = makeStyles({
  buttonsContainer: {
    paddingTop: 24,
    marginTop: 20,
    justifyContent: 'flex-end',
  },
  cancel: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
  save: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const Footer = ({
  saveFunction,
  cancelFunction,
  saveLabel = 'Save',
  disableSave = false,
  cancelLabel = 'Cancel',
}) => {
  const classes = useStyles();

  return (
    <Stack direction="row" spacing={2} className={classes.buttonsContainer}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={cancelFunction}
      >
        {cancelLabel}
      </Button>
      <Button
        disabled={disableSave}
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={saveFunction}
      >
        {saveLabel}
      </Button>
    </Stack>
  );
};

export default Footer;
