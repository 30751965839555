import React from 'react';
import { makeStyles } from '@mui/styles';
import { usePermission } from '../../../../../context/permission';
import { useFirmTasks } from '../../../../../context/firm_tasks';
import { Box } from '@mui/material';
import WorkHours from '../../_summary_row/WorkHours';
import BillableHours from '../../_summary_row/BillableHours';
import ManagementEstimatedHours from '../../_summary_row/ManagementEstimatedHours';
import ClientEstimatedHours from '../../_summary_row/ClientEstimatedHours';
import TotalTask from '../../_summary_row/TotalTask';
import PageCount from '../../_summary_row/PageCount';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    marginBottom: '2px',
  },
}));

export default () => {
  const classes = useStyles();
  const { isAngel } = usePermission();
  const { preferences } = useFirmTasks();
  const showPageCount = preferences.show_page_count === 'true';

  return (
    <Box className={classes.row} key={`task-row-summary`}>
      <TotalTask />
      <WorkHours />
      {showPageCount && (
        <>
          <PageCount pageType={'last'} />
          <PageCount pageType={'processed'} />
          <PageCount pageType={'remaining'} />
        </>
      )}
      {isAngel() && (
        <>
          <BillableHours />
          <ManagementEstimatedHours />
          <ClientEstimatedHours />
        </>
      )}
    </Box>
  );
};
