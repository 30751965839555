import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, CardContent, Card, CardHeader } from '@mui/material';
import Report from './report/Report';
import FilterBar from '../shared/FilterBar';
import { doAuthenticatedGetPromise } from '../../../actions/_methods';
import { API_PATH, WEEKLY_FIRM_REPORTS } from '../../../const/api_paths';
import { SpinnerWithBackdrop } from '../../../shared/components/utils/_spinner';

const useStyles = makeStyles({
  card: {
    marginTop: '10px',
    border: '1px solid #00000029',
  },
  dateRange: {
    width: 250,
  },
  buttonsContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const headerStyles = {
  color: '#23282C',
  fontWeight: 500,
};

const WeeklyFirmReports = () => {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [week, setWeek] = useState(null);
  const [range, setRange] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tagsString, setTagsString] = useState('');
  const [displayedResults, setDisplayedResults] = useState([]);

  const generateReport = () => {
    const queryParams = new URLSearchParams({
      week: week,
    }).toString();
    const endpoint = `${WEEKLY_FIRM_REPORTS}.json?${queryParams}`;

    setLoading(true);

    doAuthenticatedGetPromise(endpoint)
      .then((response) => {
        onGenerateSuccess(response);
      })
      .catch((error) => {
        onGenerateError(error);
      });
  };

  const onGenerateSuccess = (response) => {
    setResults(response.data);
    setTags(response.tags);
    setLoading(false);
  };

  const onGenerateError = (error) => {
    console.log(error, 'error');
    setLoading(false);
  };

  const downloadReport = () => {
    const queryParams = new URLSearchParams({
      week: week,
      tags: tagsString,
    }).toString();
    const endpoint = API_PATH(`${WEEKLY_FIRM_REPORTS}.csv?${queryParams}`);

    window.location = endpoint;
  };

  useEffect(() => {
    generateReport();
  }, [week]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Firms: Weekly Report"
        className={classes.header}
        titleTypographyProps={headerStyles}
      />
      <CardContent>
        <Box>
          <FilterBar
            week={week}
            tags={tags}
            results={results}
            setWeek={setWeek}
            setRange={setRange}
            setTagsString={setTagsString}
            generateReport={generateReport}
            downloadReport={downloadReport}
            setDisplayedResults={setDisplayedResults}
          />
          {loading ? (
            <SpinnerWithBackdrop />
          ) : (
            <Report results={displayedResults} resultsRange={range} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default WeeklyFirmReports;
