import React, { useState, useEffect } from 'react';
import { Table, TableContainer, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportHeader from './ReportHeader';
import ReportBody from './ReportBody';

const useStyles = makeStyles({
  container: {
    marginTop: 30,
  },
  range: {
    color: '#23282C',
    fontWeight: 500,
    marginBottom: 10,
    marginLeft: 14,
  },
  cell_large: {
    width: '300px',
  },
  cell: {
    width: '130px',
    textAlign: 'center',
  },
  date_range: {
    fontSize: '20px',
    marginLeft: '5px',
  },
});

const Report = ({ results, resultsRange }) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    setReportData(results);
  }, [results]);

  return (
    <div className={classes.container}>
      <p className={classes.range}>{resultsRange}</p>
      <TableContainer component={Paper} sx={{ maxHeight: 590 }}>
        <Table stickyHeader sx={{ minWidth: 900 }}>
          <ReportHeader />
          <ReportBody results={reportData} />
        </Table>
      </TableContainer>
    </div>
  );
};

export default Report;
