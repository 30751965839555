import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PrimaryText from './_PrimaryText';

const useStyles = makeStyles({
  attached_items: {
    maxHeight: '200px',
    overflow: 'auto',
    border: '1px solid #ffffff3b',
  },
  attachment_item: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
});

const AttachedFiles = ({ files, previousFiles }) => {
  const classes = useStyles();
  const [displayedFiles, setDisplayedFiles] = useState([]);

  useEffect(() => {
    const mergedFiles = [...previousFiles, ...files];
    setDisplayedFiles(mergedFiles);
  }, [files, previousFiles]);

  return (
    <Stack className={classes.attached_items}>
      <List>
        {displayedFiles.map((file) => (
          <ListItem key={file.id} className={classes.attachment_item}>
            <AttachFileIcon />
            <ListItemText
              primary={<PrimaryText data={file} />}
              secondary={file.type}
              primaryTypographyProps={{ fontSize: '12px' }}
              secondaryTypographyProps={{ fontSize: '10px' }}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default AttachedFiles;
