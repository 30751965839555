import React, { useEffect, useState } from 'react';
import _, { set } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { FormGroup } from '@mui/material';
import { useReferences } from '../../../../context/references';

const LocationSelector = ({firm_id, account, default_locations, work_as_one, onChangeAccount, onChangeLocations, onChangeWorkAsOne}) => {
  const { organizations, loaded_organizations } = useReferences();
  const [location_ids, setLocationIds] = useState([]);

  useEffect(() => {
    setLocationIds(default_locations)
    console.log(default_locations)
  }, [organizations, default_locations])

  const handleChange = (event, newValue) => {
    onChangeAccount({
      account: newValue,
      locations: []
    })
    setLocationIds([])
  };

  const onChangeCheckLocation = (location, checked) => {
    let new_location_ids = []

    if (checked) {
      new_location_ids = [...location_ids, {id: location.id, name: location.name}]
    } else {
      new_location_ids = location_ids.filter((loc) => loc.id !== location.id)
    }

    onChangeLocations(new_location_ids)
    setLocationIds(new_location_ids)
  }

  return (
    <>
      {loaded_organizations}
      {!loaded_organizations && <div sx={{marginTop: '20px'}}>loading firm locations...</div>}
      {loaded_organizations && <div sx={{marginTop: '20px'}}>
        <Autocomplete
          value={account}
          disablePortal
          options={_.find(organizations, {id: firm_id})?.children.map((account) => {
            return {
              id: account.id,
              label: account.name
            }
          })}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select Account" size="small" />}
          onChange={handleChange}
        />
        {_.find(_.find(organizations, {id: firm_id})?.children, {id: account?.id})?.children &&
          <Box component="section" sx={{ px: 2, mt: 1, border: '1px solid grey', maxHeight: 300, overflow: 'scroll' }}>
            <FormGroup>
              {
                _.find(_.find(organizations, {id: firm_id})?.children, {id: account?.id})?.children?.map((location) => {
                  return (
                    <FormControlLabel
                      key={location.id}
                      control={
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                          checked={_.find(location_ids, {id: location.id}) !== undefined}
                          onChange={(e) => onChangeCheckLocation(location, e.target.checked)}
                        />
                      }
                      label={location.name}
                    />
                  )
                })
              }
            </FormGroup>
          </Box>
        }

        <FormControlLabel
          control={
            <Checkbox
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              checked={work_as_one}
              onChange={(e) => onChangeWorkAsOne(e.target.checked)}
            />
          }
          label="Work as one"
        />
      </div>}
    </>
  )
}

export default LocationSelector;