import React, { useEffect, useState } from "react";
import Tasks from "./_Tasks";

export default ({task_group}) => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    setTasks(task_group.tasks);
  }, [task_group]);

  return(
    <div style={{ marginTop: '20px' }}>
      <Tasks tasks={tasks} />
    </div>
  )
}