import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import {
  Card,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Typography,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  box: {
    marginRight: '2px',
    width: '100%',
  },
  inner_box: {
    display: 'flex',
  },
  list: {
    width: '100%',
  },
  item: {
    paddingTop: '0',
    paddingBottom: '0',
    marginTop: '0',
    marginBottom: '0',
  },
  list_item: {
    paddingTop: '1px',
  },
  card: {
    display: 'flex',
    marginBottom: '25px',
  },
  card_title: {
    paddingLeft: '10px',
    paddingBottom: '10px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    marginRight: '10px',
  },
  check_icon: {
    size: 'medium',
    color: 'green',
    minWidth: '28px',
    padding: '0'
  },
  delete_icon: {
    fontSize: 'medium',
    color: 'red',
  },
}));

const name = 'checklist';
const title = 'Checklist';

export default ({ default_value, onChange }) => {
  const classes = useStyles();
  const [checklist, setChecklist] = useState(default_value);
  const [value, setValue] = useState('');

  const isEnter = (e) => {
    let key_code = (e.which || e.keyCode)
    return (key_code === 13 && !e.shiftKey)
  }

  const onSubmit = () => {
    const new_checklist = checklist;
    const new_val = value;
    new_checklist.push(new_val);
    setChecklist(new_checklist);
    setValue('')
    onChange(sendBackData(new_checklist));
  }

  const onDelete = (e) => {
    let indx = e.currentTarget.dataset.index;
    let new_checklist = checklist;
    new_checklist.splice(indx, 1);
    setChecklist(new_checklist);
    onChange(sendBackData(new_checklist));
  }

  const onChangeLocal = (e) => {
    setValue(e.target.value);
  }

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = checklist;
    return data;
  }

  const DataList = () => {
    return (
      <Card className={classes.card}>
        <List className={classes.list}>
          <Typography component="div" className={classes.card_title}>
            Checklist
          </Typography>
          {checklist.map((row, indx) => (
            <ListItem
              key={value}
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <DeleteIcon onClick={onDelete} data-index={indx} />
                </IconButton>
              }
              className={classes.list_item}
            >
              <ListItemButton className={classes.item}>
                <ListItemIcon className={classes.check_icon}>
                  <CheckBoxOutlineBlankIcon
                    edge="start"
                    className={classes.check_icon}
                  />
                </ListItemIcon>
                <ListItemText className={classes.item} primary={row} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Card>
    )
  }

  return (
    <Box className={classes.box}>
      {checklist.length > 0 && <DataList />}
      <Box className={classes.inner_box}>
        <TextField
          variant="outlined"
          size="small"
          className={classes.input}
          label={title}
          value={value}
          onChange={onChangeLocal}
          onKeyPress={(e) => {
            if (isEnter(e)) {
              e.preventDefault();
              onSubmit();
              return false
            }
          }}
        />
        <Button
          variant="contained"
          onClick={onSubmit}
          // disabled={submitting}
          style={{ marginLeft: '10px' }}
        >Add</Button>
      </Box>
    </Box>
  )
}