import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { doAuthenticatedPost } from '../../../../../actions/_methods';
import { API_MANAGE_ACCOUNT_CREATE_PROJECTS } from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({firm_id}) => {
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const onSubmit = () => {
    const url = API_MANAGE_ACCOUNT_CREATE_PROJECTS.replace(':firm_id', firm_id)
    const data = { name: name };

    setSubmitting(true);
    doAuthenticatedPost(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  };

  const onSuccess = (res) => {
    dispatch(projectActions.appendAccount(res.account))
    setName('')
    setSubmitting(false)
  };

  const onError = (res) => {
    alert('Please try again')
    setSubmitting(false)
  };

  return (
    <div style={{marginBottom: '20px'}}>
      <TextField
        label="New Account"
        variant="outlined"
        value={name}
        onChange={handleChange}
        disabled={submitting}
        size="small"
      />
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={submitting}
        style={{marginLeft: '10px'}}
      >Add Account</Button>
    </div>
  )
}