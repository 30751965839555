import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import AttachedFiles from './_AttachedFiles';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  box: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '2px',
  },
});

export default ({ files, previousFiles }) => {
  const classes = useStyles();
  const [uploaded_files, setUploadedFiles] = useState([]);
  const includesFiles = (files.length || previousFiles.length) > 0;

  useEffect(() => {
    setUploadedFiles(files);
  }, []);

  if (includesFiles) {
    return (
      <Paper elevation={4} className={classes.wrapper}>
        <Stack className={classes.box}>
          <AttachedFiles files={uploaded_files} previousFiles={previousFiles} />
        </Stack>
      </Paper>
    );
  } else {
    return null;
  }
};
