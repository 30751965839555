import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Modal } from '@mui/material';
import VersionList from './VersionList';
import FilterButtons from '../../../FilterButtons';
import AddVersion from './AddVersion';
import { doAuthenticatedGet } from '../../../../../../../../actions/_methods';
import { ACCOUNTING_TASK_REFERENCE_VERSIONS } from '../../../../../../../../const/api_paths';
import { Spinner } from '../../../../../../../../shared/components/utils/_spinner';
import ErrorMessage from '../../../../../../../reports/data_reports/report/ErrorMessage';
import EditReference from './EditReference';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    backgroundColor: '#f8f8ff',
    padding: 20,
    borderRadius: '5px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 20,
  },
  name: {
    fontSize: 20,
    marginLeft: 10,
    color: '#00000069',
  },
  text: {
    width: '90%',
  },
});

const ManageReference = ({
  open,
  handleClose,
  reference: data,
  fetchReferences,
}) => {
  const classes = useStyles();
  const [versions, setVersions] = useState([]);
  const [reference, setReference] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditReference, setShowEditReference] = useState(false);
  const [showAddVersion, setShowAddVersion] = useState(false);

  const fetchVersions = () => {
    setIsLoading(true);
    doAuthenticatedGet(
      ACCOUNTING_TASK_REFERENCE_VERSIONS,
      { reference_id: reference.id },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => alert('Please try again'),
      }
    );
  };

  const onSuccess = (res) => {
    setVersions(res);
    setIsLoading(false);
  };

  useEffect(() => {
    setReference(data);
    setVersions(data?.versions || []);
  }, [data]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <div className={classes.container}>
          <div>
            <span className={classes.header}>Manage Reference:</span>
            <span className={classes.name}>{reference?.name}</span>
          </div>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setShowEditReference(true)}
          >
            Edit
          </Button>
        </div>

        <EditReference
          reference={reference}
          open={showEditReference}
          setReference={setReference}
          fetchReferences={fetchReferences}
          handleClose={() => setShowEditReference(false)}
        />

        {isLoading ? (
          <Spinner height={200} />
        ) : versions.length > 0 ? (
          <VersionList versions={versions} />
        ) : (
          <ErrorMessage
            height={200}
            message="This reference does not have any released versions yet."
          />
        )}

        <FilterButtons
          labelCancel="Close"
          labelSave="New Version"
          onClickSave={() => setShowAddVersion(true)}
          onClickCancel={handleClose}
        />

        <AddVersion
          open={showAddVersion}
          reference={reference}
          fetchVersions={fetchVersions}
          handleClose={() => setShowAddVersion(false)}
        />
      </Box>
    </Modal>
  );
};

export default ManageReference;
