import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

const LABEL = 'Add Break in Minutes';

const useStyles = makeStyles((theme) => ({
  element_row: {
    display: 'flex',
    flexGrow: 1,
    margin: 7
  },
  textfield: {
    width: '100%',
    marginRight: '10px'
  }
}));

export default ({onSubmit}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const onClick = (e) => {
    const comment_value = `+${value} breaks`;
    onSubmit(comment_value)
    setValue('')
  }

  const onChange = (e) => {
    setValue(e.target.value)
    console.log(e.target.value)
  }

  return(
    <div className={classes.element_row}>
      <TextField
        label={LABEL}
        size="small"
        type="number"
        className={classes.textfield}
        InputProps={{
          endAdornment: <InputAdornment position="start">mins</InputAdornment>,
          inputMode: 'numeric',
          pattern: '[0-9]'
        }}
        value={value}
        onChange={onChange}
      />
      <Button
        variant="contained"
        size="small"
        onClick={onClick}
      >Add</Button>
    </div>
  )

}