export const STYLES = {
  table: {
    width: '100%',
  },
  th: {
    borderTop: '1px solid #777777',
    borderBottom: '1px solid #777777',
    borderLeft: '1px solid #777777',
    textAlign: 'left',
    padding: '7px 10px',
  },
  th_end: {
    borderTop: '1px solid #777777',
    borderBottom: '1px solid #777777',
    borderLeft: '1px solid #777777',
    borderRight: '1px solid #777777',
    textAlign: 'left',
    padding: '5px 10px',
  },
  td: {
    borderTop: '1px solid #777777',
    borderBottom: '1px solid #777777',
    borderLeft: '1px solid #777777',
    textAlign: 'left',
    padding: '10px 10px',
  },
  td_end: {
    borderTop: '1px solid #777777',
    borderBottom: '1px solid #777777',
    borderLeft: '1px solid #777777',
    borderRight: '1px solid #777777',
    textAlign: 'left',
    padding: '10px 10px',
  },

  td_edit: {
    borderTop: '1px solid #777777',
    borderBottom: '1px solid #777777',
    borderLeft: '1px solid #777777',
    textAlign: 'left',
    padding: '10px',
  },
  td_edit_end: {
    borderTop: '1px solid #777777',
    borderBottom: '1px solid #777777',
    borderLeft: '1px solid #777777',
    borderRight: '1px solid #777777',
    textAlign: 'left',
    padding: '10px',
  },
  card_box: {
    margin: '10px',
  },
  inner_box: {
    display: 'flex',
    marginBottom: '25px',
  },
  create_wrapper: {
    marginBottom: "25px",
  }
}