import React from 'react';
import { Chip, Stack } from '@mui/material';

const FirmTags = ({ tags = [] }) => {
  const tagsWillOverflow = tags.length > 3;
  const displayTags = tagsWillOverflow ? tags.slice(0, 3) : tags;

  return (
    <Stack direction="row" spacing={1} width={300}>
      {displayTags.map((tag) => (
        <Chip
          key={tag}
          size="small"
          label={tag}
          color="default"
          variant="outlined"
        />
      ))}
      {tagsWillOverflow && (
        <Chip size="small" label="..." color="default" variant="outlined" />
      )}
    </Stack>
  );
};

export default FirmTags;
