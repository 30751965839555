import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Paper, Avatar, Tooltip } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';

const useStyles = makeStyles((theme) => ({
  box: {
    flexBasis: '12%',
    marginRight: '2px',
  },
  paper: {
    padding: '4px 10px',
    display: 'flex',
    minHeight: '44px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar_group: {
    '& > div': {
      width: '35px',
      height: '35px',
    },
  },
}));

export default ({ task }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        <AvatarGroup max={3} className={classes.avatar_group}>
          {task.assigned_to.map((user) => (
            <Tooltip key={`avatar_tooltip_${Math.random()}`} title={user.label}>
              <Avatar
                key={`assigned_to_avatar_${Math.random()}`}
                alt={user.name}
                src={user.avatar}
              ></Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      </Paper>
    </Box>
  );
};

// 1. Display all assigned user
// 2. Display if no assigned user
// 3. Ability to assign user
// 4. Ability to remove user assignment
