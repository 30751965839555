export const STYLES = (theme) => {
  return({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    margin_bottom: {
      marginBottom: '15px',
    },
    margin_top: {
      marginTop: '15px',
    }
  })
};