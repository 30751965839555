import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Name             from '../_default/info/Name';
import AccountLocation  from "../_default/info/AccountLocation";
import DueDate          from "../_default/info/DueDate";
import AssignedTo       from "../_default/info/AssignedTo";
import Tags             from "../_default/info/Tags";
import Description      from "../_default/info/Description";
import File             from "../_default/info/File";
import CheckList        from "../_default/info/Checklist";

import {
  doAuthenticatedPut,
  doAuthenticatedDelete
} from "../../../../../../actions/_methods";
import { API_TASKS } from "../../../../../../const/api_paths";
import { useReferences } from "../../../../../context/references";

const useStyles = makeStyles((theme) => ({
  box: {
    width: '65%',
    marginBottom: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  inner_box: {
    display: 'flex',
    marginTop: '25px',
  },
  buttons_box: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel-btn': {
      marginLeft: '15px'
    },
    '& .delete-btn': {
      marginLeft: '15px'
    }
  }
}));

export default ({task, onTaskUpdate, onTaskDelete, onCancelEdit}) => {
  const classes = useStyles();
  const { connected_users } = useReferences();
  const [data, setData] = useState({});
  const [orig_data, setOrigData] = useState({});
  const read_mode = false;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const sanitized_task = sanitizeTask(task)
    setData(sanitized_task);
    setOrigData(sanitized_task);
  }, [task, connected_users]);

  const sanitizeTask = (task_data) => {
    var new_task = {...task_data};

    const levels = [1,2,3];
    levels.forEach(i => {
      if(task_data.organizational_structure[i - 1]) {
        new_task[`level_${i}`] = task_data.organizational_structure[i - 1];
      }
    });

    return new_task;
  };

  const onChange = (new_data) => {
    setData({...data, ...new_data});
  };

  const onCancelUpdateTask = () => {
    setData({...orig_data});
    onCancelEdit();
  };

  const onClickUpdateTask = () => {
    doAuthenticatedPut(`${API_TASKS}/${data.id}`, data, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  };

  const onClickDeleteTask = () => {
    if(window.confirm('Are you sure you want to delete this task?')) {
      doAuthenticatedDelete(`${API_TASKS}/${data.id}`, data, {
        success: onSuccessDelete,
        general: onSuccessDelete,
        error: onError,
      });
    }
  };

  const onSuccess = (res) => {
    enqueueSnackbar('You have successfully UPDATED the task.', {variant: 'success'});
    onTaskUpdate(res.task);
  }

  const onError = (res) => {
    enqueueSnackbar('An ERROR occured, please try again.', {variant: 'error'});
  }

  const onSuccessDelete = (res) => {
    enqueueSnackbar('You have successfully DELETED the task.', {variant: 'info'});
    onTaskDelete(res.task);
  }

  return(
    <Card className={classes.box}>
      <CardContent>
        <Box>
          <Name
            default_value={task.name}
            read_mode={read_mode}
            onChange={onChange}
          />
        </Box>

        <Box className={classes.inner_box}>
          <AccountLocation
            read_mode={read_mode}
            default_level_1={data.level_2 && data.level_2.id}
            default_level_2={data.level_3 && data.level_3.id}
            onChange={onChange}
            firm_id={task.firm_id}
          />
        </Box>

        <Box className={classes.inner_box}>
          <DueDate
            read_mode={read_mode}
            default_value={task.due_date}
            onChange={onChange}
          />
          <AssignedTo
            read_mode={read_mode}
            default_value={data.assigned_to}
            onChange={onChange}
            options={connected_users}
          />
        </Box>

        <Box className={classes.inner_box}>
          <Tags
            read_mode={read_mode}
            default_value={task.tags}
            onChange={onChange}
          />
        </Box>

        <Box className={classes.inner_box}>
          <CheckList task={task} default_value={task.checklist} />
        </Box>

        <Box className={classes.inner_box}>
          <Description
            read_mode={read_mode}
            default_value={task.description}
            onChange={onChange}
          />
        </Box>

        <Box className={classes.inner_box}>
          <File
            read_mode={read_mode}
            task={task}
            default_files={task.files}
          />
        </Box>

        <Box className={classes.buttons_box}>
          <Button
            variant="contained"
            color="primary"
            disabled={false} onClick={onClickUpdateTask}
          >Update Task</Button>

          <Button
            variant="contained"
            className="delete-btn"
            color="secondary"
            disabled={true} onClick={onClickDeleteTask}
          >Delete Task</Button>

          <Button
            variant="contained"
            className="cancel-btn"
            onClick={onCancelUpdateTask}
          >Cancel</Button>
        </Box>

      </CardContent>
    </Card>
  )

}