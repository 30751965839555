import React, { useEffect, useState } from 'react';
import { useFirmsCtx } from '../context';
import {
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  OutlinedInput,
} from '@mui/material';

const FilterDropdown = (name, label) => {
  const { tags, filters, setFilters } = useFirmsCtx();
  const [selectedTags, setSelectedTags] = useState([]);
  const options = tags || [];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newSelectedTags =
      typeof value === 'string' ? value.split(',') : value;
    setSelectedTags(newSelectedTags);

    setFilters({ ...filters, tags: newSelectedTags });
  };

  useEffect(() => {
    if (filters.tags.length === 0 && selectedTags.length !== 0) {
      setSelectedTags([]);
    }
  }, [filters.tags]);

  return (
    <FormControl variant="outlined" sx={{ width: 160 }}>
      <InputLabel size="small">{label}</InputLabel>
      <Select
        size="small"
        multiple
        value={selectedTags}
        onChange={handleChange}
        name={name}
        label={label}
        input={<OutlinedInput label="Name" />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 300 },
          },
        }}
      >
        {options.map((tag) => (
          <MenuItem key={tag} value={tag}>
            <Checkbox checked={selectedTags.indexOf(tag) > -1} />
            <ListItemText primary={tag} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterDropdown;
