import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Stack } from '@mui/material';
import Filters from '../weekly_firm_reports/Filters';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    height: 40,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const FilterBar = ({
  week,
  tags,
  results,
  setWeek,
  setRange,
  setTagsString,
  generateReport,
  downloadReport,
  setDisplayedResults,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Filters
        week={week}
        tags={tags}
        results={results}
        setWeek={setWeek}
        setRange={setRange}
        setTagsString={setTagsString}
        setDisplayedResults={setDisplayedResults}
      />
      <Stack direction="row" spacing={2} className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={generateReport}
        >
          Generate
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={downloadReport}
        >
          Download
        </Button>
      </Stack>
    </div>
  );
};

export default FilterBar;
