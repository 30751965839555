import React from 'react';
import { makeStyles } from '@mui/styles';
import { useFirmTasks } from '../../context/firm_tasks';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
import Looks5OutlinedIcon from '@mui/icons-material/Looks5Outlined';

const useStyles = makeStyles({
  container: (props) => ({
    width: '100%',
    display: 'flex',
    paddingLeft: props.showFilterIcon ? 10 : 0,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  icon: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
});

const SortableHeader = ({ label, objKey }) => {
  const {
    keyName,
    sortAscending,
    sortTasks,
    setKeyName,
    setSortAscending,
    statusPriorityIndex,
  } = useFirmTasks();
  const showFilterIcon = keyName === objKey;
  const isFilteringStatus = objKey === 'status';
  const classes = useStyles({ showFilterIcon });

  const onHeaderLabelClick = () => {
    setKeyName(objKey);
    setSortAscending(!sortAscending);
    sortTasks(objKey);
  };

  const renderStatusIcon = () => {
    switch (statusPriorityIndex) {
      case 1:
        return <LooksOneOutlinedIcon color="success" fontSize="small" />;
      case 2:
        return <LooksTwoOutlinedIcon color="info" fontSize="small" />;
      case 3:
        return <Looks3OutlinedIcon color="warning" fontSize="small" />;
      case 4:
        return <Looks4OutlinedIcon color="error" fontSize="small" />;
      case 0:
        return <Looks5OutlinedIcon color="primary" fontSize="small" />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.container} onClick={onHeaderLabelClick}>
      <span>{label}</span>
      {showFilterIcon && (
        <div className={classes.icon}>
          {isFilteringStatus ? (
            renderStatusIcon()
          ) : sortAscending ? (
            <ArrowDropUpOutlinedIcon color="success" />
          ) : (
            <ArrowDropDownOutlinedIcon color="info" />
          )}
        </div>
      )}
    </div>
  );
};

export default SortableHeader;
