import React from 'react';
import _ from 'lodash';
import SinglePhotoUpload from '../../upload_photo/single_inline';

export default ({columns, column, path}) => {
  const label = _.find(columns, {'column': column}).header;
  const api_host = _.find(columns, {'column': column}).api_host;

  const onCancel = () => {
    // do nothing
  }

  const afterSave = () => {
    alert(`Successfully uploaded ${label}.`)
  }

  return(
    <div>
      <SinglePhotoUpload
        url={`${api_host}${path}/upload`}
        afterSave={afterSave}
        onCancel={onCancel}
      />
    </div>
  )
}
