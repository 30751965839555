import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import Menu from './_TaskGroupHeaderMenu';
import { usePermission } from '../../../../../context/permission';
import { useFirmTasks } from '../../../../../context/firm_tasks';

import { STYLES } from './styles';
import SortableHeader from '../../../SortableHeader';
const useStyles = makeStyles((theme) => STYLES(theme));

export default ({
  title,
  show_tasks,
  toggleHideShow,
  show_add_task,
  toggleShowAddTask,
  request_task,
  toggleRequestTask,
  filters,
  firm_id,
}) => {
  const classes = useStyles();
  const { isAngel } = usePermission();
  const { preferences } = useFirmTasks();

  const showPageCount = preferences.show_page_count === 'true';

  return (
    <Box className={classes.header_row}>
      <Box className={classes.header_name}>
        <Menu
          show_tasks={show_tasks}
          toggleHideShow={toggleHideShow}
          show_add_task={show_add_task}
          toggleShowAddTask={toggleShowAddTask}
          request_task={request_task}
          toggleRequestTask={toggleRequestTask}
          filters={filters}
          firm_id={firm_id}
        />
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box className={classes.header_organizational_structure}>
        Organization
      </Box>
      <Box className={classes.header_due_date}>
        <SortableHeader label={'Due Date'} objKey={'due_date'} />
      </Box>
      <Box className={classes.header_status}>
        <SortableHeader label={'Status'} objKey={'status'} />
      </Box>
      <Box className={classes.header_assigned_to}>Assigned To</Box>
      <Box className={classes.header_work_hours}>
        <SortableHeader label={'Work Hours'} objKey={'work_hours'} />
      </Box>
      {showPageCount && (
        <>
          <Box className={classes.header_work_hours}>
            <SortableHeader label={'Last Count'} objKey={'last_count'} />
          </Box>
          <Box className={classes.header_work_hours}>
            <SortableHeader
              label={'Processed Count'}
              objKey={'processed_count'}
            />
          </Box>
          <Box className={classes.header_work_hours}>
            <SortableHeader
              label={'Remaining Count'}
              objKey={'remaining_count'}
            />
          </Box>
        </>
      )}
      {isAngel() && (
        <>
          <Box className={classes.header_billable_hours}>
            <SortableHeader
              label={'Billable Hours'}
              objKey={'billable_hours'}
            />
          </Box>
          <Box className={classes.header_management_estimated_hours}>
            <SortableHeader
              label={'Mngt est. Hours'}
              objKey={'admin_est_hours'}
            />
          </Box>
          <Box className={classes.header_client_estimated_hours}>
            <SortableHeader
              label={'Client est. Hours'}
              objKey={'client_est_hours'}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
