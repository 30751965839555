import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, TextField } from '@mui/material';
import { useTaskCategory } from '../../context/task_categories';

const useStyles = makeStyles({
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    width: 300,
  },
  count: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'gray',
    fontSize: 14,
    marginTop: 5,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 30,
    margin: 20,
    borderBottom: '1px solid #00000029',
  },
  title: {
    fontWeight: 500,
    marginLeft: 26,
    fontSize: 20,
  },
  searchWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: 15,
  },
  save: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    height: 40,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const Header = ({
  searchString,
  matchCount,
  setShowAddModal,
  handleSearchOnChange,
}) => {
  const classes = useStyles();
  const { setCurrentNode } = useTaskCategory();
  
  const onSave = () => {
    setCurrentNode(null);
    setShowAddModal(true);
  };

  return (
    <div className={classes.header}>
      <div className={classes.title}>Task Categories</div>
      <div className={classes.searchWrapper}>
        <div className={classes.searchContainer}>
          <TextField
            size="small"
            variant="outlined"
            label="Search"
            name="Search"
            className={classes.search}
            value={searchString}
            onChange={handleSearchOnChange}
          />
          {matchCount > 0 && (
            <div className={classes.count}>Matches Found: {matchCount}</div>
          )}
        </div>
        <Button variant="contained" className={classes.save} onClick={onSave}>
          Add Task Category
        </Button>
      </div>
    </div>
  );
};

export default Header;
