import React, { useEffect, useState } from 'react';
import Header from './_TaskGroupHeader';
import Tasks from './_Tasks';
import TaskSummary from './_TaskSummary';
import AddTask from '../../../AddTask';
import RequestTask from '../../../RequestTask';
import { useFirmTasks } from '../../../../../context/firm_tasks';

export default ({ task_group, filters }) => {
  const { firmTasks, setFirmTasks } = useFirmTasks();
  const [title, setTitle] = useState('');
  const [show_tasks, setShowTasks] = useState(true);
  const [show_add_task, setShowAddTask] = useState(false);
  const [request_task, setRequestTask] = useState(false);

  useEffect(() => {
    setTitle(task_group.title);
    setFirmTasks(task_group.tasks);
  }, [task_group]);

  const onCloseAddNewTask = () => {
    setShowAddTask(false);
  };

  const onCloseRequestTask = () => {
    setRequestTask(false);
  };

  const onAddData = (new_task) => {
    setFirmTasks([...firmTasks, new_task]);
    setShowAddTask(false);
  };

  const toggleHideShow = () => {
    setShowTasks(!show_tasks);
  };

  const toggleShowAddTask = () => {
    setShowAddTask(!show_add_task);
  };

  const toggleRequestTask = () => {
    setRequestTask(!request_task);
  }

  return (
    <div style={{ marginTop: '20px' }}>
      {show_add_task && (
        <AddTask
          onCloseAddNewTask={onCloseAddNewTask}
          onAddData={onAddData}
          group_by="firm"
          group_id={task_group.id}
          filters={filters}
        />
      )}
      {request_task && (
        <RequestTask
          onCloseRequestTask={onCloseRequestTask}
          onAddData={onAddData}
          group_by="firm"
          group_id={task_group.id}
          filters={filters}
        />
      )}
      <Header
        title={title}
        show_tasks={show_tasks}
        toggleHideShow={toggleHideShow}
        show_add_task={show_add_task}
        toggleShowAddTask={toggleShowAddTask}
        request_task={request_task}
        toggleRequestTask={toggleRequestTask}
        filters={filters}
        firm_id={task_group.id}
      />
      {show_tasks && <Tasks />}
      <TaskSummary />
    </div>
  );
};
