import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, CardContent, CardActions } from '@mui/material';

import Category from '../_mytask/show/_Category';
import Organization from '../_mytask/show/_Organization';
import AssignedTo from '../_mytask/show/_AssignedTo';
import Tags from '../_mytask/show/_Tags';
import Files from '../_mytask/show/files/_Files';
import Rules from '../_mytask/show/_Rules';
import Description from '../_mytask/show/_Description';
import TotalPerUser from '../_mytask/show/_TotalPerUser';
import Stuck from '../_default/_Stuck';
import CheckList from '../_mytask//show/_Checklist';
import TaskRowHeader from '../_mytask//_TaskRowHeader';
import MultiTimeTracker from '../_mytask/row/multi_time_tracker/Main';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  box: {
    width: '65%',
    marginBottom: '20px',
  },
  inner_box: {
    display: 'flex',
    marginTop: '10px',
  },
  inner_box_spaced: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'space-between',
  },
  buttons_box: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel-btn': {
      marginLeft: '15px',
    },
    '& .delete-btn': {
      marginLeft: '15px',
    },
  },
  stuck_button_box: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actions: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
}));

export default ({ task, onTaskUpdate, previousFiles }) => {
  const classes = useStyles();
  const [data, setData] = useState({});

  useEffect(() => {
    setData(task);
  }, [task]);

  return (
    <Card className={classes.box}>
      <TaskRowHeader task={task} />
      <CardContent style={{ paddingTop: '0px' }}>
        <Box>
          <Category task={task} />
        </Box>
        <Box className={classes.inner_box_spaced}>
          <Organization task={task} />
        </Box>
        <Box
          className={classes.inner_box_spaced}
          style={{ marginBottom: '20px' }}
        >
          <AssignedTo task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <Tags task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <MultiTimeTracker task={task} onChange={onTaskUpdate} />
        </Box>
        <Box className={classes.inner_box}>
          <TotalPerUser task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <CheckList task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <Description task_description={task.description} />
        </Box>
        <Box className={classes.inner_box}>
          <Rules task={task} />
        </Box>
        <Box className={classes.inner_box}>
          <Files files={task.files} previousFiles={previousFiles} />
        </Box>
      </CardContent>
      <CardActions className={classes.actions}>
        <Stuck task={task} onTaskUpdate={onTaskUpdate} />
      </CardActions>
    </Card>
  );
};
