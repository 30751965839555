import { TAG_OPTIONS } from '../const/ref';

export const filterTags = (tags, firms) => {
  const tagValues = TAG_OPTIONS.map((option) => option.value).filter(
    (value) => value !== 'Others'
  );

  return firms.filter((firm) => {
    if (firm.tags.length === 0) return false;

    const hasExplicitTag = tags.includes('Others')
      ? tags.some((tag) => tag !== 'Others' && firm.tags.includes(tag))
      : firm.tags.some((tag) => tags.includes(tag));

    const hasOtherTag =
      tags.includes('Others') &&
      firm.tags.some((tag) => !tagValues.includes(tag));

    return hasExplicitTag || hasOtherTag;
  });
};
