import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Box, Typography, Paper, Divider, Button } from "@mui/material";

import InputText from "../../../board/components/_basic_filters/InputText";
import InputSelect from "../../../board/components/_basic_filters/InputSelect";
import InputAutocomplete from "../../../board/components/_basic_filters/InputAutocomplete";
import { STATUS_OPTIONS } from "../const/ref";

export default ({ classes, default_filter, onChange, firms }) => {
  const [data, setData] = useState({ ...default_filter });

  const onChangeLocal = (new_data) => {
    setData({ ...data, ...new_data });
  };

  const applyFilter = () => {
    onChange(data);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      applyFilter();
    }
  };

  return (
    <Box onKeyPress={handleKeyPress}>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant={"h6"}>Filters</Typography>
        <Divider className={classes.margin_bottom} />
        <Box display="flex" justifyContent="space-between">
          <InputText
            width={"20%"}
            name="match_name"
            title="Task Name"
            onChange={onChangeLocal}
          />
          <InputText
            width={"20%"}
            name="match_firstname"
            title="First Name"
            onChange={onChangeLocal}
          />
          <InputText
            width={"20%"}
            name="match_lastname"
            title="Last Name"
            onChange={onChangeLocal}
          />
          <InputText
            width={"20%"}
            name="match_email"
            title="Email"
            onChange={onChangeLocal}
          />
        </Box>
        <Box
          width={"50%"}
          marginTop="15px"
          display="flex"
          justifyContent="space-between"
        >
          <InputSelect
            width={"50%"}
            name="exact_status"
            title="Status"
            options={STATUS_OPTIONS}
            onChange={onChangeLocal}
            default_value="pending"
          />
          <InputAutocomplete
            width={"50%"}
            name="match_any_firm"
            title="Firm"
            options={firms}
            onChange={onChangeLocal}
            default_value=""
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" marginTop="15px">
          <Button variant="contained" color="success" onClick={applyFilter}>
            Apply Filter
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
