import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Modal, TextField } from '@mui/material';
import ToggleBar from '../ToggleBar';
import FilterButtons from '../../../FilterButtons';
import { doAuthenticatedPut } from '../../../../../../../../actions/_methods';
import { ACCOUNTING_TASK_REFERENCE } from '../../../../../../../../const/api_paths';
import { useGlobal } from '../../../../../../../context/global';

const useStyles = makeStyles({
  box: {
    padding: 20,
    top: '50%',
    left: '50%',
    width: '30vw',
    borderRadius: '5px',
    position: 'absolute',
    backgroundColor: '#f8f8ff',
    transform: 'translate(-50%, -50%)',
  },
  container: {
    gap: 15,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 20,
  },
  text: {
    width: '100%',
  },
});

const EditReference = ({
  open,
  reference,
  handleClose,
  setReference,
  fetchReferences,
}) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [name, setName] = useState('');
  const [active, setActive] = useState('true');

  const disableSave = name.trim() === '';

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const referenceStatus = () => {
    return active === 'true' ? 'active' : 'inactive';
  };

  const endpoint = () => {
    return ACCOUNTING_TASK_REFERENCE.replace(':id', reference.id);
  };

  const onSave = () => {
    doAuthenticatedPut(
      endpoint(),
      { name: name, status: referenceStatus() },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => alert('Please try again'),
      }
    );
  };

  const onSuccess = (res) => {
    const message = 'Successfully edited task reference';

    prompt(message);
    setReference(res);
    fetchReferences();
    onClose();
  };

  const onClose = () => {
    initializeValues();
    handleClose();
  };

  const initializeValues = () => {
    setName(reference?.name || '');
    setActive(reference?.status === 'active' ? 'true' : 'false');
  };

  useEffect(() => {
    initializeValues();
  }, [reference]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <div className={classes.header}>Edit Reference</div>
        <div className={classes.container}>
          <TextField
            required
            size="small"
            name="Name"
            label="Name"
            variant="outlined"
            value={name}
            className={classes.text}
            onChange={onNameChange}
          />

          <ToggleBar on={active} label={'Active'} handleChange={setActive} />
        </div>
        <FilterButtons
          labelSave="Save"
          labelCancel="Close"
          onClickSave={onSave}
          onClickCancel={onClose}
          disableSave={disableSave}
        />
      </Box>
    </Modal>
  );
};

export default EditReference;
