import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';
import DataTable from '../../../shared/components/data_table';
import { MODEL_DEF } from './model_def';

import { API_REFERENCES } from '../../../const/api_paths';
import { doAuthenticatedGet } from '../../../actions/_methods';
import { groupByLevel } from '../../../utils/orgs';

const STYLES = {
  header: {
    marginBottom: "25px",
  }
};

export default () => {
  const [organization, setOrganization] = useState([]);

  useEffect(() => {
    getOrganization();
  }, []);

  const getOrganization = () => {
    doAuthenticatedGet(API_REFERENCES, {}, {
      success: onSuccessGetOrganization,
      general: onSuccessGetOrganization,
      error: (res) => alert('Please try again...'),
    });
  }

  const onSuccessGetOrganization = (res) => {
    const options = res.organization;
    setOrganization(options)
    const {level_1, level_2, level_3} = groupByLevel(options);

    _.find(MODEL_DEF.columns, {column: "firm_id"}).options      = level_1;
    _.find(MODEL_DEF.columns, {column: "account_id"}).options   = level_2;
    _.find(MODEL_DEF.columns, {column: "location_id"}).options  = level_3;

    _.find(MODEL_DEF.columns, {column: "category_id"}).options  = res.categories;
  }

  return(
    <div>
      <Typography variant="h5" style={ STYLES.header }>
        Recurring Tasks
      </Typography>
      {(organization.length > 0) && <DataTable {...MODEL_DEF} />}
      {(organization.length === 0) && <div>loading...</div>}
    </div>
  );
};
