import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { doAuthenticatedGet } from '../../../actions/_methods';
import Row from './_row';
import Create from './_create';
import { STYLES } from './styles';
import { Spinner } from "../utils/_spinner";

export default (props) => {
  const {
    api_path,
    model_name,
    peer_name,
    columns,
    listable_columns,
    creatable_columns,
    updatable_columns,
    filters,
    showCreateButton = true,
  } = props;

  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  // const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = (page = 1) => {
    setLoading(true);
    doAuthenticatedGet(api_path, { ...filters, ...{ page: page }}, {
      general: onGeneral,
      success: onSuccess,
      error: onError,
    });
  }

  const onPaginate = (event, value) => {
    setPage(value);
    loadData(value);
  }

  const onGeneral = (res) => {
    // do nothing for now
    setLoading(false);
  }

  const onSuccess = (res) => {
    setMeta(res['meta']);
    setTotalPages(res['total_pages']);
    setData(res[peer_name]);
    setLoading(false);
  }

  const onError = (res) => {
    alert('Some error');
    setLoading(false);
  }

  const afterCreate = (new_data) => {
    loadData();
  }

  const TableHeaders = () => (
    <thead>
      <tr>
        {listable_columns.map(col => {
          return(
            <th style={STYLES.th} key={`datatable_th_${Math.random()}`}>
              {col['header']}
            </th>
          )
        })}
        <th style={STYLES.th_end}>Action</th>
      </tr>
    </thead>
  )

  return (
    <div>
      {showCreateButton && <Create
        api_path={api_path}
        columns={columns}
        creatable_columns={creatable_columns}
        afterCreate={afterCreate}
      />}
      {loading ? (
        <Spinner height={300} />
      ) : (
        <>
          <table style={STYLES.table} cellSpacing="0">
            <TableHeaders />
            <tbody>
              {data.map((row) => {
                return (
                  <Row
                    key={`datatable_row_${Math.random()}`}
                    api_path={api_path}
                    model_name={model_name}
                    data={row}
                    columns={columns}
                    listable_columns={listable_columns}
                    updatable_columns={updatable_columns}
                  />
                );
              })}
            </tbody>
          </table>
          <Stack mt={2}>
            <Pagination
              count={total_pages}
              page={page}
              onChange={onPaginate}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </>
      )}
    </div>
  )
}