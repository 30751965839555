import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField
} from '@mui/material';

import { doAuthenticatedPost } from '../../../../../../../actions/_methods';
import { API_TASK_NIGHTLY_REPORT } from '../../../../../../../const/api_paths';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    diplay: 'flex',
    flexGrow: 1,
    marginTop: '10px'
  },
  element_row: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: '10px'
  },
  textfield: {
    width: '100%',
  },
  card_wrapper: {
    padding: '10px',
  },
  dialog_content: {
    width: '550px',
    marginTop: '20px',
    border: '1px solid #ccc',
    padding: '10px',
  }
}));


export default ({comments, task}) => {
  const classes = useStyles();
  const [show_more, setShowMore] = useState(false);
  const [reports, setReports] = useState([]);
  const [nested_reports, setNestedReports] = useState([]);
  const [email_recipients, setEmailRecipients] = useState('');
  const [email_senders, setEmailSenders] = useState('');
  const [subject, setSubject] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    let filtered_comments = _.filter(comments, comment => {return(comment.body.substring(0, 4) == "#NR ")});
    let uniq_comments = _.uniqBy(filtered_comments, comment => {
      return(comment.body.substring(4).split(":::")[0])
    });

    let formatted_comments = uniq_comments.map(comment => {
      let comments2 = _.filter(filtered_comments, comment2 => comment2.body.substring(4).split(":::")[0] == comment.body.substring(4).split(":::")[0])
      return {
        title:    comment.body.substring(4).split(":::")[0],
        comments: comments2.map(comment2 => {
          return {
            title:   comment2.body.substring(4).split(":::")[0],
            label_1: comment2.body.substring(4).split(":::")[1],
            label_2: comment2.body.substring(4).split(":::")[2],
            message: comment2.body.substring(4).split(":::")[3]
          }
        })
      }
    });

    setNestedReports(formatted_comments);
    setReports(filtered_comments);
    setSubject(`Nightly Report for ${task.safe_location_name}`);
    setEmailRecipients(task.email_recipients);
    setEmailSenders(task.email_senders);
  }, [comments]);

  const apiEndpoint = () => {
    return API_TASK_NIGHTLY_REPORT.replace(':task_id', task.id);
  }

  const onClickSendReport = (e) => {
    const url = apiEndpoint();
    const data = {
      task_id: task.id,
      email_recipients: email_recipients,
      email_senders: email_senders,
      subject: subject,
      body: document.getElementById(`report_body_${task.id}`).innerHTML
    }

    setSubmitting(true);
    doAuthenticatedPost(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const handleClose = (e) => {
    setShowMore(false);
  }

  const onSuccess = (res) => {
    setSubmitting(false);
    alert('Report sent successfully');
    setShowMore(false);
  }

  const onError = (res) => {
    setSubmitting(false);
    alert('Error sending report');
  }

  return(
    <div className={classes.wrapper}>
      {show_more && <Dialog onClose={handleClose} open={show_more} className={classes.dialog}>
        <DialogTitle>Nightly Report</DialogTitle>
        <DialogContent>
          <div style={{marginTop: 10}}>
            <TextField
              label="Subject"
              variant="outlined"
              value={subject}
              onChange={(e)=>{setSubject(e.target.value)}}
              style={{ width: '100%' }}
            />
          </div>
          <div style={{marginTop: 10}}>
            <TextField
              label="Email Recipients (separated by commas)"
              variant="outlined"
              value={email_recipients}
              onChange={(e)=>{setEmailRecipients(e.target.value)}}
              style={{ width: '100%' }}
            />
          </div>
          <div style={{marginTop: 10}}>
            <TextField
              label="Email Sender/From (e.g. Overeasy <admin@overeasyoffice.com>)"
              variant="outlined"
              value={email_senders}
              onChange={(e)=>{setEmailSenders(e.target.value)}}
              style={{ width: '100%' }}
            />
          </div>
          <div className={classes.dialog_content} id={`report_body_${task.id}`}>
            {_.map(nested_reports, (report, index) => {
              return(
                <>
                  <strong>{report.title}</strong>
                  <br/>
                  <ul>
                    {_.map(report.comments, (comment, index) => {
                      return(
                        <li>
                          <b>{comment.label_1}</b>: <b>{comment.label_2}</b>: <span>{comment.message}</span>
                        </li>
                      )
                    })}
                  </ul>
                </>
              )
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClickSendReport}>
            Send Report
          </Button>
        </DialogActions>
      </Dialog>}
      {!show_more && <Button
        variant="contained"
        size="small"
        onClick={()=>setShowMore(true)}
      >Preview Nightly Report</Button>}
      {show_more && <Button
        variant="contained"
        size="small"
        onClick={()=>setShowMore(false)}
      >Close Preview Nightly Report</Button>}
    </div>
  )
}