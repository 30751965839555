import React from 'react';
import { Button } from '@mui/material';

export default ({ showUncompleted, current_mode, onChangeMode }) => {
  const onTodayMode = () => {
    onChangeMode('today');
  };

  const onWeekMode = () => {
    onChangeMode('week');
  };

  const onUncompletedMode = () => {
    onChangeMode('uncompleted');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <Button
        onClick={onTodayMode}
        color={current_mode == 'today' ? 'success' : 'primary'}
      >
        Today's Tasks
      </Button>
      <Button
        onClick={onWeekMode}
        color={current_mode == 'week' ? 'success' : 'primary'}
      >
        Week's Tasks
      </Button>
      {showUncompleted && (
        <Button
          onClick={onUncompletedMode}
          color={current_mode == 'uncompleted' ? 'success' : 'warning'}
        >
          Uncompleted Tasks
        </Button>
      )}
    </div>
  );
};
