import React from 'react';
import _ from 'lodash';
import EditString from "./edit_string";
import EditStatus from "./edit_status";
import EditAutocomplete from "./edit_autocomplete";
import EditOrganizational from "./edit_organizational";
import EditUserRoles from "./edit_user_roles";
import EditProfilePic from "./edit_profile_pic";
import EditSelect from "./edit_select";
import EditMultiSelect from "./edit_multiselect";
import EditTimeZone from "./edit_timezone";

export default ({columns, column, data, path, onChange}) => {
  const column_type = _.find(columns, {'column': column}).type;

  if(column_type === 'status') {
    return(<EditStatus {...{columns, column, data, path, onChange}} />)
  } else if(column_type === 'autocomplete') {
    return(<EditAutocomplete {...{columns, column, data, path, onChange}} />)
  } else if(column_type === 'organizational') {
    return(<EditOrganizational {...{columns, column, data, path, onChange}} />)
  } else if(column_type === 'user_roles') {
    return(<EditUserRoles {...{columns, column, data, path, onChange}} />)
  } else if(column_type === 'profile_pic') {
    return(
      <>
        <EditProfilePic {...{ columns, column, data, path, onChange }} />
        <EditTimeZone {...{ data, onChange }} />
      </>
    )
  } else if(column_type === 'select') {
    return(<EditSelect {...{columns, column, data, path, onChange}} />)
  } else if(column_type === 'multiselect') {
    return(<EditMultiSelect {...{columns, column, data, path, onChange}} />)
  } else {
    return(<EditString {...{columns, column, data, path, onChange}} />)
  }
}
