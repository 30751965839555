import { API_MANAGE_CATEGORIES } from '../../../const/api_paths';

export const MODEL_DEF = {
  api_path: API_MANAGE_CATEGORIES,
  model_name: 'category',
  peer_name: 'categories',
  columns: [
    {column: 'id',      header: 'ID',     type: 'integer'},
    {column: 'name',    header: 'Name',   type: 'string'},
    {column: 'status',  header: 'Status', type: 'status', default: 'active'},
  ],
  listable_columns: [
    {column: 'id',      header: 'ID'},
    {column: 'name',    header: 'Category Name'},
    {column: 'status',  header: 'Status'},
  ],
  creatable_columns: [
    ['name'],
  ],
  updatable_columns: [
    ['name'],
    ['status']
  ]
};