import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const SettingsButton = ({ setShowSettings }) => {
  const classes = useStyles();

  return (
    <IconButton
      color="default"
      className={classes.button}
      onClick={() => setShowSettings(true)}
      aria-label="settings"
    >
      <SettingsIcon fontSize="small" />
    </IconButton>
  );
};

export default SettingsButton;
