import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {
  TextField,
  Avatar,
  Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  avatar_wrapper: {
    '& > div': {
      width: '28px',
      height: '28px',
      marginRight: '10px',
    }
  },
  input: {
    minWidth: '250px',
    marginRight: '10px',
  },
  option_style: {
    display: 'flex',
    fontSize: '11pt',
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #555',
    marginBottom: '0',
  }
}));

export default ({columns, column, data, onChange}) => {
  const classes     = useStyles();
  const column_def  = _.find(columns, {'column': column});
  const label       = column_def.header;
  const options     = column_def.options;

  const [value, setValue] = useState('');

  useEffect(() => {
    const default_value = _.find(options, {'value': data[column]});
    setValue(default_value)
  }, [data]);

  const onChangeLocal = (e, target_value) => {
    let new_data = {};
    new_data[column] = target_value ? target_value.value : '';
    setValue(target_value ? target_value.value : '');
    onChange(new_data);
  };

  const OptionAvatar = (option) => (
    <span className={classes.avatar_wrapper}>
      <Avatar src={option.avatar}></Avatar>
    </span>
  );

  const OptionComponent = ({option, props}) => (
    <div className={classes.option_style} {...props}>
      {option.avatar && <OptionAvatar option={option} />}
      {option.label}
    </div>
  );

  const InputComponent = ({params, label}) => (
    <TextField
      {...params}
      label={label}
      variant="outlined"
    />
  );

  return(
    <Autocomplete
      size="small"
      value={value}
      options={options}
      className={classes.input}
      onChange={onChangeLocal}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => option ? option.label : ''}
      renderOption={(props, option) => <OptionComponent {...{option, props}} />}
      renderInput={(params) => <InputComponent {...{params, label}} />}
    />
  )
}
