import React from 'react';
import TaskWrapper from './_TaskWrapper';
import { TaskCtxProvider } from '../../../../../context/task';

export default ({ tasks }) => {
  return (
    <>
      {tasks.map((task) => (
        <TaskCtxProvider>
          <TaskWrapper key={`task-wrapper-${task.id}`} task={task} />
        </TaskCtxProvider>
      ))}
    </>
  );
};
