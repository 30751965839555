import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Header from './Header';
import UserList from './user-list/UserList';
import AssignUser from './AssignUser';
import Footer from './Footer';
import { useFirmsCtx } from '../../context';
import Loader from '../Loader';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    backgroundColor: '#f8f8ff',
    padding: 16,
    borderRadius: '5px',
  },
});

const ManageUsers = ({ firm, open, handleClose }) => {
  const classes = useStyles();
  const { isUsersLoading } = useFirmsCtx();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <Header firm={firm} />
        {isUsersLoading ? (
          <Loader height={300} />
        ) : (
          <>
            <UserList firm={firm} />
            <AssignUser firm={firm} />
          </>
        )}
        <Footer handleClose={handleClose} />
      </Box>
    </Modal>
  );
};

export default ManageUsers;
