export const statusColor = (status) => {
  switch (status) {
    case 'unstarted':
      return {
        // backgroundColor: 'rgb(66, 66, 66)',
      }
    case 'on_going':
      return {
        backgroundColor: 'rgb(144, 202, 249)',
        color: 'rgba(0, 0, 0, 0.87)',
      }
    case 'paused':
      return {
        backgroundColor: 'rgb(251 207 107)',
        color: 'rgba(0, 0, 0, 0.87)',
      }
    case 'stucked':
      return {
        backgroundColor: 'rgb(244, 67, 54)',
      }
    case 'completed':
      return {
        backgroundColor: 'rgb(76, 175, 80)',
      }
    default: 
      return {
        backgroundColor: 'rgb(66, 66, 66)',
      }
  }
}

export const statusLabel = (status) => {
  switch (status) {
    case 'unstarted':
      return 'Unstarted'
    case 'on_going':
      return 'On-Going'
    case 'paused':
      return 'Paused'
    case 'stucked':
      return 'Stucked'
    case 'completed':
      return 'Completed'
    default:
      return status
  }
}