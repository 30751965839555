import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { calculateRange, getCurrentMonth, getCurrentWeek } from '../utils';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Chip,
  Box,
} from '@mui/material';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    gap: 30,
  },
  note: {
    color: '#0000005c',
    fontSize: 12,
    marginTop: 5,
  },
});

const Filters = ({
  type,
  date,
  label,
  firms,
  results,
  setDate,
  setRange,
  setSelectedFirms,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState([]);
  const currentDate = type === 'week' ? getCurrentWeek() : getCurrentMonth();

  const onDateChange = (event) => {
    const value = event.target.value;
    setDate(value);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const selectedFirmsString = value.join(',');

    setSelectedFirms(selectedFirmsString);
    setValue(value);
  };

  const renderValue = (selected) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((id) => (
        <Chip key={id} label={_.find(firms, { id }).name} size="small" />
      ))}
    </Box>
  );

  useEffect(() => {
    setDate(currentDate);
    setRange(calculateRange(currentDate, type));
  }, []);

  useEffect(() => {
    if (date) setRange(calculateRange(date, type));
  }, [results]);

  useEffect(() => {
    if (type === 'month' && !date) {
      const today = new Date();
      const formattedDate = today.toISOString().substring(0, 7);
      setDate(formattedDate);
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <TextField
        label="Date Range"
        type={type}
        className={classes.dateRange}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        onChange={onDateChange}
      />
      <div>
        <FormControl variant="outlined" sx={{ minWidth: 250 }}>
          <InputLabel size="small">{label}</InputLabel>
          <Select
            size="small"
            value={value}
            onChange={handleChange}
            label={label}
            renderValue={renderValue}
            multiple
            MenuProps={{
              PaperProps: {
                style: { maxHeight: 300, marginTop: 10 },
              },
            }}
          >
            {firms.map((firm) => (
              <MenuItem key={firm.id} value={firm.id}>
                <Checkbox checked={value.indexOf(firm.id) > -1} size="small" />
                <ListItemText primary={firm.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Filters;
