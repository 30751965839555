import React from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import ToggleButton from './Toggle';

const useStyles = makeStyles({
  box: {
    margin: 10,
    minWidth: 400,
    border: '1px solid #00000029',
    borderRadius: 5,
  },
  wrapper: {
    gap: 15,
    padding: 30,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    width: 300,
  },
});

const CustomTextField = ({
  name,
  label,
  value,
  onChange,
  placeholder,
  numeric = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <span>{label}</span>
      <TextField
        type={numeric ? 'number' : 'text'}
        size="small"
        name={name}
        variant="outlined"
        value={value}
        onChange={onChange}
        className={classes.text}
        placeholder={placeholder}
        InputProps={numeric ? { inputProps: { min: 1 } } : {}}
      />
    </div>
  );
};

const Options = ({ options, setErrors, sheetType, setOptions }) => {
  const classes = useStyles();
  const isNew = sheetType === 'new';

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'starting_point') {
      if (value.trim() !== '') {
        const isValid = /^[A-Z][0-9]+$/.test(value);
        setErrors((prev) => ({ ...prev, starting_point: !isValid }));
      } else {
        setErrors((prev) => ({ ...prev, starting_point: false }));
      }
    }

    setOptions((prevOptions) => ({ ...prevOptions, [name]: value }));
  };

  return (
    <div className={classes.box}>
      <div className={classes.wrapper}>
        {isNew ? (
          <CustomTextField
            name="name"
            label="Document Name"
            value={options.name}
            onChange={handleChange}
            placeholder="New Sheet"
          />
        ) : (
          <>
            <CustomTextField
              name="sheet_id"
              label="Sheet ID"
              value={options.sheet_id}
              onChange={handleChange}
              placeholder="15dn3Tx1IUEmt7-m7Gvxqd6..."
            />
            <CustomTextField
              numeric={true}
              name="sheet_number"
              label="Sheet Number"
              value={options.sheet_number}
              onChange={handleChange}
              placeholder="1"
            />
            <CustomTextField
              name="starting_point"
              label="Starting Point"
              value={options.starting_point}
              onChange={handleChange}
              placeholder="A1"
            />
            <div className={classes.container}>
              <span>Include Headers</span>
              <ToggleButton setOptions={setOptions} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Options;
