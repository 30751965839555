import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import {
  Box,
  TextField,
  Avatar,
  Autocomplete,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
  },
  input: {
    minWidth: '250px',
    marginRight: '10px',
  },
  avatar_wrapper: {
    '& > div': {
      width: '28px',
      height: '28px',
      marginRight: '10px',
    }
  }
}));

const option_style = {
  display: 'flex',
  fontSize: '11pt',
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '1px solid #555',
  marginBottom: '0',
};

const groupByLevel = (options) => {
  var level_1 = []
  var level_2 = []

  options.forEach(lvl_1 => {
    level_1.push({
      value: lvl_1.id,
      label: lvl_1.name,
      code: lvl_1.code,
      avatar: lvl_1.avatar
    });

    lvl_1.children.forEach(lvl_2 => {
      level_2.push({
        value: lvl_2.id,
        label: lvl_2.name,
        code: lvl_2.code,
        avatar: lvl_2.avatar,
        group: lvl_1.name,
        lvl_1_value: lvl_1.id,
        lvl_1_label: lvl_1.name,
        lvl_1_code: lvl_1.code,
        lvl_1_avatar: lvl_1.avatar,
      });
    })
  });

  return { level_1, level_2 }
}

const label_1 = 'Firm';
const label_2 = 'Account';

export default ({options, onChange, group_by, group_id}) => {
  const classes = useStyles();
  const {level_1, level_2} = groupByLevel(options);
  const [value_1, setValue1] = useState(null);
  const [value_2, setValue2] = useState(null);

  useEffect(() => {
    if(group_by === 'account') {
      var target = _.find(level_2, {'value': group_id});
      if(target) {
        onChangeLevel2(null, target);
      }
    } else if(group_by === 'firm') {
      var target = _.find(level_1, {'value': group_id});
      if(target) {
        onChangeLevel1(null, target);
      }
    }
  }, []);

  

  const onChangeLevel1 = (e, target_value) => {
    var val_1 = target_value;
    var val_2 = null;
    onChangeLocal({val_1, val_2})
  }

  const onChangeLevel2 = (e, target_value) => {
    var val_1 = {...value_1};
    var val_2 = target_value;

    if(target_value !== null) {
      val_1 = {
        value:  target_value.lvl_1_value,
        label:  target_value.lvl_1_label,
        code:   target_value.lvl_1_code,
        avatar: target_value.lvl_1_avatar,
      };
    }

    onChangeLocal({val_1, val_2})
  }

  const onChangeLocal = ({val_1, val_2}) => {
    var firm_id = null;
    var account_id = null;

    setValue1(val_1);
    setValue2(val_2);

    if(val_1) { firm_id = val_1.value }
    if(val_2) { account_id = val_2.value }

    onChange({ firm_id, account_id });
  }

  const OptionComponent = ({option, props}) => {
    const avatar = option.avatar ? <Avatar src={option.avatar}>{option.code}</Avatar>: <Avatar>{option.code}</Avatar>;
    return <div style={option_style} {...props}>
      <span className={classes.avatar_wrapper}>{avatar}</span>
      {option.label}
    </div>
  };

  return(
    <Box className={classes.box}>
      <Autocomplete
        size="small"
        value={value_1}
        options={level_1}
        className={classes.input}
        onChange={onChangeLevel1}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => <OptionComponent {...{option, props}} />}
        renderInput={(params) => <TextField {...params} label={label_1} variant="outlined" />}
      />
      <Autocomplete
        size="small"
        value={value_2}
        options={level_2}
        className={classes.input}
        onChange={onChangeLevel2}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => <OptionComponent {...{option, props}} />}
        renderInput={(params) => <TextField {...params} label={label_2} variant="outlined" />}
      />
    </Box>
  )
}