import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Header from '../Header';
import Options from './Options';
import Footer from './Footer';
import { API_MANAGE_LOCATIONS } from '../../../../../../const/api_paths';
import { doAuthenticatedPut } from '../../../../../../actions/_methods';
import { useFirmsCtx } from '../../../context';
import { useGlobal } from '../../../../../context/global';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    backgroundColor: '#f8f8ff',
    padding: 16,
    borderRadius: '5px',
  },
});

const LocationSettings = ({ location, open, handleClose }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { reloadData } = useFirmsCtx();
  const [updatedLocation, setUpdatedLocation] = useState(location);
  const autoCount = updatedLocation.auto_count;
  const slug = updatedLocation.slug;
  const password = updatedLocation.password;

  const missingInput = (value) => value === null || value === '';

  const disableSave =
    autoCount === 'true' && (missingInput(slug) || missingInput(password));

  const updatedSettings = () => {
    const endpoint = `${API_MANAGE_LOCATIONS}/${updatedLocation.id}`;

    const payload = {
      auto_count: updatedLocation.auto_count,
    };

    if (updatedLocation.auto_count) {
      payload.slug = updatedLocation.slug;
      payload.password = updatedLocation.password;
    }

    doAuthenticatedPut(endpoint, payload, {
      general: onSuccess,
      success: onSuccess,
      error: onError,
    });
  };

  const onSuccess = (res) => {
    const message = 'Successfully saved!';

    handleClose();
    reloadData('secondary');
    prompt(message);
  };

  const onError = () => {
    const message = 'Something went wrong. Please try again.';

    prompt(message, { variant: 'warning' });
  };

  const handleCancel = () => {
    setUpdatedLocation(location);
    handleClose();
  };

  useEffect(() => {
    setUpdatedLocation(location);
  }, [location]);

  return (
    <Modal open={open} onClose={handleCancel}>
      <Box className={classes.box}>
        <Header firm={location} />
        <Options
          updatedLocation={updatedLocation}
          setUpdatedLocation={setUpdatedLocation}
        />
        <Footer
          disabled={disableSave}
          onSave={updatedSettings}
          onCancel={handleCancel}
        />
      </Box>
    </Modal>
  );
};

export default LocationSettings;
