import React from 'react';
import { makeStyles } from '@mui/styles';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

const useStyles = makeStyles({
  title: {
    color: '#23282C',
    fontWeight: 500,
    textAlign: 'center',
  },
});

const headers = [
  { id: 'account_name', label: 'Account' },
  { id: 'parent', label: 'Parent' },
  { id: 'child', label: 'Child' },
  { id: 'description', label: 'Description' },
  { id: 'location_count', label: 'Location Count' },
  { id: 'assignee', label: 'Assignee' },
  { id: 'te', label: 'TE' },
  { id: 'at', label: 'AT' },
  { id: 'diff', label: 'Diff' },
  { id: '%', label: '%' },
];

const columnWidths = [
  '15%', // Account
  '15%', // Parent
  '15%', // Child
  '20%', // Description
  '5%', // Location Count
  '10%', // Assignee
  '2.5%', // TE
  '2.5%', // AT
  '2.5%', // Diff
  '2.5%', // %
];

const ReportHeader = ({
  sortKey,
  setSortKey,
  sortAscending,
  setSortAscending,
}) => {
  const classes = useStyles();

  const handleSort = (newSortKey) => {
    setSortKey(newSortKey);
    setSortAscending(!sortAscending);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => {
          return (
            <TableCell
              key={header.id}
              className={classes.title}
              style={{
                width: columnWidths[headers.indexOf(header)],
                cursor: 'pointer',
              }}
              onClick={() => handleSort(header.id)}
              role="button"
              tabIndex={0}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {header.label}
                {sortKey === header.id &&
                  (sortAscending ? (
                    <ArrowDropUpOutlinedIcon color="info" />
                  ) : (
                    <ArrowDropDownOutlinedIcon color="error" />
                  ))}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default ReportHeader;
