import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Box, Paper } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useFirmTasks } from '../../../../context/firm_tasks';

const useStyles = makeStyles({
  box: {
    flexBasis: '5%',
    marginRight: '2px',
  },
  paper: {
    padding: '10px 5px',
    height: '44px',
    textAlign: 'center',
    backgroundColor: blueGrey[200],
  },
});

const pageTypes = {
  last: 'last_count',
  processed: 'processed_count',
  remaining: 'remaining_count',
};

const totalPages = (tasks, pageType) => {
  const key = pageTypes[pageType];

  return _.sum(tasks.map((t) => t[key]));
};

const PageCount = ({ pageType }) => {
  const { firmTasks } = useFirmTasks();
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        {totalPages(firmTasks, pageType)}
      </Paper>
    </Box>
  );
};

export default PageCount;
