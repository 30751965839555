import React, { useEffect, useState } from 'react';
import 'react-sortable-tree/style.css';
import { makeStyles } from '@mui/styles';
import { useTaskCategory } from '../../context/task_categories';
import { SpinnerWithBackdrop } from '../../../shared/components/utils/_spinner';
import AddTaskCategory from './AddTaskCategory';
import EditTaskCategory from './EditTaskCategory';
import DeleteTaskCategory from './DeleteTaskCategory';
import TaskCategoryInfo from './TaskCategoryInfo';
import Header from './Header';
import Content from './Content';
import ExpandButtons from './ExpandButtons';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    paddingTop: 16,
    backgroundColor: '#fdfdfd',
  },
});

const TaskCategories = () => {
  const classes = useStyles();
  const {
    treeData,
    loading,
    setTreeData,
    fetchTaskCategories,
    expandedKeys,
    setExpandedKeys,
  } = useTaskCategory();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [matchCount, setMatchCount] = useState(null);
  const [noMatches, setNoMatches] = useState(false);

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const handleVisibilityToggle = ({ node, expanded }) => {
    toggleNodeExpansion(node.id, expanded);
  };

  const handleSearchOnChange = (e) => {
    setSearchString(e.target.value);
  };

  const applyExpandedState = (node) => {
    return {
      ...node,
      expanded: expandedKeys.has(node.id),
      children: node.children ? node.children.map(applyExpandedState) : [],
    };
  };

  const toggleNodeExpansion = (nodeId, expanded) => {
    setExpandedKeys((prevExpandedKeys) => {
      const newExpandedKeys = new Set(prevExpandedKeys);
      if (expanded) {
        newExpandedKeys.add(nodeId);
      } else {
        newExpandedKeys.delete(nodeId);
      }
      return newExpandedKeys;
    });
  };

  const searchMethod = ({ node, searchQuery }) => {
    if (!searchQuery) return;

    const nodeTitleLowercase = node.title.toLowerCase();
    const searchQueryLowercase = searchQuery.toLowerCase();

    return nodeTitleLowercase.includes(searchQueryLowercase);
  };

  const searchFinishCallback = (matches) => {
    setMatchCount(matches.length);
  };

  useEffect(() => {
    if (treeData.some((node) => node.children)) {
      const updatedTreeData = treeData.map(applyExpandedState);
      setTreeData(updatedTreeData);
    }
  }, [expandedKeys, loading]);

  useEffect(() => {
    const noResults = searchString !== '' && matchCount === 0;
    setNoMatches(noResults);
  }, [searchString, matchCount]);

  useEffect(() => {
    fetchTaskCategories();
  }, []);

  if (loading) {
    return <SpinnerWithBackdrop />;
  }

  return (
    <div className={classes.wrapper}>
      <Header
        searchString={searchString}
        matchCount={matchCount}
        setShowAddModal={setShowAddModal}
        handleSearchOnChange={handleSearchOnChange}
      />
      <ExpandButtons />
      <Content
        noMatches={noMatches}
        getNodeKey={getNodeKey}
        handleVisibilityToggle={handleVisibilityToggle}
        setShowAddModal={setShowAddModal}
        setShowEditModal={setShowEditModal}
        setShowDeleteModal={setShowDeleteModal}
        setShowInfoModal={setShowInfoModal}
        searchMethod={searchMethod}
        searchString={searchString}
        searchFinishCallback={searchFinishCallback}
      />
      <AddTaskCategory
        open={showAddModal}
        applyExpandedState={applyExpandedState}
        handleClose={() => setShowAddModal(false)}
      />
      <EditTaskCategory
        open={showEditModal}
        applyExpandedState={applyExpandedState}
        handleClose={() => setShowEditModal(false)}
      />
      <DeleteTaskCategory
        open={showDeleteModal}
        applyExpandedState={applyExpandedState}
        handleClose={() => setShowDeleteModal(false)}
      />
      <TaskCategoryInfo
        open={showInfoModal}
        handleClose={() => setShowInfoModal(false)}
      />
    </div>
  );
};

export default TaskCategories;
