import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Paper } from '@mui/material';
import { statusColor, statusLabel } from '../../../../../../utils/task_status';

const useStyles = makeStyles((theme) => ({
  box: {
    flexBasis: '11%',
    marginRight: '2px',
  },
  paper: {
    padding: '10px 5px',
    minHeight: '44px',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default ({ task, field }) => {
  const classes = useStyles();
  const [local_task, setLocalTask] = useState({ ...task });

  useEffect(() => {
    setLocalTask(task);
  }, [task]);

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        <Button
          size="small"
          id="demo-customized-button"
          aria-controls="demo-customized-menu"
          aria-haspopup="true"
          variant="contained"
          disableElevation
          style={statusColor(local_task[field])}
        >
          {statusLabel(local_task[field])}
        </Button>
      </Paper>
    </Box>
  );
};
