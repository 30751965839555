import React, { useEffect, useState } from "react";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import { Box, TextField, Avatar, Autocomplete } from "@mui/material";
import { groupByLevel } from "../../../../utils/orgs";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
  },
  input: {
    minWidth: "250px",
    marginRight: "10px",
  },
  avatar_wrapper: {
    "& > div": {
      width: "28px",
      height: "28px",
      marginRight: "10px",
    },
  },
}));

const option_style = {
  display: "flex",
  fontSize: "11pt",
  padding: "10px",
  cursor: "pointer",
  borderBottom: "1px solid #555",
  marginBottom: "0",
};

const label_1 = "Firm";
const label_2 = "Account";
const label_3 = "Location";

export default ({ options, onChange, group_by, group_id }) => {
  const classes = useStyles();
  const { level_1, level_2, level_3 } = groupByLevel(options);
  const [value_1, setValue1] = useState(null);
  const [value_2, setValue2] = useState(null);
  const [value_3, setValue3] = useState(null);
  const [levelAccount, setLevelAccount] = useState([]);
  const [levelLocation, setLevelLocation] = useState([]);

  useEffect(() => {
    setLevelAccount(level_2);
    setLevelLocation(level_3);

    if (group_by === "location") {
      var target = _.find(level_3, { value: group_id });
      if (target) {
        onChangeLevel3(null, target);
      }
    } else if (group_by === "account") {
      var target = _.find(level_2, { value: group_id });
      if (target) {
        onChangeLevel2(null, target);
      }
    } else if (group_by === "firm") {
      var target = _.find(level_1, { value: group_id });
      if (target) {
        onChangeLevel1(null, target);
      }
    }
  }, [options, group_by, group_id]);

  const onChangeLevel1 = (e, target_value) => {
    var val_1 = target_value;
    var val_2 = null;
    var val_3 = null;

    filterByLevel1(val_1);
    onChangeLocal({ val_1, val_2, val_3 });
  };

  const filterByLevel1 = (val_1) => {
    if (val_1) {
      setLevelAccount(_.filter(level_2, { lvl_1_value: val_1.value }));
      setLevelLocation(_.filter(level_3, { lvl_1_value: val_1.value }));
    } else {
      setLevelAccount(level_2);
      setLevelLocation(level_3);
    }
  };

  const onChangeLevel2 = (e, target_value) => {
    var val_1 = { ...value_1 };
    var val_2 = target_value;
    var val_3 = null;

    if (target_value !== null) {
      val_1 = {
        value: target_value.lvl_1_value,
        label: target_value.lvl_1_label,
        code: target_value.lvl_1_code,
        avatar: target_value.lvl_1_avatar,
      };
    }

    filterByLevel2(val_1, val_2);
    onChangeLocal({ val_1, val_2, val_3 });
  };

  const filterByLevel2 = (val_1, val_2) => {
    if (val_2) {
      setLevelLocation(_.filter(level_3, { lvl_2_value: val_2.value }));
    } else if (val_1) {
      setLevelAccount(_.filter(level_2, { lvl_1_value: val_1.value }));
      setLevelLocation(_.filter(level_3, { lvl_1_value: val_1.value }));
    } else {
      setLevelAccount(level_2);
      setLevelLocation(level_3);
    }
  };

  const onChangeLevel3 = (e, target_value) => {
    var val_1 = { ...value_1 };
    var val_2 = { ...value_2 };
    var val_3 = target_value;

    if (target_value !== null) {
      val_2 = {
        value: target_value.lvl_2_value,
        label: target_value.lvl_2_label,
        code: target_value.lvl_2_code,
        avatar: target_value.lvl_2_avatar,
      };
      val_1 = {
        value: target_value.lvl_1_value,
        label: target_value.lvl_1_label,
        code: target_value.lvl_1_code,
        avatar: target_value.lvl_1_avatar,
      };
    }

    onChangeLocal({ val_1, val_2, val_3 });
  };

  const onChangeLocal = ({ val_1, val_2, val_3 }) => {
    var firm_id = null;
    var account_id = null;
    var location_id = null;

    setValue1(val_1);
    setValue2(val_2);
    setValue3(val_3);

    if (val_1) {
      firm_id = val_1.value;
    }
    if (val_2) {
      account_id = val_2.value;
    }
    if (val_3) {
      location_id = val_3.value;
    }

    onChange({ firm_id, account_id, location_id });
  };

  const OptionComponent = ({ option, props }) => {
    const avatar = option.avatar ? (
      <Avatar src={option.avatar}>{option.code}</Avatar>
    ) : (
      <Avatar>{option.code}</Avatar>
    );
    return (
      <div style={option_style} {...props}>
        <span className={classes.avatar_wrapper}>{avatar}</span>
        {option.label}
      </div>
    );
  };

  return (
    <Box className={classes.box}>
      <Autocomplete
        size="small"
        value={value_1}
        options={level_1}
        className={classes.input}
        onChange={onChangeLevel1}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        renderInput={(params) => (
          <TextField {...params} label={label_1} variant="outlined" />
        )}
      />
      <Autocomplete
        size="small"
        value={value_2}
        options={levelAccount}
        className={classes.input}
        onChange={onChangeLevel2}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        renderInput={(params) => (
          <TextField {...params} label={label_2} variant="outlined" />
        )}
      />
      <Autocomplete
        size="small"
        value={value_3}
        options={levelLocation}
        className={classes.input}
        onChange={onChangeLevel3}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        renderInput={(params) => (
          <TextField {...params} label={label_3} variant="outlined" />
        )}
      />
    </Box>
  );
};
