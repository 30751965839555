import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  footer: {
    gap: 20,
    marginTop: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 20,
  },
  cancel: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
  save: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const FilterButtons = ({
  labelSave,
  onClickSave,
  disableSave = false,
  labelCancel,
  onClickCancel,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Button
        variant="contained"
        className={classes.cancel}
        onClick={onClickCancel}
      >
        {labelCancel}
      </Button>
      <Button
        variant="contained"
        className={classes.save}
        onClick={onClickSave}
        disabled={disableSave}
      >
        {labelSave}
      </Button>
    </div>
  );
};

export default FilterButtons;
