import React, { useState, useEffect } from "react";
import _ from 'lodash';
import {
  Box,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import { doAuthenticatedPost } from '../../../actions/_methods';

import { STYLES } from './styles';
import Element from './micro_components/element';

export default ({api_path, creatable_columns, columns, afterCreate}) => {
  const [mode, setMode] = useState('close')
  const [local_data, setLocalData] = useState({});

  useEffect(() => {
    setLocalData(defaultData());
  }, []);

  const defaultData = () => {
    let new_data = {}
    const flattened_create_columns = _.flattenDeep(creatable_columns);
    columns.forEach(col => {
      if(flattened_create_columns.includes(col['column'])) {
        if(col['default'] !== undefined) {
          new_data[col['column']] = col['default']
        }
      }
    });
    return new_data;
  }

  const onSave = () => {
    onCreate({...local_data})
    setLocalData({...local_data})
  }

  const onChange = (new_data) => {
    Object.keys(new_data).forEach(key => {
      local_data[key] = new_data[key];
    });
  }

  const onCreate = (new_data) => {
    doAuthenticatedPost(api_path + `/`, new_data, {
      general: onSuccessCreate,
      success: onSuccessCreate,
      error: onErrorUpdate
    });
  }

  const onSuccessCreate = (res) => {
    alert('Successfully created')
    setLocalData({})
    setMode('close')
    afterCreate(res)
  }

  const onErrorUpdate = (res) => {
    const errors = Object.keys(res.errors).map(key => key + ': ' + res.errors[key].join()).join("\n");
    alert(`Please try again...\n${errors}`);
  }

  const SaveButton = () => (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      onClick={onSave}
      style={{marginRight: '10px'}}
    >Save</Button>
  )

  const CancelButton = () => (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={() => setMode('close')}
    >Cancel</Button>
  )

  const ElementRow = ({col_rows}) => (
    <Box style={STYLES.inner_box}>
      {col_rows.map(col => {
        return(
          <Element
            columns={columns}
            column={col}
            data={local_data}
            onChange={onChange}
          />
        )
      })}
    </Box>
  )

  const FormCard = () => (
    <Card style={STYLES.card_box}>
      <CardContent>
        {creatable_columns.map(cr => <ElementRow col_rows={cr} />)}
        <Box>
          <SaveButton />
          <CancelButton />
        </Box>
      </CardContent>
    </Card>
  )


  const OpenCreateButton = () => (
    <Button
      variant="contained"
      size="small"
      onClick={() => setMode('create')}
    >Add New Record</Button>
  )

  const CloseMode = () => (
    <OpenCreateButton />
  )

  const CreateMode = () => (
    <FormCard />
  )

  return (
    <div style={STYLES.create_wrapper}>
      {mode === 'close' && <CloseMode />}
      {mode === 'create' && <CreateMode />}
    </div>
  )
}
