import React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useFirmsCtx } from '../../../features/manage/firms/context';

const CustomPagination = () => {
  const { page, setPage, totalPages } = useFirmsCtx();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Stack
      spacing={10}
      direction="row"
      display="flex"
      paddingY={1.5}
      justifyContent="center"
      alignItems="center"
    >
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
};

export default CustomPagination;
