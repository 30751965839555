import React from 'react';
import { makeStyles } from '@mui/styles';
import { useFirmsCtx } from '../context';
import { Spinner } from '../../../../shared/components/utils/_spinner';
import CustomPagination from '../../../../shared/components/utils/_custom_pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Firm from './_firm';

const useStyles = makeStyles((theme) => ({
  datagrid_wrapper: {
    marginTop: theme.spacing(3),
    height: 400,
    width: '100%',
  },
  head: {
    backgroundColor: '#1967D2',
  },
  cell: {
    fontSize: '14px',
    color: 'white',
  },
  total: {
    fontWeight: 'bold',
    paddingLeft: '5px',
  },
  pagination_container: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const Firms = () => {
  const classes = useStyles();
  const { firms, isLoading, totalFirms } = useFirmsCtx();

  return (
    <>
      <TableContainer component={Paper}>
        {isLoading === 'secondary' ? (
          <Spinner height={700} />
        ) : (
          <Table className={classes.table} aria-label="firms table">
            <TableHead>
              <TableRow className={classes.head}>
                <TableCell className={classes.cell}>
                  Total Firm(s):
                  <span className={classes.total}>{totalFirms}</span>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firms.map((firm) => (
                <Firm key={firm.id} firm={firm} />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <div className={classes.pagination_container}>
        <CustomPagination />
      </div>
    </>
  );
};

export default Firms;
