import React from 'react';
import { makeStyles } from '@mui/styles';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles({
  header: {
    background: '#1976d2',
  },
  title: {
    color: 'whitesmoke',
  },
});

const UserListHeader = () => {
  const classes = useStyles();

  return (
    <TableHead className={classes.header}>
      <TableRow>
        <TableCell className={classes.title}>User</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default UserListHeader;
