export const sortData = (data, sortKey, sortAscending) => {
  const sortedData = [...data].sort((a, b) => {
    if (typeof a[sortKey] === 'string') {
      return sortAscending
        ? a[sortKey].localeCompare(b[sortKey])
        : b[sortKey].localeCompare(a[sortKey]);
    } else {
      return sortAscending ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey];
    }
  });
  return sortedData;
};
