import React from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';

export default ({task}) => {

  const readableDate = () => {
    let due_date = moment(task.due_date).format('MMM Do YYYY')
    if(due_date === 'Invalid date') {
      return 'Not yet set';
    }
    return due_date;
  }

  const colorContext = () => {
    if(isOverDue()) {
      return {color: 'error.main'}
    } else if(isToday()) {
      return {color: 'info.main'}
    } else if(isFuture()) {
      return {color: 'text.primary'}
    } else {
      return {color: 'text.primary'}
    }
  }

  const isOverDue = () => {
    return moment().format("YYYYMMDD") > moment(task.due_date).format("YYYYMMDD")
  }

  const isToday = () => {
    return moment().format("YYYYMMDD") === moment(task.due_date).format("YYYYMMDD")
  }

  const isFuture = () => {
    return moment().format("YYYYMMDD") < moment(task.due_date).format("YYYYMMDD")
  }

  return(
    <>
      <Typography variant="h6" sx={colorContext()}>
        Due on: {readableDate()}
      </Typography>
    </>
  )

}
