import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

export default ({task}) => {

  return(
    <div>
      {task.assigned_to.map(user => <Chip
        avatar={<Avatar alt={user.label} src={user.avatar} />}
        label={user.label}
        variant="outlined"
        style={{marginLeft: '3px', marginBottom: '3px'}}
      />)}
    </div>
  )

}
