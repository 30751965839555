export const filterReport = (results, tags) => {
  if (tags.length === 0) {
    return results;
  }

  return results.filter((firm) => {
    return tags.every((tag) => firm.tags.includes(tag));
  });
};

export const getCurrentWeek = () => {
  const timeZone = 'Asia/Manila';
  const currentDate = new Date(
    new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date())
  );

  const year = currentDate.getFullYear();
  const oneJan = new Date(year, 0, 1);

  oneJan.setTime(oneJan.getTime() + oneJan.getTimezoneOffset() * 60 * 1000);
  const oneJanOffset = new Date(oneJan.toLocaleString('en-US', { timeZone }));

  const numberOfDays = Math.floor(
    (currentDate - oneJanOffset) / (24 * 60 * 60 * 1000)
  );

  const startDay = oneJanOffset.getDay() || 7;
  const currentDay = currentDate.getDay() || 7;
  const weekNumber = Math.ceil((numberOfDays + startDay - currentDay) / 7);

  return `${year}-W${weekNumber}`;
};

export const getCurrentMonth = () => {
  const timeZone = 'Asia/Manila';
  const currentDate = new Date(
    new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
    }).format(new Date())
  );

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${year}-${formattedMonth}`;
};

export const calculateWeeKRange = (weekValue) => {
  const [year, week] = weekValue.split('-W').map(Number);

  const startDate = new Date(year, 0, 1 + (week - 1) * 7);
  if (startDate.getDay() === 0) {
    startDate.setDate(startDate.getDate() + 1);
  }

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 5);

  const formatDate = (date) =>
    date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

  const startDateString = formatDate(startDate);
  const endDateString = formatDate(endDate);

  return `${startDateString} - ${endDateString}`;
};

export const formatMonthYear = (monthValue) => {
  const [year, month] = monthValue.split('-').map(Number);

  const date = new Date(year, month - 1);

  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  });

  return formattedDate;
};

export const calculateRange = (dateValue, type = 'week') => {
  return type === 'week'
    ? calculateWeeKRange(dateValue)
    : formatMonthYear(dateValue);
};
