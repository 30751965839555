import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { doAuthenticatedPut } from '../../../../../actions/_methods';
import { useGlobal } from '../../../../context/global';
import { API_MANAGE_FIRM_PREFERENCES } from '../../../../../const/api_paths';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    padding: '30px 20px 0 20px',
  },
  cancel: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
  save: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const Footer = ({ firm, preferences, handleClose }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();

  const endpoint = () => {
    return API_MANAGE_FIRM_PREFERENCES.replace(':firm_id', firm.id);
  };

  const savePreferences = () => {
    doAuthenticatedPut(
      endpoint(),
      { preferences: preferences },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => alert('Please try again'),
      }
    );
  };

  const onSuccess = (res) => {
    const message = 'Successfully saved!';

    handleClose();
    prompt(message);
  };

  return (
    <div className={classes.footer}>
      <Button
        variant="contained"
        className={classes.cancel}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        className={classes.save}
        onClick={savePreferences}
      >
        Save preferences
      </Button>
    </div>
  );
};

export default Footer;
