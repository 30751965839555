import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { API_MANAGE_FIRM_PREFERENCES } from '../../../../../const/api_paths';
import { doAuthenticatedGet } from '../../../../../actions/_methods';
import { useGlobal } from '../../../../context/global';

import Header from './Header';
import Options from './Options';
import Footer from './Footer';
import Loader from '../Loader';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    backgroundColor: '#f8f8ff',
    padding: 16,
    borderRadius: '5px',
  },
});

const defaultPreferences = {
  timezone: 'Asia/Manila',
  weekly_task_auto_generate: 'true',
  pass_the_baton: 'false',
  enable_add_task: 'false',
  show_page_count: 'false',
  nightly_report: 'false',
};

const FirmSettings = ({ firm, open, handleClose }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [preferences, setPreferences] = useState(defaultPreferences);
  const [loading, setLoading] = useState(false);

  const endpoint = () => {
    return API_MANAGE_FIRM_PREFERENCES.replace(':firm_id', firm.id);
  };

  const loadPreferences = () => {
    setLoading(true);
    doAuthenticatedGet(
      endpoint(),
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const onSuccess = (res) => {
    setPreferences(res.preferences);
    setLoading(false);
  };

  const onError = () => {
    const message = 'Something went wrong. Please try again.';

    prompt(message, { variant: 'warning' });
    setLoading(false);
  };

  useEffect(() => {
    if (open) loadPreferences();
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <Header firm={firm} />
        {loading ? (
          <Loader />
        ) : (
          <Options preferences={preferences} setPreferences={setPreferences} />
        )}
        <Footer
          firm={firm}
          preferences={preferences}
          handleClose={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default FirmSettings;
