import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import _ from 'lodash';

import { Button, Menu, MenuItem } from '@mui/material';

import {
  KeyboardArrowDown,
  PlayCircleFilled,
  PauseCircle,
  CheckCircle,
} from '@mui/icons-material';

import { statusColor, statusLabel } from '../../../../../../utils/task_status';
import { useFirmTasks } from '../../../../../../../context/firm_tasks';
import { useReferences } from '../../../../../../../context/references';

const THEMED_STYLES = ({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  />
))(THEMED_STYLES);

export default ({ task, field, update_field, onChange }) => {
  const { myself } = useReferences();
  const [anchorEl, setAnchorEl] = useState(null);
  const [local_task, setLocalTask] = useState({ ...task });
  const { pendingAcknowledgements, setShowPendingItems } = useFirmTasks();
  const open = Boolean(anchorEl);

  useEffect(() => {
    setLocalTask(task);
  }, [task]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    const started = option.value === 'on_going';
    const ending = option.value === 'completed';
    const hasPendingItems = pendingAcknowledgements.length > 0;
    let new_data = {};

    if (ending && hasPendingItems) {
      setShowPendingItems(true);
      return;
    }

    new_data[update_field] = option.value;
    onChange(new_data);

    local_task.status = option.value;
    setLocalTask(local_task);

    if (started && hasPendingItems) {
      setShowPendingItems(true);
    }

    handleClose();
  };

  const ongoingOtherWorkers = () => {
    return _.reject(
      _.filter(local_task.status_per_user, { status: 'on_going' }),
      { user_id: myself.id }
    );
  };

  const myOngoing = () => {
    return _.filter(local_task.status_per_user, {
      status: 'on_going',
      user_id: myself.id,
    });
  };

  const new_options = () => {
    const data = [];

    if (myOngoing().length === 0) {
      data.push({
        label: 'Start',
        value: 'on_going',
        icon: <PlayCircleFilled color="primary" />,
      });
    } else {
      data.push({
        label: 'Pause/Stop',
        value: 'paused',
        icon: <PauseCircle />,
      });
      if (ongoingOtherWorkers().length === 0) {
        data.push({
          label: 'Completed',
          value: 'completed',
          icon: <CheckCircle />,
        });
      }
    }
    return data;
  };

  return (
    <>
      <Button
        size="small"
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        style={statusColor(local_task[field])}
      >
        {statusLabel(local_task[field])}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {new_options().map((option) => {
          return (
            <MenuItem
              key={`status_item_${Math.random()}`}
              onClick={() => handleSelectOption(option)}
              disableRipple
            >
              {option.icon}
              {option.label}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};
