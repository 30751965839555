import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Chip, Avatar, Autocomplete } from '@mui/material';

const useStyles = makeStyles({
  container: {
    width: (props) => props.width,
  },
  input: {
    width: '100%',
    marginRight: '10px',
  },
  avatar_wrapper: {
    '& > div': {
      width: '28px',
      height: '28px',
      marginRight: '10px',
    },
  },
});

const option_style = {
  display: 'flex',
  fontSize: '11pt',
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '1px solid #555',
  marginBottom: '0',
};

export default ({
  name,
  title,
  default_value,
  options,
  onChange,
  width,
  limit = 1,
}) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState(default_value);

  useEffect(() => {
    if (default_value && default_value.length === 0) {
      setValue(default_value);
    }
  }, [default_value]);

  const onChangeLocal = (e, target_value) => {
    setValue(target_value);
    onChange(sendBackData(target_value));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value.map((u) => {
      if(u.id !== undefined) {
        return u.id;
      } else {
        return u.value;
      }
    });

    return data;
  };

  const OptionComponent = ({ option, props }) => {
    const avatar = option.profile_pic ? (
      <Avatar src={option.profile_pic}>{option.code}</Avatar>
    ) : (
      <Avatar>{option.code}</Avatar>
    );
    return (
      <div style={option_style} {...props}>
        <span className={classes.avatar_wrapper}>{avatar}</span>
        {option.label}
      </div>
    );
  };

  const InputComponent = ({ params, title }) => (
    <TextField {...params} label={title} variant="outlined" />
  );

  const tagComponent = (tagValue, getTagProps, classes) => {
    return tagValue.map((option, index) => {
      const avatar = option.profile_pic ? (
        <Avatar src={option.profile_pic}>{option.code}</Avatar>
      ) : (
        <Avatar>{option.code}</Avatar>
      );
      return (
        <Chip
          avatar={avatar}
          label={option.label}
          variant="outlined"
          className={classes.tag}
          {...getTagProps({ index })}
        />
      );
    });
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        multiple
        size="small"
        value={value}
        options={options}
        limitTags={limit}
        className={classes.input}
        onChange={onChangeLocal}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderTags={(value, props) => tagComponent(value, props, classes)}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        renderInput={(params) => <InputComponent {...{ params, title }} />}
        sx={{ minWidth: "200px"}}
      />
    </div>
  );
};
