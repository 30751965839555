import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, Select, MenuItem } from '@mui/material';

const useStyles = makeStyles({
  control: () => ({
    width: 160,
    marginLeft: 10,
  }),
  placeholder: {
    color: '#00000029',
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 200,
    },
  },
};

const roles = {
  user: 'User',
  manager: 'Manager',
  admin: 'Admin',
  angel: 'Angel',
};

const DropdownRoles = ({ setSelectedRole }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedRole(event.target.value);
  };

  const renderValue = (selected) => {
    if (selected === '') {
      return <em className={classes.placeholder}>Select a role</em>;
    }

    return roles[selected] || selected;
  };

  return (
    <FormControl className={classes.control}>
      <Select
        size="small"
        value={value}
        onChange={handleChange}
        MenuProps={MenuProps}
        displayEmpty
        renderValue={renderValue}
      >
        {Object.entries(roles).map(([key, label]) => (
          <MenuItem key={key} value={key}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownRoles;
