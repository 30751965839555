import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Stack } from '@mui/material';
import Filters from './Filters';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    height: 40,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const FilterBar = ({
  type,
  date,
  firms,
  error,
  results,
  setDate,
  setRange,
  selectedFirms,
  generateReport,
  downloadReport,
  downloadAPTransactionReport,
  setSelectedFirms,
}) => {
  const classes = useStyles();
  const noFirms = selectedFirms === null;
  const hasError = error !== null;

  return (
    <div className={classes.container}>
      <Filters
        type={type}
        date={date}
        label="Firms"
        firms={firms}
        results={results}
        setDate={setDate}
        setRange={setRange}
        setSelectedFirms={setSelectedFirms}
      />
      <Stack direction="row" spacing={2} className={classes.buttonsContainer}>
        <Button
          disabled={noFirms}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={generateReport}
        >
          Generate
        </Button>
        <Button
          disabled={noFirms || hasError}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={downloadReport}
        >
          Download
        </Button>
        <Button
          disabled={noFirms || hasError}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={downloadAPTransactionReport}
        >
          Download AP Transaction Count
        </Button>
      </Stack>
    </div>
  );
};

export default FilterBar;
