import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  IconButton,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  doAuthenticatedPost,
  doAuthenticatedDelete
} from '../../../../actions/_methods';
import { API_MANAGE_USERS } from '../../../../const/api_paths';

const STYLES = {
  element: {
    marginRight: '10px',
    width: '100%',
  }
}

export default ({columns, column, data, onChange}) => {
  const column_def = _.find(columns, {'column': column});
  // const label = _.find(columns, {'column': column}).header;
  const [user_roles, setUserRoles] = useState([]);
  const [role, setRole] = useState('');
  const [selected_firm, setSelectedFirm] = useState(null);

  useEffect(() => {
    setUserRoles(data[column]);
  }, [data]);

  // const onChangeLocal = (e) => {
  //   let new_data = {}
  //   new_data[column] = e.target.value;
  //   onChange(new_data);
  // }

  const onDelete = (user_role) => {
    if(window.confirm(`Are you sure you want to delete this role?`)) {
      doAuthenticatedDelete(`${API_MANAGE_USERS}/${data.id}/delete_role`, {user_role_id: user_role.id}, {
        general: onDeleteSuccess,
        success: onDeleteSuccess,
        error: onDeleteError
      });
    }
  }

  const onDeleteSuccess = (res) => {
    setUserRoles(res.user.roles)
  }

  const onDeleteError = (res) => {
    alert(`Please try again...`);
  }

  const handleRoleChange = (e) => {
    setRole(e.target.value)
  }

  const handleFirmChange = (e, target_value) => {
    setSelectedFirm(target_value)
  }

  const onAddRole = () => {
    const new_data = {
      firm_id: selected_firm.value,
      name: role
    };

    doAuthenticatedPost(`${API_MANAGE_USERS}/${data.id}/add_role` , new_data, {
      general: onAddSuccess,
      success: onAddSuccess,
      error: onAddError
    });
  }

  const onAddSuccess = (res) => {
    setUserRoles(res.user.roles);
    setSelectedFirm(null);
  }

  const onAddError = (res) => {
    alert(`Please try again...`);
  }


  const RolesInTable = () => {
    if(user_roles && user_roles.length > 0) {
      return(
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {user_roles.map((user_role) => {
                return(
                  <TableRow>
                    <TableCell>{user_role.firm_name}</TableCell>
                    <TableCell>{user_role.name}</TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => onDelete(user_role)}
                        aria-label="delete"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return null;
    }
  }

  const AddNewRole = () => (
    <Paper>
      <div style={{display: 'flex'}}>
        <Autocomplete
          disablePortal
          options={column_def.options.firms}
          sx={{ width: 300 }}
          onChange={handleFirmChange}
          value={selected_firm}
          renderInput={(params) => <TextField {...params} label="Firm" />}
        />
        <FormControl sx={{ width: 300, marginLeft: 2 }}>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={role}
            label="Role"
            onChange={handleRoleChange}
          >
            {column_def.options.roles.map((r) => <MenuItem value={r}>{r}</MenuItem>)}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          sx={{ marginLeft: 2 }}
          onClick={onAddRole}
        >
          Add Role
        </Button>
      </div>
    </Paper>
  )


  return (
    <Box>
      <RolesInTable />
      <AddNewRole />
    </Box>
  )
}
