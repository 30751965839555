import React, { useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 24,
      height: 24,
      marginRight: 10,
    },
  },
  optionStyle: {
    fontSize: '11pt',
    cursor: 'pointer',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'flex-start',
  },
});


const AssignedUsers = ({assigned_users}) => {
  const classes = useStyles();

  const CustomUser = ({ user }) => {
    const avatar = <Avatar src={user.profile_pic}>{user.code}</Avatar>;
    return (
      <div className={classes.optionStyle}>
        <span className={classes.avatarWrapper}>{avatar}</span>
        {user.label}
      </div>
    )
  };

  return (
    <Stack spacing={1}>
      {assigned_users.map((user) => 
        <Chip key={user.id} variant="outlined" size="medium" label={
          <CustomUser user={user} />
        }>
        </Chip>
      )}
      {assigned_users.length == 0 && 
        <Chip label="No Assigned Users" color="error" size="small" />
      }
    </Stack>
  )
}

export default AssignedUsers;