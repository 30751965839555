import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddTask from './_add_task';
import TaskInfo from './_task_info';

export default ({firm_id, category}) => {
  const [selected, setSelected] = useState(false);
  const category_tasks = useSelector(state => state.project.category_tasks);

  useEffect(() => {
    if(category_tasks[category.id]['tasks'].length > 0) {
      setSelected(true);
    }
  }, []);

  const handleChange = (e) => {
    setSelected(e.target.checked);
  };

  return (
    <Card sx={{marginBottom: "20px", padding: "15px"}}>
      <FormGroup>
        <FormControlLabel control={
          <Checkbox
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            checked={selected}
            onChange={handleChange}
          />
        } label={category.name} />
      </FormGroup>
      {selected && <>
        <br/>
        <div style={{display: 'block'}}>
          {category_tasks[category.id] && category_tasks[category.id]['tasks'] && <>
            {category_tasks[category.id]['tasks'].map((task) => {
              return(
                <TaskInfo task={task} />
              )
            })}
          </>}
          <br/>
          <AddTask category={category} firm_id={firm_id} />
        </div>
      </>}
    </Card>
  )
}