import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { filterReport, getCurrentWeek, calculateRange } from '../utils';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    gap: 30,
  },
  note: {
    color: '#0000005c',
    fontSize: 12,
    marginTop: 5,
  },
});

const Filters = ({
  week,
  tags,
  results,
  setWeek,
  setRange,
  setTagsString,
  setDisplayedResults,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const currentWeek = getCurrentWeek();
  const label = 'Tags';

  const onWeekChange = (event) => {
    const value = event.target.value;
    setWeek(value);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const tagsString = value.join(',');

    setTagsString(tagsString);
    setValues(value);
  };

  useEffect(() => {
    setDisplayedResults(filterReport(results, values));
  }, [results, values]);

  useEffect(() => {
    setWeek(currentWeek);
    setRange(calculateRange(currentWeek));
  }, []);

  useEffect(() => {
    if (week) setRange(calculateRange(week));
  }, [results]);

  return (
    <div className={classes.wrapper}>
      <TextField
        label="Date Range"
        type="week"
        className={classes.dateRange}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        onChange={onWeekChange}
      />
      <div>
        <FormControl variant="outlined" sx={{ minWidth: 160 }}>
          <InputLabel size="small">{label}</InputLabel>
          <Select
            size="small"
            multiple
            value={values}
            onChange={handleChange}
            name="tags"
            label={label}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              PaperProps: {
                style: { maxHeight: 300 },
              },
            }}
          >
            {tags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Filters;
