import React from "react";
import { makeStyles } from '@mui/styles';
import {
  Box,
  Paper,
  Avatar,
  Tooltip,
} from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '2px',
  },
  
  avatar_group: {
    flexDirection: 'row',
    '& > div': {
      width: '35px',
      height: '35px',
    },
  }
}));

export default ({task}) => {
  const classes = useStyles();

  return(
    <Box className={classes.box} marginBottom={1}>
      <AvatarGroup max={7} className={classes.avatar_group} flexDirection="row">
        {task.assigned_to.map(user => (
          <Tooltip key={`avatar_tooltip_${Math.random()}`} title={user.label}>
            <Avatar key={`assigned_to_avatar_${Math.random()}`} alt={user.name} src={user.avatar}></Avatar>
          </Tooltip>
        ))}
      </AvatarGroup>
    </Box>
  )
}