import React, { useState, useEffect } from "react";
import _ from 'lodash';
import {
  Button,
  Paper,
  Box,
  TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { doAuthenticatedPut } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS_UPDATE_PROJECT_TASK } from '../../../../../const/api_paths';

export default ({ task }) => {
  const [selecting, setSelecting] = useState(false);
  const [data, setData] = useState({});
  const [local_task, setLocalTask] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setLocalTask(task)
  }, [task]);

  const handleChange = (new_data) => {
    const params = { ...data, ...new_data };
    setData(params);
    setLocalTask({ ...local_task, ...new_data });
  }

  const onSubmit = (event) => {
    const url = API_MANAGE_PROJECTS_UPDATE_PROJECT_TASK.replace(':firm_id', task.firm_id);
    const new_data = { task_id: task.id };
    const params = { ...data, ...new_data };

    closeSnackbar();
    setSubmitting(true);

    doAuthenticatedPut(url, params, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const onSuccess = (res) => {
    setSubmitting(false);
    enqueueSnackbar('You have successfully UPDATED estimated hours.', {variant: 'success'});
    setSelecting(false);
  }

  const onError = (res) => {
    setSubmitting(false);
    enqueueSnackbar('Please try again', {variant: 'error'});
    console.log(res)
  }

  return (
    <>
      <Button onClick={() => setSelecting(!selecting)}>Set Estimated Hours</Button>
      {selecting && <>
        <Box style={{ width: '100%', marginBottom: "15px" }}>
          <Paper elevation={3} style={{ width: '100%', padding: "15px 20px" }}>
            <div style={{display: 'flex'}}>  
              <TextField
                id={`client_est_hours_${task.id}`}
                labelId="select-email-senders-label"
                label="Client Estimated Hours"
                variant="outlined"
                value={local_task.client_est_hours}
                onChange={(e) => handleChange({client_est_hours: e.target.value })}
                style={{ width: '50%' }}
              />
              <TextField
                id={`admin_est_hours_${task.id}`}
                labelId="select-email-senders-label"
                label="Admin Estimated Hours"
                variant="outlined"
                value={local_task.admin_est_hours}
                onChange={(e) => handleChange({admin_est_hours: e.target.value })}
                style={{ width: '50%', marginLeft: '10px' }}
              />
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={submitting}
                style={{marginLeft: '10px'}}
              >Save</Button>
            </div>
          </Paper>
        </Box>
      </>}
    </>
  )

}
