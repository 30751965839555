import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import AddWorkMins from './add_comment/AddWorkMins';
import DeductWorkMins from './add_comment/DeductWorkMins';
import AddInvoices from './add_comment/AddInvoices';
import AddInvoicePages from './add_comment/AddInvoicePages';
import AddRejectedPages from './add_comment/AddRejectedPages';
import AddBreakMins from './add_comment/AddBreakMins';
import AddReportingMins from './add_comment/AddReportingMins';
import { usePermission } from '../../../../../../context/permission';
import { IfAnyGranted } from 'react-authorization';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    diplay: 'flex',
    flexGrow: 1,
    marginTop: '10px',
  },
  element_row: {
    display: 'flex',
    flexGrow: 1,
  },
  textfield: {
    width: '100%',
    marginRight: '10px',
  },
  card_wrapper: {
    padding: '10px',
  },
}));

export default ({ onSubmit }) => {
  const classes = useStyles();
  const [show_more, setShowMore] = useState(false);
  const { atleastAdmin, atleastManager, getMyRoles } = usePermission();

  const MoreButton = () => (
    <Button
      size="small"
      endIcon={<ArrowDropDownIcon />}
      onClick={() => setShowMore(!show_more)}
    >
      more presets
    </Button>
  );

  const LessButton = () => (
    <Button
      size="small"
      endIcon={<ArrowDropUpIcon />}
      onClick={() => setShowMore(!show_more)}
    >
      less presets
    </Button>
  );

  const CustomElements = () => (
    <Card variant="outlined" className={classes.card_wrapper}>
      <IfAnyGranted expected={atleastManager()} actual={getMyRoles()}>
        <AddWorkMins onSubmit={onSubmit} />
        <DeductWorkMins onSubmit={onSubmit} />
      </IfAnyGranted>
      <AddBreakMins onSubmit={onSubmit} />
      <AddReportingMins onSubmit={onSubmit} />
      <AddInvoices onSubmit={onSubmit} />
      <AddInvoicePages onSubmit={onSubmit} />
      <AddRejectedPages onSubmit={onSubmit} />
    </Card>
  );

  return (
    <div className={classes.wrapper}>
      {!show_more && <MoreButton />}
      {show_more && <LessButton />}
      {show_more && <CustomElements />}
    </div>
  );
};
