import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Box, Paper } from '@mui/material';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import Status from './Status';

const useStyles = makeStyles((theme) => ({
  box: {
    flexBasis: '11%',
    marginRight: '2px',
  },
  paper: {
    padding: '10px 5px',
    minHeight: '44px',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  working_wrapper: {
    display: 'flex',
    opacity: 0.5,
    alignItems: 'center',
  },
  working_icon: {
    color: '#1b97fc',
    marginRight: '4px',
    width: '15px',
  },
  working_name: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
  working_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
}));

export default ({ task, onChange }) => {
  const classes = useStyles();

  const ongoingWorkers = () => {
    return _.filter(task.status_per_user, { status: 'on_going' });
  };

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        <Status
          task={task}
          field={`status`}
          update_field={`pseudo_status`}
          onChange={onChange}
        />
        <div className={classes.working_container}>
          {ongoingWorkers().map((w) => {
            return (
              <div
                className={classes.working_wrapper}
                key={`ongoing_work_${task.id}_${w.user_id}`}
              >
                <RunCircleIcon
                  fontSize="small"
                  className={classes.working_icon}
                />
                <small className={classes.working_name}>{w.display_name}</small>
              </div>
            );
          })}
        </div>
      </Paper>
    </Box>
  );
};
