import React, { useState } from "react";
import FilterDropdown from "../FilterDropdown";
import ManageCard from "./manage-card/ManageCard";
import EditTemplate from "../edit-template/EditTemplate";
import { doAuthenticatedPost } from "../../../../../../actions/_methods";
import {
  ACCOUNTING_PROJECT_TASK_ARCHIVE,
  ACCOUNTING_PROJECT_TASK_RESET_PH,
  ACCOUNTING_PROJECT_TASK_RESET_US,
} from "../../../../../../const/api_paths";
import { useGlobal } from "../../../../../context/global";
import { usePermission } from "../../../../../context/permission";

const cellActionsFiltered = (atleastAdmin, atleastAngel) => {
  const default_action = [
    { id: 1, name: "Edit", value: "edit" },
    { id: 2, name: "Manage Client Card", value: "manage_client" },
  ];
  let new_action = [];

  if (atleastAdmin) {
    new_action.push(
      { id: 3, name: "Reset Tasks (PH)", value: "reset_ph" },
      { id: 4, name: "Reset Tasks (US)", value: "reset_us" }
    );
  }

  if (atleastAngel) {
    new_action.push({ id: 5, name: "Archive", value: "archive" });
  }
  return [...default_action, ...new_action];
};

const CellActions = ({
  firm,
  task,
  users,
  reloadData,
  topLevelCategories,
  subLevelCategories,
}) => {
  const [showManageCard, setShowManageCard] = useState(false);
  const [showEditTemplate, setShowEditTemplate] = useState(false);
  const { getRoleByFirm, isAtleastAngel } = usePermission();
  const { prompt } = useGlobal();

  // Should be admin role for the specific firm
  const atleastAngel = isAtleastAngel();
  const atleastAdmin = atleastAngel || (firm && getRoleByFirm(firm.id) === "admin");

  const onArchive = () => {
    if (!window.confirm("Are you sure you want to archive this task?")) return;

    const endpoint = ACCOUNTING_PROJECT_TASK_ARCHIVE.replace(":id", task.id);
    doAuthenticatedPost(
      endpoint,
      { firm_id: firm.id },
      {
        success: onSuccessArchive,
        general: onSuccessArchive,
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccessArchive = (res) => {
    const message = "Successfully archived task.";

    prompt(message);
    reloadData();
  };

  const onTaskResetPH = () => {
    if (
      !window.confirm(
        "Are you sure you want to this task (PH: starting from the start of today)?"
      )
    )
      return;

    const endpoint = ACCOUNTING_PROJECT_TASK_RESET_PH.replace(":id", task.id);
    doAuthenticatedPost(
      endpoint,
      { firm_id: firm.id },
      {
        success: onSuccessTaskResetPH,
        general: onSuccessTaskResetPH,
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccessTaskResetPH = (res) => {
    const message = "Successfully reset task (PH)";
    prompt(message);
    reloadData();
  };

  const onTaskResetUS = () => {
    if (
      !window.confirm(
        "Are you sure you want to this task (US: task starting from tomorrow)?"
      )
    )
      return;

    const endpoint = ACCOUNTING_PROJECT_TASK_RESET_US.replace(":id", task.id);
    doAuthenticatedPost(
      endpoint,
      { firm_id: firm.id },
      {
        success: onSuccessTaskResetUS,
        general: onSuccessTaskResetUS,
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccessTaskResetUS = (res) => {
    const message = "Successfully reset task (US)";
    prompt(message);
    reloadData();
  };

  const handleChange = (event) => {
    const option = event.target.value === "" ? null : event.target.value;
    const name = option && option.value;

    switch (name) {
      case "edit":
        setShowEditTemplate(true);
        break;

      case "manage_client":
        setShowManageCard(true);
        break;

      case "reset_ph":
        onTaskResetPH();
        console.log(name);
        break;

      case "reset_us":
        onTaskResetUS();
        console.log(name);
        break;

      case "archive":
        onArchive();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <FilterDropdown
        label={"More Actions"}
        width="100%"
        value={""}
        onChange={handleChange}
        options={cellActionsFiltered(atleastAdmin, atleastAngel)}
      />

      {showManageCard && (
        <ManageCard
          task={task}
          open={showManageCard}
          handleClose={() => setShowManageCard(false)}
        />
      )}

      <EditTemplate
        firm={firm}
        task={task}
        users={users}
        reloadData={reloadData}
        open={showEditTemplate}
        topLevelCategories={topLevelCategories}
        subLevelCategories={subLevelCategories}
        handleClose={() => setShowEditTemplate(false)}
      />
    </>
  );
};

export default CellActions;
