import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles({
  text: {
    width: 250,
  },
});

const Text = ({
  type,
  name,
  label,
  required = false,
  stateKey,
  stateSetter,
  existingValue,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const inputType = type === 'password' && !showPassword ? 'password' : 'text';

  const onChange = (event) => {
    const newValue = event.target.value;

    setValue(newValue);
    handleChange(newValue);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (newValue) => {
    stateSetter((prevState) => ({
      ...prevState,
      [stateKey]: newValue,
    }));
  };

  useEffect(() => {
    if (existingValue !== (null || '')) {
      setValue(existingValue);
    }
  }, [existingValue]);

  return (
    <TextField
      required={required}
      size="small"
      margin="normal"
      id={name}
      label={label}
      name={name}
      type={inputType}
      variant="outlined"
      value={value}
      onChange={onChange}
      className={classes.text}
      autoComplete={type === 'password' ? 'new-password' : 'off'}
      InputProps={{
        endAdornment:
          type === 'password' && value ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
    />
  );
};

export default Text;
