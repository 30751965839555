import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';

const useStyles = makeStyles({
  container: {
    maxHeight: 335,
    marginTop: 20,
    marginBottom: 40,
    overflow: 'auto',
  },
});

const columnWidths = [
  '50%', // Name
  '30%', // Status
  '20%', // Manage Button
];

const ReferenceList = ({
  references,
  setSelectedReference,
  setShowManageReference,
}) => {
  const classes = useStyles();

  const onManage = (ref) => {
    setSelectedReference(ref);
    setShowManageReference(true);
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: columnWidths[0] }}>Name</TableCell>
            <TableCell style={{ width: columnWidths[1] }}>Status</TableCell>
            <TableCell style={{ width: columnWidths[2] }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {references.map((reference) => (
            <TableRow key={reference.id}>
              <TableCell
                component="th"
                scope="row"
                style={{ width: columnWidths[0] }}
              >
                {reference.name}
              </TableCell>
              <TableCell style={{ width: columnWidths[1] }}>
                {reference.status}
              </TableCell>
              <TableCell align="right" style={{ width: columnWidths[2] }}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => onManage(reference)}
                >
                  Manage
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferenceList;
