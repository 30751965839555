import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  cell_large: {
    width: '300px',
  },
  cell: {
    width: '130px',
    textAlign: 'center'
  },
  date_range: {
    fontSize: '20px',
    marginLeft: '5px',
  }
}));

export default ({ results, date_range_str }) => {
  const classes = useStyles();
  const [local_results, setLocalResults] = useState([])

  useEffect(() => {
    setLocalResults(results);
  }, [results]);

  return (
    <>
    <Typography gutterBottom variant="h6" component="div">
      {date_range_str}
    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 900 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>Firm ID</TableCell>
            <TableCell className={classes.cell_large}>Firm Name</TableCell>
            <TableCell className={classes.cell}>Monday</TableCell>
            <TableCell className={classes.cell}>Tuesday</TableCell>
            <TableCell className={classes.cell}>Wednesday</TableCell>
            <TableCell className={classes.cell}>Thursday</TableCell>
            <TableCell className={classes.cell}>Friday</TableCell>
            <TableCell className={classes.cell}>Saturday</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {local_results.map((result, indx) => (
            <TableRow
              key={`tr_${result.indx}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className={classes.cell}>{result[0]}</TableCell>
              <TableCell component="th" scope="row">{result[1]}</TableCell>
              <TableCell className={classes.cell}>{result[2]}</TableCell>
              <TableCell className={classes.cell}>{result[3]}</TableCell>
              <TableCell className={classes.cell}>{result[4]}</TableCell>
              <TableCell className={classes.cell}>{result[5]}</TableCell>
              <TableCell className={classes.cell}>{result[6]}</TableCell>
              <TableCell className={classes.cell}>{result[7]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </>
  )
}