import React from 'react';
import { Typography } from '@mui/material';

export default ({task}) => {

  return(
    <Typography variant="body2" sx={{color: 'info.dark'}}>
      {task.category_name}
    </Typography>
  )

}