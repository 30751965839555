import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Box, Paper } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useFirmTasks } from '../../../../context/firm_tasks';

const useStyles = makeStyles({
  box: {
    flexBasis: '5%',
    marginRight: '2px',
  },
  paper: {
    padding: '10px 5px',
    height: '44px',
    textAlign: 'center',
    backgroundColor: blueGrey[200],
  },
});

const totalHours = (tasks) => {
  return _.sum(tasks.map((t) => parseFloat(t.client_est_hours)));
};

const round = (num) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

export default () => {
  const { firmTasks } = useFirmTasks();
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        {round(totalHours(firmTasks))}
      </Paper>
    </Box>
  );
};
