import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { SpinnerWithBackdrop } from '../../../shared/components/utils/_spinner';
import ErrorMessage from '../data_reports/report/ErrorMessage';
import Report from '../time_variance/report/Report';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});

const ReportContent = ({
  range,
  error,
  noData,
  loading,
  results,
  addedResults,
  deletedResults,
  isRequiredFieldsMissing,
}) => {
  const classes = useStyles();

  const emptyResult = (addedResults, deletedResults) => {
    const isAddedEmpty = addedResults.length === 0;
    const isDeletedEmpty = deletedResults.length === 0;
    const message = `There are no ${
      isAddedEmpty ? 'newly added' : 'deleted'
    } tasks for this time frame`;

    if (isAddedEmpty || isDeletedEmpty) {
      return <ErrorMessage message={message} height={250} />;
    }
  };

  return (
    <Box className={classes.box}>
      {loading && <SpinnerWithBackdrop />}

      {!loading && isRequiredFieldsMissing && (
        <ErrorMessage message="Please select a firm and date range." />
      )}

      {!loading &&
        !error &&
        results.length === 0 &&
        !isRequiredFieldsMissing && (
          <ErrorMessage message="You can either generate the report now, or select an account and (optional) location, for a more focused report." />
        )}

      {noData && <ErrorMessage message="No results." />}

      {error && <ErrorMessage message={error} />}

      {!loading && !isRequiredFieldsMissing && results.length > 0 && (
        <>
          <Box>
            {addedResults.length > 0 && (
              <Report
                range={range}
                maxHeight={400}
                results={addedResults}
                title={'Newly Added Tasks'}
              />
            )}
          </Box>

          <Box>
            {deletedResults.length > 0 && (
              <Report
                range={range}
                maxHeight={400}
                results={deletedResults}
                title={'Deleted Tasks'}
              />
            )}
          </Box>

          {emptyResult(addedResults, deletedResults)}
        </>
      )}
    </Box>
  );
};

export default ReportContent;
