import React from 'react';
import { makeStyles } from '@mui/styles';
import { TableBody, TableCell, TableRow } from '@mui/material';

const useStyles = makeStyles({
  cell: {
    width: '130px',
    textAlign: 'center',
  },
});

const tableRowStyles = {
  '&:last-child td, &:last-child th': {
    border: 0,
  },
};

const ReportBody = ({ results }) => {
  const classes = useStyles();

  const renderRow = (data, isTask = false) => (
    <TableRow key={data.id} sx={tableRowStyles}>
      <TableCell className={classes.cell}>{isTask ? '' : data.firm}</TableCell>
      <TableCell className={classes.cell}>{data.task}</TableCell>
      <TableCell className={classes.cell}>{data.monday}</TableCell>
      <TableCell className={classes.cell}>{data.tuesday}</TableCell>
      <TableCell className={classes.cell}>{data.wednesday}</TableCell>
      <TableCell className={classes.cell}>{data.thursday}</TableCell>
      <TableCell className={classes.cell}>{data.friday}</TableCell>
      <TableCell className={classes.cell}>{data.saturday}</TableCell>
      <TableCell className={classes.cell}>{data.total}</TableCell>
    </TableRow>
  );

  return (
    <TableBody>
      {results.map((firm) => (
        <React.Fragment key={firm.id}>
          {renderRow(firm)}
          {firm.tasks.map((task) =>
            renderRow({ ...task, firm: firm.firm }, true)
          )}
        </React.Fragment>
      ))}
    </TableBody>
  );
};

export default ReportBody;
