import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Modal } from '@mui/material';
import Footer from './Footer';
import Header from './Header';
import Options from './Options';
import Selector from './Selector';
import { doAuthenticatedGet } from '../../../../actions/_methods';
import { EXPORT_GOOGLE_SHEET } from '../../../../const/api_paths';
import { SpinnerWithBackdrop } from '../../../../shared/components/utils/_spinner';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    backgroundColor: '#f8f8ff',
    padding: 16,
    borderRadius: '5px',
  },
  message: {
    fontSize: 13,
    color: '#21242775',
    padding: '8px 16px 8px 16px',
  },
});

const defaultOptions = {
  name: '',
  sheet_id: '',
  starting_point: '',
  sheet_number: '',
  include_headers: 'true',
};

const GoogleSheet = ({ open, filters, handleClose, setAnchorEl }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [sheetType, setSheetType] = useState('new');
  const [options, setOptions] = useState(defaultOptions);

  const params = {
    ...filters,
    ...options,
    type: sheetType,
  };

  const onExport = () => {
    setLoading(true);
    setAnchorEl(null);
    const filteredParams = Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (value) acc[key] = value;
        return acc;
      },
      {}
    );

    doAuthenticatedGet(EXPORT_GOOGLE_SHEET, filteredParams, {
      success: onSuccess,
      general: onSuccess,
      error: (res) => console.error(res),
    });
  };

  const onSuccess = (res) => {
    setLoading(false);
    const windowGlobal = typeof window !== 'undefined' && window;
    const url = res.url || null;

    if (url) windowGlobal.open(url, '_blank');
  };

  const disableExport = useMemo(() => {
    if (sheetType === 'new') {
      return options.name.trim() === '';
    } else {
      const startingPointError =
        options.starting_point.trim() !== '' && errors.starting_point;
      return (
        (errors.starting_point !== undefined && startingPointError) ||
        options.sheet_id.trim() === ''
      );
    }
  }, [sheetType, options, errors]);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [sheetType]);

  if (loading) {
    return <SpinnerWithBackdrop />;
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <Header />
        <Selector width={200} setSheetType={setSheetType} />
        <div className={classes.message}>
          Important: If this is the first time or authorization has expired, you
          may be redirected to authorize this app through google. Once that
          process is finished, you can return to this page and proceed with the
          export.
        </div>
        <Options
          options={options}
          setErrors={setErrors}
          sheetType={sheetType}
          setOptions={setOptions}
        />
        <Footer
          saveLabel="Export"
          saveFunction={onExport}
          cancelFunction={handleClose}
          disableSave={disableExport}
        />
      </Box>
    </Modal>
  );
};

export default GoogleSheet;
