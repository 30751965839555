import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    width: (props) => props.width,
  },
  input: {
    width: '100%',
    marginRight: '10px',
  },
}));

export default ({ name, title, default_value, onChange, width }) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState(default_value);

  const onChangeLocal = (e) => {
    const target_value = e.target.value;
    setValue(target_value);
    onChange(sendBackData(target_value));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  };

  return (
    <div className={classes.container}>
      <TextField
        size="small"
        type="date"
        variant="outlined"
        className={classes.input}
        label={title}
        value={value}
        onChange={onChangeLocal}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
};
