import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Box, Typography, Paper, Divider, Button } from '@mui/material';
import { useGlobal } from '../../context/global';
import { useFirmTasks } from '../../context/firm_tasks';
import { useReferences } from '../../context/references';

import InputText from './_basic_filters/InputText';
import InputSelect from './_basic_filters/InputSelect';
import InputSelectMultiple from './_basic_filters/InputSelectMultiple';
import InputPeople from './_basic_filters/InputPeople';
import InputAutocomplete from './_basic_filters/InputAutocomplete';
import TaskCategory from "./_add_task/TaskCategory";

import { STATUS_OPTIONS, BILLABLE_OPTIONS, TEAM_OPTIONS } from '../const/ref';

import Organizational from './_add_task/Organizational';
import AccountLocation from './_add_task/AccountLocation';

export default ({
  classes,
  default_filter,
  references,
  onChange,
  no_organization,
}) => {
  const { firmUsers } = useGlobal();
  const { preferences } = useFirmTasks();
  const { organizations } = useReferences();
  const [data, setData] = useState({ ...default_filter });
  const [showTeam, setShowTeam] = useState(false);

  const applyFilter = () => {
    onChange(data);
  };

  const onChangeLocal = (new_data) => {
    const today_data = { ...data, due_date: moment().format('YYYY-MM-DD') };
    setData({ ...today_data, ...new_data });
    // onChange({...today_data, ...new_data});
  };

  const onChangeOrganization = (new_data) => {
    const today_data = { ...data, due_date: moment().format('YYYY-MM-DD') };
    setData({ ...today_data, ...new_data });
    // onChange({...today_data, ...new_data});
  };

  useEffect(() => {
    setShowTeam(preferences.pass_the_baton === 'true');
  }, [preferences]);

  return (
    <Box>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant={'h6'}>Today's Filters</Typography>
        <Divider className={classes.margin_bottom} />
        <Box display="flex" justifyContent="space-between" width="100%" gap={2}>
          <InputText
            width="20%"
            name="match_task_id"
            title="Task ID"
            onChange={onChangeLocal}
          />
          <InputText
            width="20%"
            name="match_name"
            title="Task Name"
            onChange={onChangeLocal}
          />
          <InputSelectMultiple
            width="20%"
            name="match_any_status"
            title="Status"
            options={STATUS_OPTIONS}
            onChange={onChangeLocal}
            default_value={[]}
          />
          <InputPeople
            width="20%"
            name="user_id"
            title="Person"
            options={firmUsers}
            onChange={onChangeLocal}
          />

          {/* <InputAutocomplete
            width="20%"
            name="category_id"
            title="Category"
            options={category_options}
            onChange={onChangeLocal}
          /> */}
          <InputText name="tags" title="Tags" onChange={onChangeLocal} />
        </Box>
        <Box display="flex" className={classes.margin_top}>
          {!no_organization && (
            <Box display="inline-flex" justifyContent="space-between">
              <Organizational
                onChange={onChangeOrganization}
                options={organizations}
              />
            </Box>
          )}
          <Box display="inline-flex" justifyContent="space-between">
            <AccountLocation
              onChange={onChangeOrganization}
              firm_id={default_filter.firm_id}
              options={organizations}
            />
          </Box>
          <Box display="inline-flex">
            <TaskCategory onChange={onChangeLocal} required={false} />
          </Box>
          {showTeam && (
            <Box display="inline-flex">
              <InputSelect
                name="match_team_timezone"
                title="Team"
                options={TEAM_OPTIONS}
                onChange={onChangeLocal}
                default_value=""
              />
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="success" onClick={applyFilter}>
            Apply Filter
          </Button>
        </Box>
        <a id="reload_all_tasks" onClick={() => onChange(data)}></a>
      </Paper>
    </Box>
  );
};
