import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';

import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Comment from './Comment';
const useStyles = makeStyles({
  items: {
    height: '310px',
    overflow: 'auto',
    border: '1px solid #c4c4c4',
  },
});

const CommentList = ({ comments, previousComments, onDelete }) => {
  const classes = useStyles();
  const latest = useRef(null);
  const [displayedComments, setDisplayedComments] = useState([]);

  const isLatest = (index) => {
    return index === displayedComments.length - 1;
  };

  useEffect(() => {
    latest.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [displayedComments]);

  useEffect(() => {
    const mergedComments = [...previousComments, ...comments];
    setDisplayedComments(mergedComments);
  }, [comments, previousComments]);

  return (
    <Stack className={classes.items}>
      <List>
        {displayedComments?.map((comment, i) => (
          <Comment
            key={comment.id}
            data={comment}
            onClickDelete={onDelete}
            carryOver={comment.carry_over}
            ref={isLatest(i) ? latest : null}
          />
        ))}
      </List>
    </Stack>
  );
};
export default CommentList;
