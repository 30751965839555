import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useFirmsCtx } from '../../../context';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import UserListItem from './UserListItem';
import UserListHeader from './UserListHeader';
import NoUsersMessage from './NoUsersMessage';
import { useReferences } from '../../../../../context/references';

const useStyles = makeStyles({
  container: {
    margin: '0 30px 0 30px',
    border: '1px solid #00000029',
    borderRadius: 5,
    maxHeight: 335,
  },
});

const UserList = ({ firm }) => {
  const classes = useStyles();
  const { connected_users: globalUsers } = useReferences();
  const { users: fetchedUsers, setUnassignedUsers } = useFirmsCtx();
  const users = fetchedUsers.length > 0 ? fetchedUsers : globalUsers;

  const rows = useMemo(
    () =>
      users?.flatMap((user) =>
        user.roles
          ?.filter((role) => role.firm_id === firm.id)
          .map((role) => ({
            label: user.label,
            role: role.name,
            id: user.id,
            role_id: role.id,
          }))
      ),
    [users, firm]
  );

  const noUsers = rows && rows.length === 0;

  useEffect(() => {
    const assignedUserIds = new Set(rows.map((row) => row.id));
    const unassigned = users.filter(
      (user) => !assignedUserIds.has(user.id) && user.status === 'active'
    );

    setUnassignedUsers(unassigned);
  }, [rows, users, setUnassignedUsers]);

  return (
    <div className={classes.container}>
      <TableContainer sx={{ maxHeight: 335 }}>
        <Table>
          {noUsers ? (
            <NoUsersMessage />
          ) : (
            <>
              <UserListHeader />
              <TableBody>
                {rows.map((row) => (
                  <UserListItem key={row.id} row={row} firm={firm} />
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserList;
