import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';

import { doAuthenticatedDelete } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS_DELETE_RULE } from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({rule, onSetRuleSuccess}) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onDelete = () => {

    if(window.confirm("Are you sure to delete this rule?")) {
      setSubmitting(true);

      const url = API_MANAGE_PROJECTS_DELETE_RULE;
      const data = { id: rule.id, task_id: rule.project_task_id };

      doAuthenticatedDelete(url, data, {
        general: onSuccessDelete,
        success: onSuccessDelete,
        error: onErrorDelete
      });
    }
  }

  const onSuccessDelete = (res) => {
    dispatch(
      projectActions.updateCategoryTask({task: res.task})
    );
    setSubmitting(false);
    onSetRuleSuccess(res.task);
  }

  const onErrorDelete = (res) => {
    alert('Please try again');
    setSubmitting(false);
  }

  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <p style={{marginRight: '20px'}}>{rule.rule}</p>
      <DeleteIcon onClick={onDelete}
      disabled={submitting}
      size="small" />
    </div>
  )

}
