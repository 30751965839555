import React, { useState, useEffect } from "react";
import FilterDropdown from "../../../board/components/shared/category/FilterDropdown";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { useReferences } from "../../../context/references";

const useStyles = makeStyles((_theme) => ({
  box: {
    display: "inline-block",
    marginRight: "10px",
    width: "305px",
  },
}));

export default ({ onChange }) => {
  const classes = useStyles();
  const { parent_categories, sub_categories } = useReferences();
  const [parentCategory, setParentCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);

  useEffect(() => {
    setSubCategoryOptions(sub_categories);
  }, [sub_categories]);

  const handleChangeCategory = (event, type) => {
    const new_value = event.target.value === "" ? null : event.target.value;
    let data = {};

    switch (type) {
      case "parentCategory":
        setParentCategory(new_value);

        if (new_value) {
          setSubCategory(null);
          setSubCategoryOptions(new_value.children);
          data["parent_task_category_id"] = new_value.id;
          data["task_category_id"] = "";
          onChange(data);
        }
        break;

      case "subCategory":
        setSubCategory(new_value);
        if (new_value) {
          setParentCategory(new_value.parent);
          data["parent_task_category_id"] = new_value.parent.id;
          data["task_category_id"] = new_value.id;
          onChange(data);
        }
        break;

      default:
        break;
    }
  };

  const onSelectNone = () => {
    const data = {
      parent_task_category_id: "",
      task_category_id: "",
    };
    setParentCategory(null);
    setSubCategory(null);
    setSubCategoryOptions(sub_categories);
    onChange(data);
  };

  return (
    <>
      <Box className={classes.box}>
        <FilterDropdown
          width="100%"
          label="Parent Category"
          value={parentCategory}
          onSelectNone={onSelectNone}
          onChange={(e) => handleChangeCategory(e, "parentCategory")}
          options={parent_categories ? parent_categories : []}
          noOptionsMessage="No Parent Categories"
        />
      </Box>
      <Box className={classes.box}>
        <FilterDropdown
          width="100%"
          label="Sub Category"
          value={subCategory}
          onChange={(e) => handleChangeCategory(e, "subCategory")}
          options={subCategoryOptions ? subCategoryOptions : []}
          noOptionsMessage="No Sub Categories"
        />
      </Box>
    </>
  );
};
