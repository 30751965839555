import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import AddTemplate from './add-template/AddTemplate';
import { useAccountingProjectTasks } from '../../../../../features/context/accounting_project_tasks';

const useStyles = makeStyles({
  container: {
    gap: 20,
    marginTop: 40,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  reset: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
  new: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const ActionsBar = ({
  firm,
  users,
  onExport,
  reloadData,
  topLevelCategories,
  subLevelCategories,
}) => {
  const classes = useStyles();
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const { toggleShowLocations } = useAccountingProjectTasks(); 

  const onReset = (type) => {
    console.log(type);
  };

  const onAddTemplate = () => {
    setShowAddTemplate(true);
  };

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        className={classes.reset}
        onClick={toggleShowLocations}
      >
        Toggle Locations
      </Button>
      {/* <Button
        variant="contained"
        className={classes.reset}
        onClick={() => onReset('PH')}
      >
        Reset Tasks From Today (PH)
      </Button>
      <Button
        variant="contained"
        className={classes.reset}
        onClick={() => onReset('US')}
      >
        Reset Tasks For Tomorrow (US)
      </Button> */}
      <Button
        variant="contained"
        className={classes.reset}
        onClick={onExport}
      >
        Export to CSV
      </Button>
      <Button
        variant="contained"
        className={classes.new}
        onClick={onAddTemplate}
      >
        Add Task Template
      </Button>

      {showAddTemplate && (
        <AddTemplate
          firm={firm}
          users={users}
          open={showAddTemplate}
          reloadData={reloadData}
          topLevelCategories={topLevelCategories}
          subLevelCategories={subLevelCategories}
          handleClose={() => setShowAddTemplate(false)}
        />
      )}
    </div>
  );
};

export default ActionsBar;
