import React, { useState } from "react"
import { API_HOST } from '../../const/site'
import { useSelector } from 'react-redux';

import {
  Avatar,
  Stack,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import SinglePhotoUpload from '../../shared/components/upload_photo/single_inline';
import { API_PROFILE_PIC } from '../../const/api_paths'
import { useReferences } from "../context/references";

export default ({ modeSwither }) => {
  // const myself = useSelector(state => state.ref.myself);
  const { myself } = useReferences();

  const onCancel = () => {
    // do nothing
  }

  const afterSave = () => {
    alert(`Successfully uploaded your profile pic.`)
    window.location = window.location.href
  }

  return (
    <Card>
      <CardHeader title="Change Profile Pic" />
      <CardContent>
        <Stack direction="row" spacing={2}>
            <Avatar
              alt={myself.name}
              src={myself.profile_url}
              sx={{ width: 200, height: 200 }}
            />
            <SinglePhotoUpload
              url={`${API_HOST}${API_PROFILE_PIC}`}
              afterSave={afterSave}
              onCancel={onCancel}
            />
        </Stack>
      </CardContent>
    </Card>
  )
}