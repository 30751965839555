import React from 'react';
import _ from 'lodash';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { useReferences } from '../../../../../../context/references';

export default ({task}) => {
  const { organizations } = useReferences();

  const getFirm = (firm_id) => {
    if(!firm_id) { return null }
    return _.find(organizations, {id: task.firm_id});
  }

  const getAccount = (firm_id, account_id) => {
    const firm = getFirm(firm_id);
    if(!firm) { return null }

    return _.find(firm.children, {id: account_id});
  }

  const getLocation = (firm_id, account_id, location_id) => {
    const account = getAccount(firm_id, account_id);
    if(!account) { return null }

    return _.find(account.children, {id: location_id});
  }

  const level1 = () => {
    const firm = getFirm(task.firm_id);
    if(!firm) { return null }
    return levelDisplay(firm);
  }

  const level2 = () => {
    const account = getAccount(task.firm_id, task.account_id);
    if(!account) { return null }
    return levelDisplay(account);
  }

  const level3 = () => {
    const location = getLocation(task.firm_id, task.account_id, task.location_id);
    if(!location) { return null }
    return levelDisplay(location);
  }

  const levelDisplay = (level) => {
    return(
      <Chip
        avatar={<Avatar alt={level.name} src={level.avatar} />}
        label={level.name}
        variant="outlined"
        style={{marginRight: '3px'}}
      />
    )
  }

  return(
    <div>
      {level1()}
      {level2()}
      {level3()}
    </div>
  )

}
