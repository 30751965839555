import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import AddTaskIcon from "@mui/icons-material/AddTask";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default ({ data, onApproveClick, onDeclineClick }) => {
  const classes = useStyles();
  const showApproveButton = ["pending", "declined"].includes(data["status"]);
  const showDeclineButton = data["status"] === "pending";

  return (
    <>
      {showApproveButton && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddTaskIcon />}
          className={classes.button}
          onClick={() => onApproveClick()}
        >
          Approve
        </Button>
      )}
      {showDeclineButton && (
        <Button
          variant="contained"
          color="error"
          size="small"
          startIcon={<CloseIcon />}
          className={classes.button}
          onClick={() => onDeclineClick()}
        >
          Decline
        </Button>
      )}
    </>
  );
};
