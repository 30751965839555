import React, { useEffect, useState } from "react";
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import {
  Box,
  TextField,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'inline-block',
    marginRight: '2px',
  },
  input: {
    marginRight: '10px',
  }
}));

const name = 'start_date';
const title = 'Start Date';

export default ({default_value, read_mode, onChange}) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(formattedDefaultValue())
  }, []);

  const onChangeLocal = (e) => {
    const target_value = e.target.value;
    setValue(target_value);
    onChange(sendBackData(target_value));
  }

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  }

  const formattedDefaultValue = () => {
    if(default_value) {
      return moment(default_value).format('YYYY-MM-DD');
    } else {
      return null;
    }
  }

  return(
    <Box className={classes.box}>
      <TextField
        disabled={read_mode}
        label={title}
        value={value || ''}
        type="date"
        size="small"
        variant="outlined"
        className={classes.input}
        onChange={onChangeLocal}
        InputLabelProps={{shrink: true}}
      />
    </Box>
  )

}
