import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: props.height,
  }),
});

const ErrorMessage = ({ message, height = 400 }) => {
  const classes = useStyles({ height });

  return <div className={classes.container}>{message}</div>;
};

export default ErrorMessage;
