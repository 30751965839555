import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    padding: '30px 20px 0 20px',
  },
  cancel: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
  save: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const Footer = ({
  onSave,
  onCancel,
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
}) => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Button variant="contained" className={classes.cancel} onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button variant="contained" className={classes.save} onClick={onSave}>
        {saveLabel}
      </Button>
    </div>
  );
};

export default Footer;
