import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteUserButton from '../DeleteUserButton';

const UserListItem = ({ row, firm }) => {
  return (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        {row.label}
      </TableCell>
      <TableCell align="left">{row.role}</TableCell>
      <TableCell align="right">
        <DeleteUserButton firm={firm} user={row} />
      </TableCell>
    </TableRow>
  );
};

export default UserListItem;
