import React from 'react';
import _ from 'lodash';
import DisplayString from "./display_string";
import DisplayStatus from "./display_status";
import DisplayAutocomplete from "./display_autocomplete";
import DisplayUserRoles from "./display_user_roles";
import DisplayProfilePic from "./display_profile_pic";
import DisplaySelect from "./display_select";
import DisplayMultiSelect from "./display_multiselect";

export default ({columns, column, data}) => {
  const column_type = _.find(columns, {'column': column}).type;

  if(column_type === 'status') {
    return(<DisplayStatus {...{columns, column, data}} />)
  } else if(column_type === 'autocomplete') {
    return(<DisplayAutocomplete {...{columns, column, data}} />)
  } else if(column_type === 'user_roles') {
    return(<DisplayUserRoles {...{columns, column, data}} />)
  } else if(column_type === 'profile_pic') {
    return(<DisplayProfilePic {...{columns, column, data}} />)
  } else if(column_type === 'select') {
    return(<DisplaySelect {...{columns, column, data}} />)
  } else if(column_type === 'multiselect') {
    return(<DisplayMultiSelect {...{columns, column, data}} />)
  } else {
    return(<DisplayString {...{columns, column, data}} />)
  }

}