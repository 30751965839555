import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { API_PATH, ACCOUNTING_TIME_VARIANCE_REPORT } from '../../../const/api_paths';
import { doAuthenticatedGet } from '../../../actions/_methods';
import { SpinnerWithBackdrop } from '../../../shared/components/utils/_spinner';
import FilterBar from './FilterBar';
import Report from './report/Report';
import ErrorMessage from '../data_reports/report/ErrorMessage';
import { Box, CardContent, Card, CardHeader } from '@mui/material';
import { useReferences } from "../../context/references";

const useStyles = makeStyles({
  card: {
    marginTop: '10px',
    border: '1px solid #00000029',
  },
});

const headerStyles = {
  color: '#23282C',
  fontWeight: 500,
};

const AccountingTimeVarianceReport = () => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [firms, setFirms] = useState([]);
  const [range, setRange] = useState(null);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firmID, setFirmID] = useState(null);
  const [noData, setNoData] = useState(false);
  const [accountID, setAccountID] = useState(null);
  const [locationID, setLocationID] = useState(null);
  const [aboveEstimate, setAboveEstimate] = useState(null);
  const [belowEstimate, setBelowEstimate] = useState(null);
  const isRequiredFieldsMissing = firmID === null || date === null;
  const { organizations, loadOrganizations } = useReferences();

  useEffect(() => {
    loadOrganizations()
  }, []);

  useEffect(() => {
    setError(null);
    setResults([]);
  }, [firmID]);

  useEffect(() => {
    setFirms(organizations);
  }, [organizations]);

  const generateReport = () => {
    setLoading(true);
    setError(null);
    setResults([]);

    doAuthenticatedGet(
      ACCOUNTING_TIME_VARIANCE_REPORT,
      {
        date: date,
        firm_id: firmID,
        ...(accountID ? { account_id: accountID } : {}),
        ...(locationID ? { location_id: locationID } : {}),
        ...(aboveEstimate ? { above_estimate: aboveEstimate } : {}),
        ...(belowEstimate ? { below_estimate: belowEstimate } : {}),
      },
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const downloadReport = () => {
    const queryParams = new URLSearchParams({
      date: date,
      firm_id: firmID,
      ...(accountID ? { account_id: accountID } : {}),
      ...(locationID ? { location_id: locationID } : {}),
      ...(aboveEstimate ? { above_estimate: aboveEstimate } : {}),
      ...(belowEstimate ? { below_estimate: belowEstimate } : {}),
    }).toString();
    const endpoint = API_PATH(`${ACCOUNTING_TIME_VARIANCE_REPORT}.csv?${queryParams}`);

    window.location = endpoint;
  };

  const onSuccess = (response) => {
    setResults(response.data);
    setNoData(response?.data.length === 0);
    setLoading(false);
  };

  const onError = (response) => {
    setError(response.error);
    setLoading(false);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Accounting Time Variance Report"
        titleTypographyProps={headerStyles}
      />
      <CardContent>
        <Box>
          <FilterBar
            date={date}
            firms={firms}
            results={results}
            setDate={setDate}
            setRange={setRange}
            setError={setError}
            setFirmID={setFirmID}
            setResults={setResults}
            showEstimateFilters={true}
            setAccountID={setAccountID}
            setLocationID={setLocationID}
            generateReport={generateReport}
            downloadReport={downloadReport}
            setAboveEstimate={setAboveEstimate}
            setBelowEstimate={setBelowEstimate}
            isRequiredFieldsMissing={isRequiredFieldsMissing}
          />
          {loading ? (
            <SpinnerWithBackdrop />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : results.length > 0 ? (
            <Report results={results} range={range} />
          ) : isRequiredFieldsMissing ? (
            <ErrorMessage message="Please select a firm and date range." />
          ) : noData ? (
            <ErrorMessage message="No results." />
          ) : (
            <ErrorMessage message="You can either generate the report now, or select an account and (optional) location, for a more focused report." />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountingTimeVarianceReport;
