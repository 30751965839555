import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import {
  Box,
  Paper,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';

import {
  KeyboardArrowDown,
  PlayCircleFilled,
  PauseCircle,
  Warning,
  CheckCircle,
} from '@mui/icons-material';

import {
  statusColor,
  statusLabel
} from '../../../../../utils/task_status';

const THEMED_STYLES = ({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  }
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
    transformOrigin={{vertical: 'top', horizontal: 'right'}}
    {...props}
  />
))(THEMED_STYLES);

const useStyles = makeStyles((theme) => ({
  box: {
    width: '140px',
    marginRight: '2px',
  },
  paper: {
    padding: '10px 5px',
    minHeight: '44px',
    height: '100%',
    textAlign: 'center',
  },
}));

const OPTIONS = [
  {label: 'Start',      value: 'on_going',    icon: <PlayCircleFilled color="primary" />},
  {label: 'Pause',      value: 'paused',      icon: <PauseCircle />},
  {label: 'Stucked',    value: 'stucked',     icon: <Warning />},
  {label: 'Completed',  value: 'completed',   icon: <CheckCircle />},
];

export default ({task, field, onChange}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [local_task, setLocalTask] = useState({...task});
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    let new_data = {};
    new_data[field] = option.value;
    onChange(new_data);

    local_task.status = option.value;
    setLocalTask(local_task);
    handleClose();
  };

  return (
    <Box className={classes.box} >
      <Paper square className={classes.paper}>
        <Button
          size="small"
          id="demo-customized-button"
          aria-controls="demo-customized-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
          style={statusColor(local_task[field])}
        >
          {statusLabel(local_task[field])}
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{'aria-labelledby': 'demo-customized-button'}}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {OPTIONS.map(option => {
            return(
              <MenuItem
                key={`status_item_${Math.random()}`}
                onClick={() => handleSelectOption(option)}
                disableRipple
              >
                {option.icon}
                {option.label}
              </MenuItem>
            )
          })}
        </StyledMenu>
      </Paper>
    </Box>
  );
}