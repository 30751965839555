import React, { useState } from "react";
import _ from "lodash";
import Filters from "./filters";

import Table from "./table";
import { MODEL_DEF } from "../model_def";
import { makeStyles } from "@mui/styles";
import { STYLES } from "../../../../features/board/const/styles";

const useStyles = makeStyles((theme) => STYLES(theme));

export default ({ firms }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState({});

  const modelDefFilters = () => {
    return Object.assign(MODEL_DEF, { filters: filters });
  };

  return (
    <div>
      <Filters
        classes={classes}
        firms={firms}
        onChange={(data) => setFilters(data)}
      />
      <Table {...modelDefFilters()} />
    </div>
  );
};
