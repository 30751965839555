import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import Stack          from '@mui/material/Stack';
import List           from '@mui/material/List';
import ListItem       from '@mui/material/ListItem';
import ListItemText   from '@mui/material/ListItemText';
import Paper          from '@mui/material/Paper';
import Linkify        from 'react-linkify';

import { API_TASK_RULES }  from '../../../../../../../const/api_paths';
import { doAuthenticatedGet } from "../../../../../../../actions/_methods";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%'
  },
  box: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '2px',
  },
  item: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  items: {
    maxHeight: '200px',
    overflow: 'auto',
    border: '1px solid #ffffff3b',
  },
}));

export default ({task}) => {
  const classes = useStyles();
  const [rules, setRules] = useState([]);

  useEffect(() =>  {
    loadRules();
  },[])

  const apiEndpoint = () => {
    return API_TASK_RULES.replace(':task_id', task.id);
  }

  const loadRules = () => {
    doAuthenticatedGet(apiEndpoint(), {}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onSuccess = (res) => {
    setRules(res.rules);
  }

  const onError = (res) => {
    console.log('Error loading rules');
  }

  const RuleList = () => (
    <Stack>
      <List className={classes.items}>
        {rules.map(f =>
          <ListItem className={classes.attachment_item}>
          <ListItemText
            primary={
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                  </a>
                )}
              >
                {f}
              </Linkify>
            }
            primaryTypographyProps={{fontSize: '12px'}}
          />
        </ListItem>,
        )}
      </List>
    </Stack>
  )

  if(rules.length > 0) {
    return(
      <Paper elevation={4} className={classes.wrapper}>
        <Stack className={classes.box}>
         
            <RuleList />
        </Stack>
      </Paper>
    )
  } else {
    return null;
  }

}