import React from 'react';
import { useGlobal } from '../../../context/global';
import { IconButton } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { REPORTS_UNASSIGN_USER } from '../../../../const/api_paths';
import { doAuthenticatedDelete } from '../../../../actions/_methods';
import { useReportsCtx } from '../../context';

const DeleteUserButton = ({ report, user }) => {
  const { prompt } = useGlobal();
  const { getUsers, setIsUsersLoading } = useReportsCtx();

  const unassignUser = () => {
    const confirmed = window.confirm(
      `Are you sure you want to stop sharing the report to this user?`
    );
    
    if (confirmed) {
      setIsUsersLoading(true);

      doAuthenticatedDelete(
        REPORTS_UNASSIGN_USER,
        {
          report_id: report.id,
          user_id: user.value
        },
        {
          general: onUnassignSuccess,
          success: onUnassignSuccess,
          error: onUnassignError,
        }
      );
    }
  };

  const onUnassignSuccess = () => {
    const message = `Successfully removed ${user.label} to ${report.name}`;

    getUsers();
    prompt(message);
  };

  const onUnassignError = () => {
    const message = 'Something went wrong. Please try again';

    prompt(message, { variant: 'error' });
  };

  return (
    <IconButton color="error" onClick={unassignUser} aria-label="settings">
      <RemoveCircleIcon fontSize="small" />
    </IconButton>
  );
};

export default DeleteUserButton;
