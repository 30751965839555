import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
  },
}));

const NoResults = () => {
  const classes = useStyles();

  return <Box className={classes.container}>No results were found.</Box>;
};

export default NoResults;
