import React from 'react';
import { Typography } from '@mui/material';
import DataTable from '../../../../shared/components/data_table';
import { MODEL_DEF } from '../model_def';

const STYLES = {
  header: {
    marginBottom: "25px",
  }
};

export default () => {
  return(
    <div>
      <Typography variant="h5" style={ STYLES.header }>
        Manage Categories
      </Typography>
      <DataTable {...MODEL_DEF} />
    </div>
  );
};
