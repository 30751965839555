import React, { useEffect, useState } from 'react';
import _ from 'lodash';

export default ({columns, column, data}) => {
  const [value, setValue] = useState('');
  const column_def  = _.find(columns, {'column': column});
  const options     = column_def.options;

  useEffect(() => {
    if (data[column]) {
      var labels = data[column].map(v => v.label);

      setValue(labels.join(', '))
    }
  }, [data[column]]);

  return (
    <span>{value}</span>
  )
}