import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TaskWrapper from './_tasks/variants/_default/_TaskWrapper';

import { previewNotificationActions } from '../../../store/preview_notification_slice';
import { API_MANAGE_FIRM_PREFERENCES } from '../../../const/api_paths';
import { doAuthenticatedGet } from '../../../actions/_methods';

export default () => {
  const open = useSelector((state) => state.preview_notification.open);
  const loading = useSelector((state) => state.preview_notification.loading);
  const data = useSelector((state) => state.preview_notification.data);
  const [preferences, setPreferences] = useState({});

  const dispatch = useDispatch();

  const onClosePreview = () => {
    dispatch(previewNotificationActions.setOpen(false));
    dispatch(
      previewNotificationActions.setNotification({ notification: {}, data: {} })
    );
  };

  const preferencesEndpoint = () => {
    return API_MANAGE_FIRM_PREFERENCES.replace(':firm_id', data.firm_id);
  };

  const loadPreferences = () => {
    doAuthenticatedGet(
      preferencesEndpoint(),
      {},
      {
        success: (res) => setPreferences(res.preferences),
        general: (res) => setPreferences(res.preferences),
        error: (res) => console.error(res),
      }
    );
  };

  useEffect(() => {
    if (open && data && Object.keys(data).length > 0) {
      loadPreferences();
    }
  }, [open, data]);

  if (open) {
    return (
      <div>
        {loading && <div>Loading</div>}
        {!loading && (
          <div style={{ marginBottom: '100px' }}>
            <TaskWrapper task={data} is_open={true} preferences={preferences} />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={onClosePreview}
              >
                Close Preview
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};
