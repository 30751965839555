import React, { useState, useEffect } from "react";
import _ from 'lodash';
import {
  Button,
  Paper,
  Box,
  TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { doAuthenticatedPost } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS_SET_EMAIL_SENDERS } from '../../../../../const/api_paths';

export default ({ task }) => {
  const [selecting, setSelecting] = useState(false);
  const [value, setValue] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setValue(task.email_senders);
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  const onSubmit = (event) => {
    const url = API_MANAGE_PROJECTS_SET_EMAIL_SENDERS.replace(':firm_id', task.firm_id);
    const data = { task_id: task.id, email_senders: value };

    closeSnackbar();
    setSubmitting(true);

    doAuthenticatedPost(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  }

  const onSuccess = (res) => {
    setSubmitting(false);
    enqueueSnackbar('You have successfully UPDATED email senders.', {variant: 'success'});
    setSelecting(false);
  }

  const onError = (res) => {
    setSubmitting(false);
    enqueueSnackbar('Please try again', {variant: 'error'});
    console.log(res)
  }

  return (
    <>
      <Button onClick={() => setSelecting(!selecting)}>Set Email Senders</Button>
      {selecting && <>
        <Box style={{ width: '100%', marginBottom: "15px" }}>
          <Paper elevation={3} style={{ width: '100%', padding: "15px 20px" }}>
            <div style={{display: 'flex'}}>  
              <TextField
                id="email-senders"
                labelId="select-email-senders-label"
                label="Email Senders (separated by commas)"
                variant="outlined"
                value={value}
                onChange={handleChange}
                style={{ width: '100%' }}
              />
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={submitting}
                style={{marginLeft: '10px'}}
              >Save</Button>
            </div>
          </Paper>
        </Box>
      </>}
    </>
  )

}
