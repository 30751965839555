import React, { useState, useEffect } from "react";
import { Box, Button, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DescriptionIcon from "@mui/icons-material/Description";
import _ from "lodash";

import { modalStyles } from "../../../../shared/styles/modal";
import FormattedDescription from "../../../board/components/_tasks/variants/_default/show/_Description";

const useStyles = makeStyles(() => ({
  ...modalStyles,
  icon: {
    cursor: "pointer",
  },
  box: {
    ...modalStyles.box,
    minWidth: 600,
  },
}));

export default ({ result }) => {
  const classes = useStyles();
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  const handleClose = () => {
    setShowDescriptionModal(false);
  };

  if (!result.description) {
    return null;
  }

  return (
    <>
      <DescriptionIcon
        color={"primary"}
        className={classes.icon}
        onClick={() => setShowDescriptionModal(true)}
      />
      <Modal open={showDescriptionModal}>
        <Box className={classes.box}>
          <div className={classes.header}>{`Task ID: ${result.task_id}`}</div>
          <div className={classes.title}>
            <FormattedDescription
              task_description={result.description}
              minHeight={"100px"}
            />
          </div>
          <div className={classes.container}>
            <Button
              variant="contained"
              className={classes.cancel}
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
