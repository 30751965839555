import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FilterDropdown from './FilterDropdown';
import FilterFrequency from './FilterFrequency';
import InputPeople from '../../../../board/components/_basic_filters/InputPeople';
import EstimatedHours from './EstimatedHours';

const useStyles = makeStyles({
  wrapper: {
    gap: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    gap: 20,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 16,
    color: '#302e2e',
  },
});

const Filters = ({
  firm,
  users,
  account,
  location,
  minEstHrs,
  maxEstHrs,
  setAccount,
  setLocation,
  subCategory,
  setMinEstHrs,
  setMaxEstHrs,
  assignedUsers,
  setSubCategory,
  parentCategory,
  setSelectedWeek,
  setAssignedUsers,
  setParentCategory,
  topLevelCategories,
  subLevelCategories,
  selectedFrequencies,
  setSelectedFrequencies,
}) => {
  const classes = useStyles();
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);

  const handleChange = (event, type) => {
    const value = event.target.value === '' ? null : event.target.value;

    switch (type) {
      case 'account':
        setAccount(value);
        setLocation(null);
        break;

      case 'location':
        setLocation(value);
        break;

      case 'parentCategory':
        setSubCategory(null);
        setParentCategory(value);
        if (value) setSubCategoryOptions(value.children);
        break;

      case 'subCategory':
        setSubCategory(value);
        if (value) setParentCategory(value.parent);
        break;

      default:
        break;
    }
  };

  const onChangeUsers = (value) => {
    const ids = value.user_ids;

    setAssignedUsers(ids);
  };

  const onSelectNone = () => {
    setParentCategory(null);
    setSubCategory(null);
    setSubCategoryOptions(subLevelCategories);
  };

  useEffect(() => {
    setSubCategoryOptions(subLevelCategories);
  }, [subLevelCategories]);

  return (
    <div className={classes.wrapper}>
      {/* <span className={classes.title}>Filters</span> */}

      <div className={classes.container}>
        <FilterDropdown
          width="25%"
          label="Account"
          value={account}
          onChange={(e) => handleChange(e, 'account')}
          options={firm ? firm.children : []}
          noOptionsMessage="No Accounts"
        />

        <FilterDropdown
          width="25%"
          label="Location"
          value={location}
          onChange={(e) => handleChange(e, 'location')}
          options={account ? account.children : []}
          disabled={!account}
          noOptionsMessage="No Locations"
        />

        <FilterDropdown
          width="25%"
          value={parentCategory}
          label="Parent Category"
          onSelectNone={onSelectNone}
          noOptionsMessage="No Parent Categories"
          onChange={(e) => handleChange(e, 'parentCategory')}
          options={topLevelCategories ? topLevelCategories : []}
        />

        <FilterDropdown
          width="25%"
          label="Sub-Category"
          value={subCategory}
          onChange={(e) => handleChange(e, 'subCategory')}
          options={subCategoryOptions ? subCategoryOptions : []}
          noOptionsMessage="No Sub Categories"
        />
      </div>

      <div className={classes.container}>
        <InputPeople
          width="35%"
          name="user_ids"
          title="Assigned Users"
          options={users ? users : []}
          onChange={onChangeUsers}
          default_value={assignedUsers}
        />

        <FilterFrequency
          width="35%"
          setSelectedWeek={setSelectedWeek}
          selectedFrequencies={selectedFrequencies}
          setSelectedFrequencies={setSelectedFrequencies}
        />

        <EstimatedHours
          estimate={minEstHrs}
          label="Min. Estimated Hrs"
          width="15%"
          setInput={setMinEstHrs}
        />
        <EstimatedHours
          estimate={maxEstHrs}
          label="Max. Estimated Hrs"
          width="15%"
          setInput={setMaxEstHrs}
        />
      </div>
    </div>
  );
};

export default Filters;
