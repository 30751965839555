import React from 'react';
import { makeStyles } from '@mui/styles';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  spinner_wrapper: (props) => ({
    display: 'flex',
    width: '100%',
    height: props.height ? props.height : '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
});

const Spinner = ({ height }) => {
  const classes = useStyles({ height });

  return (
    <div className={classes.spinner_wrapper}>
      <CircularProgress color="primary" />
    </div>
  );
};

const SpinnerWithBackdrop = () => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export { Spinner, SpinnerWithBackdrop };
