import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  IconButton,
} from '@mui/material';
import { red } from '@mui/material/colors';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CommentSection from './info/CommentSection';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '35%',
    marginLeft: '1px',
    marginBottom: '20px',
  },
  box: {
    height: '95%',
  },
  inner_box: {
    display: 'flex',
    height: '100%',
  },
  expand_less: {
    paddingBottom: '0px',
  },
}));

export default ({ task, onHide, previousComments }) => {
  const classes = useStyles();

  const [data, setData] = useState({});

  useEffect(() => {
    setData(task);
  }, [task]);

  const onChange = (new_data) => {
    setData({ ...data, ...new_data });
  };

  return (
    <Card className={classes.wrapper}>
      <CardHeader
        className={classes.expand_less}
        action={
          <IconButton aria-label="settings">
            <ExpandLessIcon fontSize="large" onClick={onHide} />
          </IconButton>
        }
      />
      <CardContent className={classes.box}>
        <Box className={classes.inner_box}>
          <CommentSection
            read_mode={false}
            task={task}
            default_comments={task.comments}
            previousComments={previousComments}
            onChange={onChange}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
