import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Chip,
} from '@mui/material';
import CellActions from './CellActions';
import Scope from './_scope';
import AssignedUsers from './_assigned_users';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const columnWidths = [
  '3%', // Checkbox
  '18%', // Parent Category
  '18%', // Sub Category
  '15%', // Location
  '15%', // Assigned Users
  '13%', // Frequency
  '6%', // Estimated Hours
  '6%', // Earliest Start
  '6%', // Actions
];

const DataTable = ({
  firm,
  rows,
  users,
  reloadData,
  topLevelCategories,
  subLevelCategories,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((selectedId) => selectedId !== id);
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              padding="checkbox"
              align="center"
              valign='top'
              style={{ width: columnWidths[0] }}
            >
              <Checkbox
                indeterminate={
                  selected.length > 0 && selected.length < rows.length
                }
                checked={rows.length > 0 && selected.length === rows.length}
                onChange={handleSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
            <TableCell style={{ width: columnWidths[1] }}>
              Parent Category
            </TableCell>
            <TableCell style={{ width: columnWidths[2] }}>
              Sub-category
            </TableCell>
            <TableCell style={{ width: columnWidths[3] }}>Location(s)</TableCell>
            <TableCell style={{ width: columnWidths[4] }}>
              Assigned Users
            </TableCell>
            <TableCell style={{ width: columnWidths[5] }}>Frequency</TableCell>
            <TableCell style={{ width: columnWidths[6] }}>Est Hrs</TableCell>
            <TableCell style={{ width: columnWidths[7] }}>
              Earliest Start
            </TableCell>
            <TableCell style={{ width: columnWidths[8] }} />{' '}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell
                  padding="checkbox"
                  align="center"
                  style={{ width: columnWidths[0], verticalAlign: 'top' }}
                >
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                  &nbsp;
                  <Chip
                    label={'#' + row.id}
                    size="small"
                    color="success"
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  style={{ width: columnWidths[1], verticalAlign: 'top' }}
                >
                  {row.parent_category.name || 'N/A'}
                </TableCell>
                <TableCell style={{ width: columnWidths[2], verticalAlign: 'top' }}>
                  {row.sub_category.name || 'N/A'}
                </TableCell>
                <TableCell style={{ width: columnWidths[4], verticalAlign: 'top' }}>
                  <Scope {...row} />
                </TableCell>
                <TableCell style={{ width: columnWidths[3], verticalAlign: 'top' }}>
                  <AssignedUsers {...row} />
                </TableCell>
                <TableCell style={{ width: columnWidths[5], verticalAlign: 'top' }}>
                  {row.frequency
                    ? row.frequency
                        .map((frequency) => frequency.name)
                        .join(', ')
                    : 'N/A'}
                </TableCell>
                <TableCell style={{ width: columnWidths[6], verticalAlign: 'top' }}>
                  {row.estimated_hours || 'N/A'}
                </TableCell>
                <TableCell style={{ width: columnWidths[7], verticalAlign: 'top' }}>
                  {row.earliest_time_to_start || 'N/A'}
                </TableCell>
                <TableCell
                  className={classes.actions}
                  onClick={(e) => e.stopPropagation()}
                  style={{ verticalAlign: 'top' }}
                >
                  <CellActions
                    firm={firm}
                    task={row}
                    users={users}
                    reloadData={reloadData}
                    style={{ width: columnWidths[8] }}
                    topLevelCategories={topLevelCategories}
                    subLevelCategories={subLevelCategories}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
