import { set } from 'lodash';
import React, { createContext, useContext, useState } from 'react';

const AccountingProjectTasks = createContext();

const useAccountingProjectTasks = () => {
  const context = useContext(AccountingProjectTasks);

  if (!context) {
    throw new Error(`useAccountingProjectTasks must be used within a FirmTasksContext`);
  }
  return context;
};

const AccountingProjectTasksCtxProvider = ({ children }) => {
  const [show_locations, setShowLocations] = useState(false);

  const toggleShowLocations = () => {
    setShowLocations(!show_locations);
  }


  const value = {
    show_locations,
    setShowLocations,
    toggleShowLocations
  };

  return <AccountingProjectTasks.Provider value={value}>{children}</AccountingProjectTasks.Provider>;
};

export { AccountingProjectTasks, AccountingProjectTasksCtxProvider, useAccountingProjectTasks };
