import React from 'react';
import { makeStyles } from '@mui/styles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LoadComments from './LoadComments';

const useStyles = makeStyles({
  header_wrapper: {
    padding: 0,
    marginBottom: '10px',
  },
  header: {
    padding: 0,
  },
});

const CommentHeader = ({ task, setComments }) => {
  const classes = useStyles();

  return (
    <List className={classes.header_wrapper}>
      <ListItem
        className={classes.header}
        secondaryAction={
          <LoadComments task={task} onSubmit={(res) => setComments(res)} />
        }
      >
        <ListItemText primary="COMMENTS" />
      </ListItem>
    </List>
  );
};

export default CommentHeader;
