import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {
  TextField,
  Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: '250px',
    marginRight: '10px',
  },
  option_style: {
    display: 'flex',
    fontSize: '11pt',
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #555',
    marginBottom: '0',
  }
}));

export default ({columns, column, data, onChange}) => {
  const classes     = useStyles();
  const column_def  = _.find(columns, {'column': column});
  const label       = column_def.header;
  const options     = column_def.options;

  const [value, setValue] = useState('');
  const [defVal, setDefVal] = useState({ value: '', label: '' });

  useEffect(() => {
    const default_value = _.find(options, {'value': data[column]});

    if (default_value === undefined) {
      setDefVal({ 'value': '', label: '' })
      setValue({ 'value': '', label: '' })
    } else {
      setDefVal(default_value)
      setValue(default_value)
    }
    
  }, [data]);

  const onChangeLocal = (e, target_value) => {
    let new_data = {}
    let new_val = target_value ? target_value.value : ''
    let new_def_val = _.find(options, { 'value': new_val });
    new_data[column] = new_val
    
    setValue(new_def_val);
    setDefVal(new_def_val);
    onChange(new_data);
  };

  const OptionComponent = ({option, props}) => (
    <div className={classes.option_style} {...props}>
      {option.label}
    </div>
  );

  const InputComponent = ({params, label}) => (
    <TextField
      {...params}
      label={label}
      variant="outlined"
    />
  );

  return(
    <Autocomplete
      size="small"
      value={value}
      defaultValue={defVal}
      options={options}
      className={classes.input}
      onChange={onChangeLocal}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => option ? option.label : ''}
      renderOption={(props, option) => <OptionComponent {...{option, props}} />}
      renderInput={(params) => <InputComponent {...{params, label}} />}
    />
  )
}

