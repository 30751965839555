import React from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

const useStyles = makeStyles({
  input: (props) => ({
    flexBasis: props.width,
  }),
});

const FilterText = (name, label, value, onChange, width) => {
  const classes = useStyles({ width });

  return (
    <TextField
      size="small"
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      className={classes.input}
    />
  );
};

export default FilterText;
