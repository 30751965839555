import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Stack } from '@mui/material';
import Filters from './Filters';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: 40,
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    height: 40,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
});

const FilterBar = ({
  date,
  firms,
  results,
  setDate,
  setRange,
  setError,
  setFirmID,
  setResults,
  setAccountID,
  setLocationID,
  generateReport,
  downloadReport,
  setAboveEstimate,
  setBelowEstimate,
  isRequiredFieldsMissing,
  showEstimateFilters = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Filters
        date={date}
        firms={firms}
        results={results}
        setDate={setDate}
        setRange={setRange}
        setError={setError}
        setFirmID={setFirmID}
        setResults={setResults}
        setAccountID={setAccountID}
        setLocationID={setLocationID}
        setAboveEstimate={setAboveEstimate}
        setBelowEstimate={setBelowEstimate}
        showEstimateFilters={showEstimateFilters}
      />
      <Stack direction="row" spacing={2} className={classes.buttonsContainer}>
        <Button
          disabled={isRequiredFieldsMissing}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={generateReport}
        >
          Generate
        </Button>
        <Button
          disabled={isRequiredFieldsMissing}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={downloadReport}
        >
          Download
        </Button>
 
      </Stack>
    </div>
  );
};

export default FilterBar;
