import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Paper,
  Box,
  TextField,
  Stack,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';

import TaskDescription    from './_task_description';
import CategoryLocation   from './_category_location';
import CategoryFrequency  from './_category_frequency';
import CategoryUser       from './_category_user';
import CategoryRule       from './_category_rule';
import EmailRecipients    from './_email_recipients';
import EmailSenders       from './_email_senders';
import EstimatedHours     from './_estimated_hours';

import {
  API_MANAGE_PROJECTS_DELETE_TASK, 
  API_MANAGE_PROJECTS_UPDATE_TASK,
  API_MANAGE_PROJECTS_UPDATE_TASKS_AND_ASSOCIATIONS,
} from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';
import { doAuthenticatedDelete, doAuthenticatedPut } from '../../../../../actions/_methods';

export default ({task}) => {
  const [submitting, setSubmitting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updating_tasks, setUpdatingTasks] = useState(false);
  const [edit_mode, setEditMode] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setValue(task.name);
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const onSubmit = () => {
    const url = API_MANAGE_PROJECTS_UPDATE_TASK
    const data = { name: value, id: task.id };

    setUpdating(true);
    doAuthenticatedPut(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  };

  const resetTasksFromToday = () => {
    if(window.confirm('Are you sure you want to reset tasks from TODAY upto End of the Week?')) {
      const url = API_MANAGE_PROJECTS_UPDATE_TASKS_AND_ASSOCIATIONS
      const data = { id: task.id, from_date: 'today' };

      setUpdatingTasks(true);
      doAuthenticatedPut(url, data, {
        general: onSuccessResetFromToday,
        success: onSuccessResetFromToday,
        error: onError
      });
    }
  };

  const resetTasksFromTomorrow = () => {
    if(window.confirm('Are you sure you want to reset tasks from TOMORROW upto End of the Week?')) {
      const url = API_MANAGE_PROJECTS_UPDATE_TASKS_AND_ASSOCIATIONS
      const data = { id: task.id, from_date: 'tomorrow' };

      setUpdatingTasks(true);
      doAuthenticatedPut(url, data, {
        general: onSuccessResetFromTomorrow,
        success: onSuccessResetFromTomorrow,
        error: onError
      });
    }
  };

  const onSuccessResetFromToday = (res) => {
    setUpdatingTasks(false);
    enqueueSnackbar('You have successfully reset tasks from TODAY upto End of the Week.', {variant: 'success'});
  }

  const onSuccessResetFromTomorrow = (res) => {
    setUpdatingTasks(false);
    enqueueSnackbar('You have successfully reset tasks from TOMORROW upto End of the Week.', {variant: 'success'});
  }

  const onSuccess = (res) => {
    dispatch(projectActions.updateCategoryTask({task: res.task}));
    setUpdating(false);
    setEditMode(false);
    enqueueSnackbar('You have successfully updated the task.', {variant: 'success'});
  }

  const onError = (res) => {
    console.log(res);
    setUpdating(false);
  }

  const onDelete = () => {

    if(window.confirm("Are you sure to delete this task?")) {
      setSubmitting(true);

      const url = API_MANAGE_PROJECTS_DELETE_TASK;
      const data = { id: task.id };

      doAuthenticatedDelete(url, data, {
        general: onSuccessDelete,
        success: onSuccessDelete,
        error: onErrorDelete
      });
    }
  }

  const onEdit = () => {
    setEditMode(true);
  }

  const onSuccessDelete = (res) => {
    dispatch(
      projectActions.deleteCategoryTask({task: res.task})
    );
    setSubmitting(false);
    enqueueSnackbar('You have successfully deleted the task.', {variant: 'success'});
  }

  const onErrorDelete = (res) => {
    enqueueSnackbar('Please try again', {variant: 'error'});
    setSubmitting(false);
  }

  const TaskName = (
    <h3 style={{marginRight: '20px'}}>{task.name}</h3>
  )

  const TaskForm = (
    <>
      <TextField
        label="Task Name"
        variant="outlined"
        value={value}
        onChange={handleChange}
        disabled={updating}
        size="small"
        style={{flexGrow: '1'}}
      />
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={updating}
        style={{marginLeft: '10px'}}
      >Save</Button>
    </>
  )

  const ResetButtons = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        onClick={resetTasksFromToday}
        disabled={updating_tasks}
      >Reset tasks from Today (PH)
      </Button>
      <Button
        variant="contained"
        onClick={resetTasksFromTomorrow}
        disabled={updating_tasks}
      >Reset tasks from Tomorrow (US)
      </Button>
    </Stack>
  )

  return (
    <div>
      <Box style={{width: '100%', marginBottom: "15px"}}>
        <Paper elevation={3} style={{width: '100%', padding: "15px 20px"}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {edit_mode && TaskForm }
            {!edit_mode && TaskName }
            { !edit_mode && !submitting &&
              <div>
                <EditIcon onClick={onEdit} size="small" />
                <DeleteIcon onClick={onDelete} disabled={submitting} size="small" />
              </div>
            }
          </div>
          { ResetButtons }
          <br/><br/>
          <TaskDescription task={task} />
          <br/>
          <CategoryLocation task={task} />
          <br/>
          <CategoryFrequency task={task} />
          <br/>
          <CategoryUser task={task} />
          <br/>
          <CategoryRule task={task} />
          <br/>
          <EmailRecipients task={task} />
          <br/>
          <EmailSenders task={task} />
          <br/>
          <EstimatedHours task={task} />
        </Paper>
      </Box>
    </div>
  )
}
