import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Box,
  Button,
  Stack,
  CardContent,
  Card,
  Typography,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer
} from "@mui/material";

import { doAuthenticatedGet } from '../../../actions/_methods';
import { USER_PROFILE_REPORT } from '../../../const/api_paths'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '10px',
    border: '1px solid #9f9f9f',
  },
  buttons: {
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'flex-end',
  }
}));

export default ({ user_id }) => {
  const classes = useStyles();
  const [user, setUser] = useState({})
  const [data, setData] = useState([])
  const [selected_date, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const today = moment(selected_date).format('YYYY-MM-DD');
    loadUser(user_id, today)
  }, []);

  const handleSelect = (date) => {
    setSelectedDate(date)
    const formattedDate = moment(date).format('YYYY-MM-DD');
    loadUser(user_id, formattedDate)
  }

  const loadUser = (user_id, date) => {
    setLoading(true)
    var target_url = `${USER_PROFILE_REPORT}?id=${user_id}&date=${date}`

    doAuthenticatedGet(target_url, {}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    })
  }

  const onSuccess = (res) => {
    setLoading(false)
    setUser(res.user)
    setData(res.data)
  }

  const onError = (res) => {
    setLoading(false)
    alert('Ooops... Please try again...')
  }

  const logDisplay = ({date, logs, date_readable}) => {
    if (logs.length == 0) {
      return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableCell>{date_readable}</TableCell>
              <TableCell sx={{ width: "100px"}}>None</TableCell>
            </TableHead>
          </Table>
        </TableContainer>
      )
    } else {
      return (
        <div>
          <TableContainer component={Paper} elevation={5} variant="outlined" sx={{ marginBottom: "20px", marginTop: "20px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableCell colSpan={7}>{date_readable}</TableCell>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Task ID</TableCell>
                  <TableCell>Task</TableCell>
                  <TableCell align="right">Firm</TableCell>
                  <TableCell align="right">Account</TableCell>
                  <TableCell align="right">Location</TableCell>
                  <TableCell align="right">Minutes</TableCell>
                  <TableCell align="right">Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.firm}</TableCell>
                    <TableCell align="right">{row.account}</TableCell>
                    <TableCell align="right">{row.location}</TableCell>
                    <TableCell align="right">{row.total_minutes}</TableCell>
                    <TableCell align="right">{row.total_hours}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
    }
  }

  return (
    <Card className={classes.card}>
      {!loading && <CardHeader title={`${user.lastname}, ${user.firstname}`} />}
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Calendar
            date={selected_date}
            onChange={handleSelect}
          />
        </Box>
        {loading && <span>loading....</span>}
        {!loading && <>
          <Box><img src={user.profile_pic} /></Box>
        
          {data.map(d => { 
            return (
              <div>
                {logDisplay(d)}
              </div>
            )
          })}
        </>}
      </CardContent>
    </Card>
  )
}