import React from 'react';
import Chip from '@mui/material/Chip';
import {CheckCircle, Cancel} from '@mui/icons-material/';

export default ({column, data}) => {
  const status = data[column];

  if(status === 'active') {
    return <Chip icon={<CheckCircle />} label="Active" color="success" />
  } else if(status === 'inactive') {
    return <Chip icon={<Cancel />} label="Inactive" color="secondary" />
  } else {
    return <Chip label={status} color="primary" variant="outlined" />
  }
}