import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  anchor: {
    width: '0.1px',
    height: '0.1px',
    marginTop: '-100px',
    marginBottom: '100px',
  },
}));

const ScrollAnchor = () => {
  const classes = useStyles();

  return <div className={classes.anchor} id="scroll-anchor"></div>;
};

export default ScrollAnchor;
