import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import {
  Box,
  TextField,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '2px',
    width: '100%',
  },
  input: {
    width: '100%',
    marginRight: '10px',
  }
}));

const name = 'name';
const title = 'Task Name';

export default ({default_value, read_mode, onChange}) => {
  const classes = useStyles();
  const [value, setValue] = useState(default_value);

  const onChangeLocal = (e) => {
    const target_value = e.target.value;
    setValue(target_value);
    onChange(sendBackData(target_value));
  }

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  }

  return(
    <Box className={classes.box}>
      <TextField
        variant="outlined"
        size="small"
        className={classes.input}
        label={title}
        value={value}
        onChange={onChangeLocal}
        disabled={read_mode}
      />
    </Box>
  )
}