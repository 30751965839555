import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    margin: '10px 10px 40px 10px',
  },
  name: {
    color: '#1976d2',
  },
});

const Header = ({ firm }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h6" component="h2">
        Manage User(s): <span className={classes.name}>{firm.name}</span>
      </Typography>
    </div>
  );
};

export default Header;
