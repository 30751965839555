import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'flex-end',
    margin: '40px 10px 0 10px',
    padding: '16px 20px 0 0',
    borderTop: '1px solid #00000029',
  },
  cancel: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
});

const Footer = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Button
        variant="contained"
        className={classes.cancel}
        onClick={handleClose}
      >
        Close
      </Button>
    </div>
  );
};

export default Footer;
