import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  cell_large: {
    width: '300px',
  },
  cell: {
    width: '130px',
    textAlign: 'center'
  },
  date_range: {
    fontSize: '20px',
    marginLeft: '5px',
  }
}));

export default ({ results, date_range_str }) => {
  const classes = useStyles();
  const [local_results, setLocalResults] = useState([])

  useEffect(() => {
    setLocalResults(results);
  }, [results]);

  const findWorkHours = (data, day_name) => {
    var res = _.find(data, { day_name: day_name })
    return res.work_hours
  }
  
  return (
    <>
    <Typography gutterBottom variant="h6" component="div">
      {date_range_str}
    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 900 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell_large}>User</TableCell>
            <TableCell className={classes.cell}>Monday</TableCell>
            <TableCell className={classes.cell}>Tuesday</TableCell>
            <TableCell className={classes.cell}>Wednesday</TableCell>
            <TableCell className={classes.cell}>Thursday</TableCell>
            <TableCell className={classes.cell}>Friday</TableCell>
            <TableCell className={classes.cell}>Saturday</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {local_results.map((result, indx) => (
            <TableRow
              key={`tr_${result.indx}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row"><a href={`/app/reports/user/${result.user_id}`} target="_blank">{result.user}</a></TableCell>
              <TableCell className={classes.cell}>{findWorkHours(result.week, 'Monday')}</TableCell>
              <TableCell className={classes.cell}>{findWorkHours(result.week, 'Tuesday')}</TableCell>
              <TableCell className={classes.cell}>{findWorkHours(result.week, 'Wednesday')}</TableCell>
              <TableCell className={classes.cell}>{findWorkHours(result.week, 'Thursday')}</TableCell>
              <TableCell className={classes.cell}>{findWorkHours(result.week, 'Friday')}</TableCell>
              <TableCell className={classes.cell}>{findWorkHours(result.week, 'Saturday')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </>
  )
}