import React, { useEffect, useState } from "react";
import _ from 'lodash';
import {
  Box,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import Element from './micro_components/element';
import { STYLES } from './styles';

export default ({updatable_columns, columns, table_column_count, data, path, setMode, onUpdate}) => {
  const [local_data, setLocalData] = useState({...data});

  useEffect(() => {
    setLocalData({...data});
  }, [data]);

  const onSave = () => {
    onUpdate({...local_data})
    setLocalData({...local_data})
  }

  const onChange = (new_data) => {
    Object.keys(new_data).forEach(key => {
      local_data[key] = new_data[key];
    });
  }

  const SaveButton = () => (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      onClick={onSave}
      style={{marginRight: '10px'}}
    >Save</Button>
  )

  const CancelButton = () => (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={() => setMode('view')}
    >Cancel</Button>
  )

  const ElementRow = ({col_rows}) => (
    <Box style={STYLES.inner_box}>
      {col_rows.map(col => {
        return(
          <Element
            columns={columns}
            column={col}
            data={local_data}
            path={path}
            onChange={onChange}
          />
        )
      })}
    </Box>
  )

  const FormCard = () => (
    <Card style={STYLES.card_box}>
      <CardContent>
        {updatable_columns.map(cr => <ElementRow col_rows={cr} />)}
        <Box>
          <SaveButton />
          <CancelButton />
        </Box>
      </CardContent>
    </Card>
  )

  return (
    <tr>
      <td colSpan={table_column_count} style={STYLES.td_end}>
        <FormCard />
      </td>
    </tr>
  )
}
