import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import {
  Card,
  Box,
  List,
  Typography,
} from '@mui/material';

import CheckListRow from "./_ChecklistRow";

const useStyles = makeStyles((theme) => ({
  box: {
    marginRight: '2px',
    width: '100%',
  },
  list: {
    width: '100%',
  },
  card: {
    display: 'flex',
    marginBottom: '25px',
  },
  card_title: {
    paddingLeft: '10px',
    paddingBottom: '10px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
}));

const name = 'checklist';
const title = 'Checklist';

export default ({ task }) => {
  const classes = useStyles();
  const [checklist, setChecklist] = useState(task.checklist);

  const DataList = () => {
    return (
      <Card className={classes.card}>
        <List className={classes.list}>
          <Typography component="div" className={classes.card_title}>
            Checklist
          </Typography>
          {checklist.map((row) => (
            <CheckListRow row={row} />
          ))}
        </List>
      </Card>
    )
  }

  return (
    <Box className={classes.box}>
      {checklist.length > 0 && <DataList />}
    </Box>
  )
}