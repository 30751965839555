import React, { useState, useEffect } from "react";
import { Replay }     from '@mui/icons-material';
import LoadingButton  from '@mui/lab/LoadingButton';

import { API_TASK_COMMENTS }  from '../../../../../../../const/api_paths';
import { doAuthenticatedGet } from "../../../../../../../actions/_methods";

export default ({task, onSubmit}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadComment();
  }, []);

  const apiEndpoint = () => {
    return API_TASK_COMMENTS.replace(':task_id', task.id);
  }

  const loadComment = () => {
    setLoading(true);
    doAuthenticatedGet(apiEndpoint(), {}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onSuccess = (res) => {
    onSubmit(res);
    setLoading(false);
  }

  const onError = (res) => {
    alert("Please try again");
    setLoading(false);
  }

  return(
    <LoadingButton
      startIcon={<Replay />}
      loading={loading}
      variant="contained"
      onClick={loadComment}
      style={{marginRight: '-15px'}}
    ></LoadingButton>
  )

}