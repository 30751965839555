import React from 'react';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';

const useStyles = makeStyles({
  primary: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
  },
  id: {
    fontWeight: 500,
  },
});

const PrimaryText = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.primary}>
        <Link
          href={data.url}
          color="inherit"
          download={data.name}
          target="_blank"
          textDecoration="none"
        >
          {data.name}
        </Link>
        {data.task_id && (
          <div>
            Task ID: <span className={classes.id}>{data.task_id}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default PrimaryText;
