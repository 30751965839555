import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    height: 200,
    width: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    color: '#000000a8',
  },
});

const NoUsersMessage = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.message}>
        This report has not been shared to anyone.
      </span>
    </div>
  );
};

export default NoUsersMessage;
