import React from 'react';
import { makeStyles } from '@mui/styles';
import Toggle from './Toggle';

const useStyles = makeStyles({
  section: {
    paddingLeft: 14,
    paddingRight: 14,
    borderRadius: 5,
    border: '1px solid #00000029',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

const ToggleBar = ({ on, label, handleChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.content}>
        <span className={classes.title}>{label}</span>
        <Toggle on={on} handleChange={handleChange} />
      </div>
    </div>
  );
};

export default ToggleBar;
