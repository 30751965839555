import React from 'react';
import _ from 'lodash';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default ({columns, column, data, onChange}) => {
  const label = _.find(columns, {'column': column}).header;

  const onChangeLocal = (e) => {
    let new_data = {}
    new_data[column] = e.target.checked ? 'active' : 'inactive';
    onChange(new_data);
  }

  return(
    <FormControlLabel
      control={<Switch
        color="success"
        defaultChecked={data[column] === 'active'}
        onChange={onChangeLocal}
      />}
      label={label}
    />
  )
}
