import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Modal, Button } from '@mui/material';
import { useTaskCategory } from '../../context/task_categories';
import { TASK_CATEGORY } from '../../../const/api_paths';
import { doAuthenticatedDelete } from '../../../actions/_methods';
import { useGlobal } from '../../context/global';
import { modalStyles } from "../../../shared/styles/modal";

const useStyles = makeStyles(() => modalStyles);

const DeleteTaskCategory = ({ open, handleClose, applyExpandedState }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { currentNode, setLoading, setTaskCategories } = useTaskCategory();

  const endpoint = () => {
    return TASK_CATEGORY.replace(':id', currentNode.id);
  };

  const onDeleteCategory = () => {
    setLoading(true);
    doAuthenticatedDelete(
      endpoint(),
      {},
      {
        success: onDeleteSuccess,
        general: onDeleteSuccess,
        error: (res) => console.error(res),
      }
    );
  };

  const onDeleteSuccess = (res) => {
    const message = `Successfully deleted ${currentNode.name}`;
    prompt(message);

    setTaskCategories(res.map(applyExpandedState));
    setLoading(false);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <div className={classes.header}>Are You Sure You Want To Remove</div>
        {currentNode && (
          <div className={classes.title}>{currentNode.title}</div>
        )}
        <div className={classes.container}>
          <Button
            variant="contained"
            className={classes.cancel}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.delete}
            onClick={onDeleteCategory}
          >
            Proceed
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteTaskCategory;
