import React from 'react';
import { makeStyles } from '@mui/styles';
import { Spinner } from '../../../../shared/components/utils/_spinner';

const useStyles = makeStyles({
  container: (props) => ({
    height: props.height,
  }),
});

const Loader = ({ height = 140 }) => {
  const classes = useStyles({ height });

  return (
    <div className={classes.container}>
      <Spinner />
    </div>
  );
};

export default Loader;
