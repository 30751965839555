import React, { useState, useEffect } from 'react';
import { Table, TableContainer, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportBody from './ReportBody';
import ReportHeader from '../../firm_tasks_reports/report/ReportHeader';

const useStyles = makeStyles({
  container: {
    marginTop: 30,
  },
  range: {
    color: '#23282C',
    fontWeight: 500,
    marginBottom: 10,
    marginLeft: 14,
  },
  cell_large: {
    width: '300px',
  },
  cell: {
    width: '130px',
    textAlign: 'center',
  },
  date_range: {
    fontSize: '20px',
    marginLeft: '5px',
  },
});

const Report = ({ type, results, resultsRange }) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const generateHeaders = () => {
    if (!results || results.length === 0) {
      setHeaders([]);
      return;
    }

    let dynamicHeaders = ['Firm', 'Location'];

    if (type === 'week') {
      const firstResultDaily = results[0].locations[0].daily;
      firstResultDaily.forEach((day) => {
        const dateKey = Object.keys(day).find(
          (key) => key !== 'customer_hours'
        );
        dynamicHeaders.push(dateKey, 'Customer Hours');
      });
    }

    dynamicHeaders.push('Total Pages', 'Total Hours');

    if (type === 'month') {
      dynamicHeaders.push('Total Invoice');
    }

    setHeaders(dynamicHeaders);
  };

  useEffect(() => {
    generateHeaders();
  }, [results]);

  useEffect(() => {
    setReportData(results);
  }, [results]);

  return (
    <div className={classes.container}>
      <p className={classes.range}>{resultsRange}</p>
      <TableContainer component={Paper} sx={{ maxHeight: 590 }}>
        <Table stickyHeader sx={{ minWidth: 900 }}>
          <ReportHeader headers={headers} />
          <ReportBody results={reportData} type={type} />
        </Table>
      </TableContainer>
    </div>
  );
};

export default Report;
