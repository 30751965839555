import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Modal } from '@mui/material';
import { useFirmTasks } from '../../../../../../../context/firm_tasks';
import AcknowledgementList from './AcknowledgementList';
import { Spinner } from '../../../../../../../../shared/components/utils/_spinner';
import ErrorMessage from '../../../../../../../reports/data_reports/report/ErrorMessage';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    backgroundColor: '#f8f8ff',
    padding: 20,
    borderRadius: '5px',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
  },
  name: {
    fontSize: 20,
    marginLeft: 10,
    color: '#00000069',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  close: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
});

const PendingAcknowledgements = ({ open, task, handleClose }) => {
  const classes = useStyles();
  const { loadingPending, pendingAcknowledgements } = useFirmTasks();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <div style={{ marginBottom: 20 }}>
          <span className={classes.header}>Pending Acknowledgements:</span>
          <span className={classes.name}>{task?.name}</span>
          {loadingPending ? (
            <Spinner height={150} />
          ) : pendingAcknowledgements.length > 0 ? (
            <AcknowledgementList task={task} items={pendingAcknowledgements} />
          ) : (
            <ErrorMessage
              height={200}
              message="All items have been acknowledged. You may now continue or complete this task."
            />
          )}
        </div>
        <div className={classes.button}>
          <Button
            variant="contained"
            className={classes.close}
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default PendingAcknowledgements;
