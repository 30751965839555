import React, { useState, useEffect } from 'react';
import { useFirmsCtx } from '../context';
import { useGlobal } from '../../../context/global';
import { makeStyles } from '@mui/styles';
import { TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { API_MANAGE_ACCOUNTS } from '../../../../const/api_paths';
import { doAuthenticatedPost } from '../../../../actions/_methods';

const useStyles = makeStyles((theme) => ({
  form_wrapper: {
    display: 'flex',
    width: '500px',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const NewAccountForm = ({
  value,
  onChange,
  onSaveLocal,
  onCancel,
  classes,
  disableButton,
}) => (
  <div className={classes.form_wrapper}>
    <TextField
      variant="outlined"
      size="small"
      label="Account Name"
      value={value}
      onChange={onChange}
    />
    <Button
      variant="contained"
      color="primary"
      size="small"
      className={classes.button}
      startIcon={<SaveIcon />}
      onClick={onSaveLocal}
      disabled={disableButton}
    >
      Save
    </Button>
    <Button
      variant="contained"
      color="secondary"
      size="small"
      className={classes.button}
      startIcon={<CancelIcon />}
      onClick={onCancel}
    >
      Cancel
    </Button>
  </div>
);

const AddNewAccountButton = ({ setFormVisible, classes }) => (
  <Button
    size="small"
    variant="contained"
    startIcon={<AddIcon />}
    onClick={() => setFormVisible(true)}
  >
    Add New Account
  </Button>
);

export default ({ firm }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { setIsLoading, reloadData } = useFirmsCtx();
  const [value, setValue] = useState('');
  const [form_visible, setFormVisible] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const onSaveLocal = () => {
    setIsLoading('secondary');

    const data = {
      name: value.trim(),
      firm_id: firm.id,
    };

    doAuthenticatedPost(API_MANAGE_ACCOUNTS, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError,
    });
  };

  const onSuccess = (res) => {
    const message = `Successfully added account: ${res.account.name}`;

    setValue('');
    reloadData('secondary');
    setFormVisible(false);
    prompt(message);
  };

  const onError = (res) => {
    alert(`Please try again...`);
  };

  const onCancel = () => {
    setValue('');
    setFormVisible(false);
  };

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);
  };

  useEffect(() => {
    setDisableButton(value.trim() === '');
  }, [value]);

  return form_visible ? (
    <NewAccountForm
      value={value}
      onChange={onChange}
      onSaveLocal={onSaveLocal}
      onCancel={onCancel}
      classes={classes}
      disableButton={disableButton}
    />
  ) : (
    <AddNewAccountButton setFormVisible={setFormVisible} classes={classes} />
  );
};
