import React from "react";
import { makeStyles } from '@mui/styles';
import {
  Stack,
  Avatar,
  Chip,
} from '@mui/material';
const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '24px',
    height: '24px',
    marginLeft: '4px',
    '& .MuiChip-avatar': {
      
    }
  },
}));
export default ({task}) => {
  const classes = useStyles();

  const AvatarComponent = ({org}) => {
    const avatar = org.avatar ? <Avatar src={org.avatar} className={classes.avatar}>{org.code}</Avatar> : <Avatar className={classes.avatar}>{org.code}</Avatar>;
    return (avatar)
  };

  const OrgComponent = ({org}) => {
    const org_component = org != null ? <Chip
      variant="outlined"
      avatar={<AvatarComponent org={org} />}
      label={org.name}
    /> : ''
    return (org_component)
  };

  return(
    <Stack direction="row" spacing={1} marginBottom={1}>
      {task.organizational_structure.map(org => (
        <OrgComponent />
      ))}
    </Stack>
  )
}
