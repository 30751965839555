import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default ({onMoveStep}) => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {

    const test_accounts = [
      {
        name: "Account 1",
        locations: [
          {name: "Loc 1"},
          {name: "Loc 2"},
          {name: "Loc 3"},
          {name: "Loc 4"},
        ]
      },
      {
        name: "Account 2",
        locations: [
          {name: "Loc 2 - 1"},
          {name: "Loc 2 - 2"},
          {name: "Loc 2 - 3"},
          {name: "Loc 2 - 4"},
        ]
      }
    ];

    setAccounts(test_accounts);


  }, []);

  return (
    <>
      <h1>Set Accounts and Locations</h1>

      {accounts.map((account) => {
        return(
          <Card sx={{marginBottom: "20px", padding: "15px", display: "flex"}}>
            <div style={{width: "200px"}}>
              <b>{account.name}</b>
            </div>
            <div style={{flexGrow: "1"}}>
              {account.locations.map((location) => {
                return(
                  <>
                    <div>

                    </div>
                    <Box style={{width: '100%', marginTop: "10px"}}>
                      <Paper elevation={1} style={{width: '100%', padding: "5px 15px"}}>
                        <b>{location.name}</b>
                      </Paper>
                    </Box>
                  </>
                )
              })}
            </div>
          </Card>
        )
      })}

      <Button
        variant="contained"
        onClick={() => onMoveStep(3)}
      >Move to Step 3</Button>
    </>
  )

}
