import React from 'react';
import { makeStyles } from '@mui/styles';
import { TableBody, TableCell, TableRow } from '@mui/material';

const useStyles = makeStyles({
  cell: {
    width: '130px',
    textAlign: 'center',
  },
  firm: {
    textAlign: 'center',
    borderBottom: 'none',
  },
});

const tableRowStyles = {
  '&:last-child td, &:last-child th': {
    border: 0,
  },
};

const ReportBody = ({ results, type }) => {
  const classes = useStyles();

  const renderRow = (data) => (
    <TableRow key={data.id} sx={tableRowStyles}>
      <TableCell className={classes.cell}></TableCell>
      <TableCell className={classes.cell}>{data.location}</TableCell>
      {renderDaily(data.daily)}
      {renderTotal(data.total)}
    </TableRow>
  );

  const renderDaily = (daily) => {
    return daily.map((day, index) => {
      const customerHours = day.customer_hours;
      const [date, processedPages] = Object.entries(day).find(
        ([key, _]) => key !== 'customer_hours'
      );

      return (
        <React.Fragment key={index}>
          <TableCell className={classes.cell}>{processedPages}</TableCell>
          <TableCell className={classes.cell}>{customerHours}</TableCell>
        </React.Fragment>
      );
    });
  };

  const renderTotal = (total) => {
    const totalPages = total.pages;
    const totalHours = total.hours;
    const totalInvoice = total.invoice;

    return (
      <React.Fragment>
        <TableCell className={classes.cell}>{totalPages}</TableCell>
        <TableCell className={classes.cell}>{totalHours}</TableCell>
        {type === 'month' && (
          <TableCell className={classes.cell}>{totalInvoice}</TableCell>
        )}
      </React.Fragment>
    );
  };

  return (
    <TableBody>
      {results.map((firmData) => (
        <React.Fragment key={firmData.id}>
          <TableRow sx={tableRowStyles}>
            <TableCell className={classes.firm}>{firmData.firm}</TableCell>
          </TableRow>
          {firmData.locations.map((location) => renderRow(location))}
        </React.Fragment>
      ))}
    </TableBody>
  );
};

export default ReportBody;
