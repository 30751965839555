import React, { useEffect, useState } from 'react';

import {
  doAuthenticatedPut,
  doAuthenticatedGet,
} from '../../../../../../actions/_methods';
import { API_TASKS } from '../../../../../../const/api_paths';
import { useFirmTasks } from '../../../../../context/firm_tasks';

import TaskRow from './_TaskRow';
import TaskInfo from './_TaskInfo';

export default ({ task, is_open = false }) => {
  const { firmTasks, setFirmTasks } = useFirmTasks();
  const [local_task, setLocalTask] = useState(null);
  const [show_task_info, setShowTaskInfo] = useState(is_open);
  const [task_details, setTaskDetails] = useState(null);
  const [refreshing_task, setRefreshingTask] = useState(false);

  const onToggleShowTaskInfo = (is_show_task_info) => {
    if (is_show_task_info) {
      refreshTask();
    }

    setShowTaskInfo(is_show_task_info);
  };

  const updateFirmTasks = (updatedTask) => {
    const updatedFirmTasks = firmTasks.map((task) => {
      if (task.id === updatedTask.id) {
        return updatedTask;
      }
      return task;
    });

    setFirmTasks(updatedFirmTasks);
  };

  const refreshTask = () => {
    setRefreshingTask(true);
    doAuthenticatedGet(
      `${API_TASKS}/${task.id}`,
      {},
      {
        success: onRefreshTask,
        general: onRefreshTask,
        error: (res) => {
          alert('Please try again');
          setRefreshingTask(false);
        },
      }
    );
  };

  const onChange = (data) => {
    doAuthenticatedPut(`${API_TASKS}/${task.id}`, data, {
      success: (res) => onTaskUpdate(res.task),
      general: (res) => onTaskUpdate(res.task),
      error: (res) => alert('Please try again'),
    });
  };

  const onTaskUpdate = (updated_task) => {
    setLocalTask(updated_task);
    updateFirmTasks(updated_task);
    // onHideTaskInfo();
  };

  const onTaskDelete = (deleted_task) => {
    setShowTaskInfo(false);
    setLocalTask(null);
  };

  const onHideTaskInfo = () => {
    setShowTaskInfo(false);
  };

  const onRefreshTask = ({ task }) => {
    setLocalTask(task);
    setTaskDetails(task);
    setRefreshingTask(false);
  };

  const reloadTask = () => {
    refreshTask();
  };

  useEffect(() => {
    setLocalTask(task);
    setTaskDetails(task);
  }, [task]);

  return (
    <>
      {local_task && (
        <>
          <TaskRow
            task={local_task}
            show_task_info={show_task_info}
            onToggleShowTaskInfo={onToggleShowTaskInfo}
            onChange={onChange}
          />

          {show_task_info && !refreshing_task && (
            <TaskInfo
              task={task_details}
              onHide={onHideTaskInfo}
              onTaskDelete={onTaskDelete}
              onTaskUpdate={onTaskUpdate}
            />
          )}
          {show_task_info && refreshing_task && <div>loading...</div>}
          <a id={`task_reloader_${task.id}`} onClick={reloadTask}></a>
        </>
      )}
    </>
  );
};
