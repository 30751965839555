import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { doAuthenticatedPost } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS_ADD_RULE } from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({firm_id, task, onSetRuleSuccess}) => {
  const [rule, setRule] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setRule(e.target.value);
  };

  const onSubmit = () => {
    const url = API_MANAGE_PROJECTS_ADD_RULE.replace(':firm_id', firm_id)
    const data = { rule: rule, task_id: task.id };

    setSubmitting(true);
    doAuthenticatedPost(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  };

  const onSuccess = (res) => {
    dispatch(
      projectActions.updateCategoryTask({ task: res.task })
    )

    setRule('')
    setSubmitting(false)
    onSetRuleSuccess(res.task)
  };

  const onError = (res) => {
    alert('Please try again')
    setSubmitting(false)
  };

  return (
    <div style={{display: 'flex'}}>
      <TextField
        label="New Rule"
        variant="outlined"
        value={rule}
        onChange={handleChange}
        disabled={submitting}
        size="small"
        style={{flexGrow: '1'}}
      />
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={submitting}
        style={{marginLeft: '10px'}}
      >Add Rule</Button>
    </div>
  )
}