import React from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  box: {
    flexBasis: '7%',
    marginRight: '2px',
  },
  paper: {
    padding: '10px',
    minHeight: '44px',
    height: '100%',
    textAlign: 'center',
    flexBasis: '7%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default ({ task, field }) => {
  const classes = useStyles();

  const readableDueDate = (datetime) => {
    const res = moment(datetime).format('MMM D');
    if (res === 'Invalid date') {
      return '';
    } else {
      return res;
    }
  };

  return (
    <Box className={classes.box}>
      <Paper square className={classes.paper}>
        <Typography>{readableDueDate(task[field])}</Typography>
      </Paper>
    </Box>
  );
};
