import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import {
  TextField,
  Box,
  Button,
  Stack,
  CardContent,
  Card,
  CardHeader,
} from "@mui/material";

import { doAuthenticatedGet } from '../../../actions/_methods';
import { API_PATH, DAILY_TASK_REPORTS } from '../../../const/api_paths'
import Results from './components/_Results';
import InputPeople from '../../board/components/_basic_filters/InputPeople';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '10px',
    border: '1px solid #9f9f9f',
  },
  buttons: {
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'flex-end',
  }
}));

export default () => {
  const classes = useStyles();
  const [results, setResults] = useState({})
  const [date_range_str, setDateRangeStr] = useState('')
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    doFilter(page)
  }, []);

  const doFilter = (page) => {
    setLoading(true)
    var target_url = `${DAILY_TASK_REPORTS}?${dataToParams()}`
    doAuthenticatedGet(target_url, {}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    })
  }

  const handleFilter = () => {
    setPage(1)
    doFilter(1)
  }

  const onSuccess = (res) => {
    setLoading(false)
    setResults(res['data'])
    setLoaded(true)
  }

  const onError = (res) => {
    setLoading(false)
    alert('Ooops... Please try again...')
  }

  const onPaginate = (event, value) => {
    setPage(value);
    doFilter(value);
  }

  const handleGenerateReport = () => {
    console.log("Generate Report");
    console.log(filters)

    const target_url = API_PATH(`${DAILY_TASK_REPORTS}.csv?${dataToParams()}`);
    console.log(target_url)
    window.location = target_url
  };

  const dataToParams = () => {
    const new_data = {...filters};
    Object.keys(new_data).forEach(key => {
      if (new_data[key] === null) {
        delete new_data[key];
      }
    });

    return new URLSearchParams(new_data).toString();
  }

  const handleChangeWeek = (event) => {
    console.log(event.target.value);
    setFilters({...filters, day: event.target.value});
  };

  const handleChange = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  return (
    <Card className={classes.card}>
      <CardHeader title="Tasks Weekly Count"/>
      <CardContent>
        <Box >
          <Stack direction="row" spacing={2}>
            <TextField
              label="Date Range"
              type="date"
              sx={{ width: "25%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              onChange={handleChangeWeek}
            />
          </Stack>
          <Stack direction="row" spacing={2} className={classes.buttons}>
            <Button variant="contained" color="primary" onClick={handleFilter}>
              Filter
            </Button>
            {/* <Button variant="contained" color="primary" onClick={handleGenerateReport}>
              Download Report
            </Button> */}
          </Stack>
        </Box>
        {loading && <Stack mt={2}>Loading... </Stack>}
        {!loading && loaded && <Results results={results} />}
      </CardContent>
    </Card >
  );
}