import React from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Avatar, TextField } from '@mui/material';

const useStyles = makeStyles({
  input: {
    width: '100%',
  },
  avatar_wrapper: {
    '& > div': {
      width: '28px',
      height: '28px',
      marginRight: '10px',
    },
  },
});

const option_style = {
  display: 'flex',
  fontSize: '11pt',
  padding: '10px',
  cursor: 'pointer',
  borderBottom: '1px solid #555',
  marginBottom: '0',
};

const ReportDropdown = ({
  label,
  value,
  onChange,
  options,
  disabled,
  dropdownClass,
  noOptionsMessage,
}) => {
  const classes = useStyles();
  const hasOptions = options.length > 0;
  const selectingFirm = label === 'Firm';

  const OptionComponent = ({ option, props }) => {
    const avatar = option.avatar ? (
      <Avatar src={option.avatar}>{option.code}</Avatar>
    ) : (
      <Avatar>{option.code}</Avatar>
    );
    return (
      <div style={option_style} {...props}>
        <span className={classes.avatar_wrapper}>{avatar}</span>
        {option.name}
      </div>
    );
  };

  return (
    <div className={dropdownClass}>
      <Autocomplete
        size="small"
        value={value}
        options={options}
        disabled={disabled}
        onChange={onChange}
        className={classes.input}
        getOptionLabel={(option) => option.name}
        noOptionsText={hasOptions ? null : noOptionsMessage}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            required={selectingFirm}
          />
        )}
      />
    </div>
  );
};

export default ReportDropdown;
