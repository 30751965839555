import React from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default ({loading}) => {
  if(loading) {
    return(
      <>
        <div style={{marginTop: '40px'}}>
          <Stack spacing={0}>
            <Skeleton variant="rectangular" width={'100%'} height={50} style={{marginTop: '2px'}} />
            <Skeleton variant="rectangular" width={'100%'} height={50} style={{marginTop: '2px'}} />
            <Skeleton variant="rectangular" width={'100%'} height={50} style={{marginTop: '2px'}} animation="wave" />
          </Stack>
        </div>
        <div style={{marginTop: '40px'}}>
          <Stack spacing={1}>
            <Skeleton variant="rectangular" width={'100%'} height={50} style={{marginTop: '2px'}} />
            <Skeleton variant="rectangular" width={'100%'} height={50} style={{marginTop: '2px'}} animation="wave" />
          </Stack>
        </div>
        <div style={{marginTop: '40px'}}>
          <Stack spacing={1}>
            <Skeleton variant="rectangular" width={'100%'} height={50} style={{marginTop: '2px'}} />
            <Skeleton variant="rectangular" width={'100%'} height={50} style={{marginTop: '2px'}} />
            <Skeleton variant="rectangular" width={'100%'} height={50} style={{marginTop: '2px'}} animation="wave" />
          </Stack>
        </div>
      </>
    )
  } else {
    return null
  }
}