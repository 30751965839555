import loadable from '@loadable/component';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import _ from 'lodash';
import 'react-quill/dist/quill.snow.css'

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';

import { doAuthenticatedPut } from '../../../../../actions/_methods';
import {
  API_MANAGE_PROJECTS_UPDATE_TASK_DESCRIPTION
} from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

const ReactQuill = loadable(() => import("react-quill"))

export default ({task}) => {
  const dispatch = useDispatch();
  const [selecting, setSelecting] = useState(false);
  const [description, setDescription] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setDescription(task.description);
  }, []);

  const handleChange = (e) => {
    setDescription(e);
  };

  const onSubmit = () => {
    const url = API_MANAGE_PROJECTS_UPDATE_TASK_DESCRIPTION.replace(':firm_id', task.firm_id)
    const data = { description: description, task_id: task.id };

    setSubmitting(true);
    doAuthenticatedPut(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  };

  const onSuccess = (res) => {
    dispatch(
      projectActions.updateCategoryTask({ task: res.task })
    )

    setSubmitting(false)
    setSelecting(!selecting)
    enqueueSnackbar('You have successfully UPDATED description task.', {variant: 'success'});
  };

  const onError = (res) => {
    enqueueSnackbar('Please try again', {variant: 'error'})
    setSubmitting(false)
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
  ]

  return (
    <>
    <Button onClick={() => setSelecting(!selecting)}>Manage Description</Button>
    {selecting && <>
        <Box style={{ width: '100%'}}>
        <Paper elevation={3} style={{width: '100%', padding: "15px 20px"}}>
          <ReactQuill
            theme='snow'
            value={description}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            style={{ height: '300px', width: '100%' }}
          />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '55px' }}>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={submitting}
            >Save</Button>
          </div>
        </Paper>
      </Box>
    </>}
    </>
  )
}
