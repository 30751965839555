import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Paper, Typography } from '@mui/material';
import { usePermission } from '../../../../../context/permission';
import { useFirmTasks } from '../../../../../context/firm_tasks';
import Organizational from './row/Organizational';
import AssignedTo from './row/AssignedTo';
import Element from './row/_Elem';
import Name from './row/Name';
import MultiTimeTracker from './row/multi_time_tracker/Main';
import BasicStatus from './row/multi_time_tracker/BasicStatus';
import PendingAcknowledgements from '../../../../../manage/firms/components/accounting-task/table/manage-card/pending-items/PendingAcknowledgements';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    marginBottom: '2px',
  },
  box: {
    width: '60px',
    marginRight: '2px',
  },
  paper: {
    padding: '10px 5px',
    minHeight: '44px',
    height: '100%',
    textAlign: 'center',
  },
}));

export default ({ task, show_task_info, onToggleShowTaskInfo, onChange }) => {
  const classes = useStyles();
  const { isAngel } = usePermission();
  const {
    preferences,
    fetchReferences,
    showPendingItems,
    setShowPendingItems,
  } = useFirmTasks();
  const showPageCount = preferences.show_page_count === 'true';
  const isAccountingTask = task?.accounting_project_task_id !== null;

  useEffect(() => {
    if (isAccountingTask && show_task_info) fetchReferences(task);
  }, [task]);

  return (
    <Box className={classes.row} key={`task-row-${task.id}`}>
      <Name
        task={task}
        onShowDetail={() => onToggleShowTaskInfo(!show_task_info)}
      />
      <Organizational task={task} />
      <Element type={'dates'} task={task} field={'due_date'} />
      {!show_task_info && <BasicStatus task={task} field={`status`} />}
      {show_task_info && <MultiTimeTracker task={task} onChange={onChange} />}
      <AssignedTo task={task} />
      <Element type={'hours'} task={task} field={'work_hours'} />
      {showPageCount && (
        <>
          <Element type={'hours'} task={task} field={'last_count'} />
          <Element type={'hours'} task={task} field={'processed_count'} />
          <Element type={'hours'} task={task} field={'remaining_count'} />
        </>
      )}
      {isAngel() && (
        <>
          <Element type={'hours'} task={task} field={'billable_hours'} />
          <Element type={'hours'} task={task} field={'admin_est_hours'} />
          <Element type={'hours'} task={task} field={'client_est_hours'} />
        </>
      )}

      <PendingAcknowledgements
        open={showPendingItems}
        task={task}
        handleClose={() => setShowPendingItems(false)}
      />
    </Box>
  );
};
