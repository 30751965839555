import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import _ from 'lodash';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import AddRule from './_add_rule';
import TaskRule from './_task_rule';

import { doAuthenticatedPost } from '../../../../../actions/_methods';
import {
  API_MANAGE_PROJECTS_SET_USERS
} from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({task}) => {
  const dispatch = useDispatch();
  const [selecting, setSelecting] = useState(false);
  const [rules, setRules] = useState([]);

  useEffect(() => {
    setRules(task.rules);
  }, []);

  const onSetRuleSuccess = (task) => {
    setRules(task.rules);
  };

  return (
    <>
    <Button onClick={() => setSelecting(!selecting)}>Manage Rules</Button>
    {selecting && <>
      <Box style={{width: '100%', marginBottom: "15px"}}>
        <Paper elevation={3} style={{width: '100%', padding: "15px 20px"}}>
          {rules.map((rule) => {
            return(
              <TaskRule rule={rule} onSetRuleSuccess={onSetRuleSuccess}/>
            )
          })}
          <br />
          <AddRule firm_id={task.firm_id} task={task} onSetRuleSuccess={onSetRuleSuccess}/>
        </Paper>
      </Box>
    </>}
    </>
  )
}
