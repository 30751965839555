import React, {useState} from "react"
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';

import { doAuthenticatedPut } from '../../actions/_methods'
import { API_PROFILE_PASSWORD, } from '../../const/api_paths'

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '10px',
    width: '810px',
  },
  buttons_section: {
    marginTop: '20px',
  },
}));

export default ({modeSwither}) => {
  const classes = useStyles();

  const [old_password, setOldPassword] = useState('')
  const [new_password, setNewPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')
  const [error_message, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onClick = () => {
    setSubmitting(true)
    setErrorMessage('')
    doAuthenticatedPut(API_PROFILE_PASSWORD, {old_password: old_password, new_password: new_password, confirm_password: confirm_password}, {
      success: onSuccess,
      error: onError
    })
  }

  const onSuccess = (res) => {
    setSubmitting(false)
    resetPassword()
    alert(res.message)
  }

  const resetPassword = () => {
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  const onError = (res) => {
    setSubmitting(false)
    resetPassword()
    setErrorMessage(res.error)
  }

  const oldPassword = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="signin_email"
      label="Old Password"
      name="old_password"
      type="password"
      autoFocus
      variant="outlined"
      disabled={submitting}
      value={old_password}
      onChange={(e) => setOldPassword(e.target.value)}
    />
  )

  const newPassword = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="new_password"
      label="New Password"
      name="new_password"
      type="password"
      variant="outlined"
      disabled={submitting}
      value={new_password}
      onChange={(e) => setNewPassword(e.target.value)}
    />
  )
  const confirmPassword = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="confirm_password"
      label="Confirm Password"
      name="confirm_password"
      type="password"
      variant="outlined"
      disabled={submitting}
      value={confirm_password}
      onChange={(e) => setConfirmPassword(e.target.value)}
    />
  )

  const changePasswordButton = (
    <Button
      fullWidth
      variant="contained"
      size="large"
      color="primary"
      disabled={submitting}
      onClick={onClick}
    >
      Change Password
    </Button>
  )

  const errorMessage = () => {
    if(error_message && error_message !== '') {
      return(
        <Alert severity="error">{error_message}</Alert>
      )
    }
  }

  return(
    <Card className={classes.box}>
      <CardHeader title="Change Password" />
      <CardContent>
        {errorMessage()}
        <Box>{oldPassword}</Box>
        <Box>{newPassword}</Box>
        <Box>{confirmPassword}</Box>
        <Box className={classes.buttons_section}>{changePasswordButton}</Box>
      </CardContent>
    </Card>
  )
}