import React from 'react';
import { makeStyles } from '@mui/styles';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles({
  header: {
    background: 'red',
  },
  title: {
    color: '#23282C',
    fontWeight: 500,
    textAlign: 'center',
  },
});

const rows = [
  'Firm',
  'Task',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Total',
];

const ReportHeader = () => {
  const classes = useStyles();

  return (
    <TableHead className={classes.header}>
      <TableRow>
        {rows.map((row) => {
          return (
            <TableCell key={row} className={classes.title}>
              {row}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default ReportHeader;
