import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Card   from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import ArrowDropDownIcon  from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon    from '@mui/icons-material/ArrowDropUp';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    diplay: 'flex',
    flexGrow: 1,
    marginTop: '10px'
  },
  element_row: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: '10px'
  },
  title_group_row: {
    marginBottom: '10px',
  },
  textfield: {
    width: '100%',
  },
  card_wrapper: {
    padding: '10px',
  }
}));


export default ({onSubmit}) => {
  const classes = useStyles();
  const [show_more, setShowMore] = useState(false);

  const [title, setTitle]     = useState('');
  const [label_1, setLabel1]  = useState('');
  const [label_2, setLabel2]  = useState('');
  const [message, setMessage] = useState('');
  const [title_custom, setTitleCustom] = useState('');

  const customTitle = () => {
    if (title != '') {
      return title;
    } else {
      return title_custom;
    }
  }

  const onClick = (e) => {
    const title_value = customTitle();
    const comment_value = `#NR ${title_value}:::${label_1}:::${label_2}:::${message}`;
    onSubmit(comment_value);
    setTitle('');
    setTitleCustom('');
    setLabel1('');
    setLabel2('');
    setMessage('');  
  }

  const MoreButton = () => (
    <Button
      size="small"
      endIcon={<ArrowDropDownIcon />}
      onClick={() => setShowMore(!show_more)}
    >
      show add nightly report
    </Button>
  )

  const LessButton = () => (
    <Button
      size="small"
      endIcon={<ArrowDropUpIcon />}
      onClick={() => setShowMore(!show_more)}
    >
      hide add nightly report
    </Button>
  )

  const ElementRow = ({label, value, setter}) => (
    <div className={classes.element_row}>
      <TextField
        label={label}
        size="small"
        type="text"
        className={classes.textfield}
        value={value}
        onChange={(e) => setter(e.target.value)}
      />
    </div>
  )

  const TextAreaRow = ({label, value, setter}) => (
    <div className={classes.element_row}>
      <TextField
        label={label}
        multiline
        size="small"
        type="text"
        className={classes.textfield}
        value={value}
        onChange={(e) => setter(e.target.value)}
      />
    </div>
  )

  const TitleGroupRow = () => (
    <div className={classes.title_group_row}>
      <InputLabel id="title-group-label">Issue Type</InputLabel>
      <Select
        labelId="title-group-label"
        value={title}
        label="Issue Type"
        onChange={(e) => setTitle(e.target.value)}
        className={classes.textfield}
      >
        <MenuItem value={''}></MenuItem>
        <MenuItem value={'Verify new vendor created'}>Verify new vendor created</MenuItem>
        <MenuItem value={'Verify invoice details'}>Verify invoice details</MenuItem>
        <MenuItem value={'Verify UoM'}>Verify UoM</MenuItem>
        <MenuItem value={'Verify Item created by AP'}>Verify Item created by AP</MenuItem>
        <MenuItem value={'New/Missing Items to be created and mapped by client'}>New/Missing Items to be created and mapped by client</MenuItem>
        <MenuItem value={'Verify GL Account'}>Verify GL Account</MenuItem>
        <MenuItem value={'Vendor is set up by GL Account instead of by Item (or vice versa)'}>Vendor is set up by GL Account instead of by Item (or vice versa)</MenuItem>
        <MenuItem value={"Can't access vendor portal to retrieve hard copy"}>Can't access vendor portal to retrieve hard copy</MenuItem>
        <MenuItem value={'Unable to read invoice/blurry/cut by scan'}>Unable to read invoice/blurry/cut by scan</MenuItem>
        <MenuItem value={'Vendor not available'}>Vendor not available</MenuItem>
        <MenuItem value={'Location not available'}>Location not available</MenuItem>
        <MenuItem value={'Receipt/Bank Expense'}>Receipt/Bank Expense</MenuItem>
        <MenuItem value={'Invoice already entered but with different total'}>Invoice already entered but with different total</MenuItem>
      </Select>
    </div>
  )

  const CustomElements = () => (
    <Card variant="outlined" className={classes.card_wrapper}>
      <TitleGroupRow />
      {ElementRow({label: "Custom Issue", value: title_custom, setter: setTitleCustom})}
      {ElementRow({label: "Vendor", value: label_1, setter: setLabel1})}
      {ElementRow({label: "Invoice Number", value: label_2, setter: setLabel2})}
      {TextAreaRow({label: "Message", value: message, setter: setMessage})}
      <Button
        variant="contained"
        size="small"
        onClick={onClick}
      >Add to Nightly Report</Button>
    </Card>
  )

  return(
    <div className={classes.wrapper}>
      {!show_more && <MoreButton />}
      {show_more && <LessButton />}
      {show_more && CustomElements()}
    </div>
  )
}