import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Header from './Header';
import Footer from './Footer';
import InputTags from './InputTags';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    backgroundColor: '#f8f8ff',
    padding: 16,
    borderRadius: '5px',
  },
});

const ApplyTags = ({ firm, open, handleClose }) => {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [values, setValues] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);

  const onCancel = () => {
    setValues(currentTags);
    handleClose();
  };

  useEffect(() => {
    setTags(firm.tags);
    setCurrentTags(firm.tags);
  }, [firm]);

  useEffect(() => {
    setValues(tags);
  }, [tags]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.box}>
        <Header firm={firm} />
        <InputTags
          tags={tags}
          setTags={setTags}
          values={values}
          setValues={setValues}
        />
        <Footer firm={firm} tags={tags} handleClose={onCancel} />
      </Box>
    </Modal>
  );
};

export default ApplyTags;
