import React from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const useStyles = makeStyles({
  container: {
    width: (props) => props.width,
  },
  input: {
    width: '100%',
    marginRight: '10px',
    '& > div > div': {
      padding: '8px 10px',
      fontSize: '12pt',
      minWidth: '85px',
    },
  },
});

const FilterDropdown = ({
  width,
  label,
  value,
  onChange,
  options,
  disabled,
  noOptionsMessage,
  onSelectNone,
  required = false,
}) => {
  const classes = useStyles({ width });
  const hasOptions = options.length > 0;

  return (
    <div className={classes.container}>
      <FormControl
        variant="outlined"
        disabled={disabled}
        className={classes.input}
        required={required}
      >
        <InputLabel size="small">{label}</InputLabel>
        <Select
          size="small"
          value={value || ''}
          displayEmpty
          onChange={onChange}
          name={label}
          label={label}
          renderValue={(selectedValue) => {
            const selectedOption = options.find(
              (option) => option.id === selectedValue.id
            );
            return selectedOption ? selectedOption.name : '';
          }}
          MenuProps={{
            PaperProps: {
              style: { maxHeight: 300, marginTop: 10 },
            },
          }}
        >
          {value && onSelectNone && (
            <MenuItem value="" onClick={onSelectNone}>
              <em>None</em>
            </MenuItem>
          )}
          {hasOptions ? (
            options.map((option) => (
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>{noOptionsMessage}</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterDropdown;
