import _ from 'lodash';

export const groupByFirm = (organizations, tasks) => {
  return groupTasks(organizations, tasks, 'firm');
}

export const groupByAccount = (organizations, tasks) => {
  return groupTasks(organizations, tasks, 'account');
}

export const groupByLocation = (organizations, tasks) => {
  return groupTasks(organizations, tasks, 'location');
}

export const groupTasks = (groupings, tasks, column) => {
  const grouped_tasks = _.groupBy(tasks, `${column}_id`);
  const data = groupings.map((g) => {
    var gtasks = grouped_tasks[g.id];
    if(gtasks === undefined) { gtasks = [] }

    return {
      id:     g.id,
      title:  g.name,
      tasks:  gtasks
    }
  });

  return _.sortBy(data, 'title')
}

export const extractFirms = (firm_id, organizations) => {
  var grouping_data = [];
  organizations.forEach(o => {
    if(firm_id == o.id) {
      o.children.forEach(a => {
        grouping_data.push(a)
      })
    }
  });
  return grouping_data;
}


export const nullTasks = (tasks, column) => {
  const grouped_tasks = _.groupBy(tasks, `${column}_id`)

  var gtasks = grouped_tasks[null];
  if(gtasks === undefined) { gtasks = [] }
  return gtasks;
}