import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ManageUsersButton from './components/manage_users/ManageUsersButton';
import ManageUsers from './components/manage_users/ManageUsers';

import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
  },
}));

export default ({ path, name, report, superAngelUser }) => {
  const classes = useStyles();
  const [showAddUser, setShowAddUser] = useState(false);

  if (report !== undefined) {
    return (
      <ListItem className={classes.row}>
        <Button href={path} variant="contained">{name}</Button>
        {superAngelUser &&
          <>
            <ManageUsersButton setShowAddUser={setShowAddUser} report={report} />
            <ManageUsers
              report={report}
              open={showAddUser}
              handleClose={() => setShowAddUser(false)}
            />
          </>
        }
      </ListItem>
    );
  } else {
    return null
  }
};
