import React, { useState } from "react";

import Step1 from './step_1/';
import Step2 from './step_2/';
import Step3 from './step_3/';

export default () => {
  const [mode, setMode] = useState('step_1');

  const onMoveStep = (step_number) => {
    setMode(`step_${step_number}`);
  }

  return (
    <>
      {mode === 'step_1' && <Step1 onMoveStep={onMoveStep} />}
      {mode === 'step_2' && <Step2 onMoveStep={onMoveStep} />}
      {mode === 'step_3' && <Step3 onMoveStep={onMoveStep} />}
    </>
  )

}
