import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { doAuthenticatedPost } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS_ADD_TASK } from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({firm_id, category}) => {
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setName(category.name);
  }, []);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const onSubmit = () => {
    const url = API_MANAGE_PROJECTS_ADD_TASK.replace(':category_id', category.id)
    const data = { name: name, category_id: category.id, firm_id: firm_id };

    setSubmitting(true);
    doAuthenticatedPost(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError
    });
  };

  const onSuccess = (res) => {
    dispatch(
      projectActions.addCategoryTask({
        task:     res.task,
        category: category
      })
    )

    setName(category.name);
    setSubmitting(false)
  };

  const onError = (res) => {
    alert('Please try again')
    setSubmitting(false)
  };

  return (
    <div style={{display: 'flex'}}>
      <TextField
        label="New Task"
        variant="outlined"
        value={name}
        onChange={handleChange}
        disabled={submitting}
        size="small"
        style={{flexGrow: '1'}}
      />
      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={submitting}
        style={{marginLeft: '10px'}}
      >Add Task</Button>
    </div>
  )
}