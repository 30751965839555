import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import {
  Card,
  Box,
  TextField,
  Button,
  List,
  Typography,
} from '@mui/material';
import CheckListRow from "./_ChecklistRow";
import { doAuthenticatedPost } from "../../../../../../../actions/_methods";
import { API_TASK_ADD_CHECKLIST } from "../../../../../../../const/api_paths";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  box: {
    marginRight: '2px',
    width: '100%',
  },
  inner_box: {
    display: 'flex',
  },
  list: {
    width: '100%',
  },
  item: {
    paddingTop: '0',
    paddingBottom: '0',
    marginTop: '0',
    marginBottom: '0',
  },
  list_item: {
    paddingTop: '1px',
  },
  card: {
    display: 'flex',
    marginBottom: '25px',
  },
  card_title: {
    paddingLeft: '10px',
    paddingBottom: '10px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    marginRight: '10px',
  },
  check_icon: {
    size: 'medium',
    color: 'green',
    minWidth: '28px',
    padding: '0'
  },
  delete_icon: {
    fontSize: 'medium',
    color: 'red',
  },
}));

const name = 'checklist';
const title = 'Checklist';

export default ({ task, default_value }) => {
  const classes = useStyles();
  const [checklist, setChecklist] = useState([]);
  const [value, setValue] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setChecklist(default_value);
  }, [checklist]);

  const isEnter = (e) => {
    let key_code = (e.which || e.keyCode)
    return (key_code === 13 && !e.shiftKey)
  }

  const addChecklist = (new_val) => {
    let api_path = API_TASK_ADD_CHECKLIST.replace(':task_id', task.id)

    doAuthenticatedPost(api_path, { name: new_val }, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onDelete = (indx) => {
    let new_checklist = checklist;
    new_checklist.splice(indx, 1);
    setChecklist([]);
    // setChecklist(new_checklist);
  }

  const onSubmit = () => {
    const new_checklist = checklist;
    const new_val = value;
    addChecklist(new_val);
  }

  const onChangeRow = (res) => {
    let new_checklist = checklist.map(c => {
      if (c.id === res.id) {
        return {...c, ...res };
      }
      return { ...c };
    });
    console.log("new_checklist")
    console.log(new_checklist)
    setChecklist([])
    setChecklist(new_checklist)
  }

  const onChangeLocal = (e) => {
    setValue(e.target.value);
  }

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = checklist;
    return data;
  }

  const onSuccess = (res) => {
    const new_checklist = checklist;
    new_checklist.push(res);
    setChecklist(new_checklist);
    setValue('')
    enqueueSnackbar('You have successfully ADDED task checklist.', { variant: 'success' });
  }

  const onError = (res) => {
    enqueueSnackbar('An ERROR occured, please try again.', { variant: 'error' });
  }

  const DataList = () => {
    return (
      <Card className={classes.card}>
        <List className={classes.list}>
          <Typography component="div" className={classes.card_title}>
            Checklist
          </Typography>
          {checklist.map((row, indx) => (
            <CheckListRow default_value={row} indx={indx} onDelete={onDelete} onChangeRow={onChangeRow} />
          ))}
        </List>
      </Card>
    )
  }

  return (
    <Box className={classes.box}>
      {checklist.length > 0 && <DataList />}
      <Box className={classes.inner_box}>
        <TextField
          variant="outlined"
          size="small"
          className={classes.input}
          label={title}
          value={value}
          onChange={onChangeLocal}
          onKeyPress={(e) => {
            if (isEnter(e)) {
              e.preventDefault();
              onSubmit();
              return false
            }
          }}
        />
        <Button
          variant="contained"
          onClick={onSubmit}
          style={{ marginLeft: '10px' }}
        >Add</Button>
      </Box>
    </Box>
  )
}