import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  header: {
    margin: 10,
    display: 'flex',
    paddingBottom: 10,
    borderBottom: '1px solid #00000029',
  },
});

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h6" component="h2">
        Export Data to Google Sheets
      </Typography>
    </div>
  );
};

export default Header;
