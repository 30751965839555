import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';

import { doAuthenticatedDelete } from '../../../../../actions/_methods';
import { API_MANAGE_PROJECTS_DELETE_ACCOUNT } from '../../../../../const/api_paths';
import { projectActions } from '../../../../../store/project_slice';

export default ({account}) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onDelete = () => {

    if(window.confirm("Are you sure to delete this account?")) {
      setSubmitting(true);

      const url = API_MANAGE_PROJECTS_DELETE_ACCOUNT;
      const data = { id: account.id };

      doAuthenticatedDelete(url, data, {
        general: onSuccessDelete,
        success: onSuccessDelete,
        error: onErrorDelete
      });
    }
  }

  const onSuccessDelete = (res) => {
    dispatch(
      projectActions.removeAccount(account.id)
    );
    setSubmitting(false);
  }

  const onErrorDelete = (res) => {
    alert('Please try again');
    setSubmitting(false);
  }

  return (
    <>
      <b>{account.name}</b>
      <br />
      <DeleteIcon
        onClick={onDelete}
        disabled={submitting}
      />
    </>
  )

}
