import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import {
  Stack,
  Box,
  Card,
  CardContent,
  Button,
  TextareaAutosize,
  OutlinedInput
} from '@mui/material';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { doAuthenticatedPut } from "../../../../../../actions/_methods";
import { API_TASKS } from "../../../../../../const/api_paths";
import { useReferences } from "../../../../../context/references";

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    marginBottom: '20px',
  },
  inner_box: {
    display: 'flex',
    marginTop: '10px',
  },
  buttons_box: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .cancel-btn': {
      marginLeft: '15px'
    },
    '& .delete-btn': {
      marginLeft: '15px'
    }
  },
  stuck_button_box: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

export default ({ task, onTaskUpdate }) => {
  const classes = useStyles();
  const { stuck_options } = useReferences();

  const [show_form, setShowForm] = useState(false);
  const [show_unstuck, setShowStuck] = useState(false);
  const [stuck_reason, setStuckReason] = useState('');
  const [other_reason, setOtherReason] = useState('');
  const [stuck_reason_link,               setStuckReasonLink] = useState('');
  const [stuck_reason_action_needed,      setStuckReasonActionNeeded] = useState('');
  const [stuck_reason_action_needed_from, setStuckReasonActionNeededFrom] = useState('');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setStuckReason(task.stuck_reason_id)
    setOtherReason(task.stuck_reason_other)
    setStuckReasonLink(task.stuck_reason_link)
    setStuckReasonActionNeeded(task.stuck_reason_action_needed)
    setStuckReasonActionNeededFrom(task.stuck_reason_action_needed_from)

    var stats = (task.status == 'stucked')
    setShowStuck(stats)
  }, [stuck_options, task]);

  const onSave = () => {
    var data = { stuck_reason_id: stuck_reason, 
                 stuck_reason_other: other_reason,
                 stuck_reason_link: stuck_reason_link,
                 stuck_reason_action_needed: stuck_reason_action_needed,
                 stuck_reason_action_needed_from: stuck_reason_action_needed_from,
                 status: 'stucked' 
               }
    doAuthenticatedPut(`${API_TASKS}/${task.id}`, data, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onUnstuck = () => {
    if (window.confirm('Do you want to UNSTUCK the task?')) {
      doAuthenticatedPut(`${API_TASKS}/${task.id}/unstuck`, {}, {
        success: onSuccessUnstuck,
        general: onSuccessUnstuck,
        error: onError,
      });
    }
  }

  const onSuccess = (res) => {
    enqueueSnackbar('You have successfully UPDATED the task as STUCK.', { variant: 'success' });
    setShowForm(false)
    onTaskUpdate(res.task);
  }

  const onSuccessUnstuck = (res) => {
    enqueueSnackbar('You have successfully UNSTUCK the task.', { variant: 'success' });
    setShowForm(false)
    onTaskUpdate(res.task);
  }

  const onError = (res) => {
    enqueueSnackbar('An ERROR occured, please try again.', { variant: 'error' });
  }

  const handleChange = (e) => {
    setStuckReason(e.target.value)
  }

  const handleChangeOther = (e) => {
    setOtherReason(e.target.value)
  }

  const handleChangeStuckReasonLink = (e) => {
    setStuckReasonLink(e.target.value)
  }

  const handleChangeStuckReasonActionNeeded = (e) => {
    setStuckReasonActionNeeded(e.target.value)
  }

  const handleChangeStuckReasonActionNeededFrom = (e) => {
    setStuckReasonActionNeededFrom(e.target.value)
  }

  const UnstuckButton = (
    <Button variant="contained" color="info" onClick={onUnstuck}>Unstuck</Button>
  )

  return(
    <>
      <Stack direction="row" spacing={2}>
        {!show_form &&
          <Button variant="contained" color="warning" onClick={() => setShowForm(true)}>I'm Stuck!</Button>}
        {!show_form && show_unstuck && UnstuckButton}
      </Stack>
      {show_form &&
        <Card className={classes.box}>
          <CardContent>
            <Box className={classes.inner_box}>
              <FormControl fullWidth>
                <InputLabel shrink id="stuck-reason-select-label">Why are you Stuck?</InputLabel>
                <Select
                  labelId="stuck-reason-select-label"
                  id="stuck-reason-select"
                  value={stuck_reason}
                  label="Why are you stuck?"
                  onChange={handleChange}
                  displayEmpty
                  input={<OutlinedInput notched label="Why are you Stuck?" />}
                >
                  {stuck_options.map((o) => {
                    return <MenuItem key={`reason_${Math.random()}`} value={o.id}>{o.value}</MenuItem>
                  })}
                  <MenuItem value="0"><em>Others</em></MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className={classes.inner_box}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Details"
                style={{ width: '100%', padding: '10px' }}
                onChange={handleChangeOther}
                defaultValue={other_reason}
              />
            </Box>
            <Box className={classes.inner_box}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={1}
                maxRows={2}
                placeholder="Link"
                style={{ width: '100%', padding: '10px' }}
                onChange={handleChangeStuckReasonLink}
                defaultValue={stuck_reason_link}
              />
            </Box>

            <Box className={classes.inner_box}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={2}
                placeholder="Action Needed"
                style={{ width: '100%', padding: '10px' }}
                onChange={handleChangeStuckReasonActionNeeded}
                defaultValue={stuck_reason_action_needed}
              />
            </Box>

            <Box className={classes.inner_box}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={2}
                placeholder="Need action from"
                style={{ width: '100%', padding: '10px' }}
                onChange={handleChangeStuckReasonActionNeededFrom}
                defaultValue={stuck_reason_action_needed_from}
              />
            </Box>

            <Box className={classes.buttons_box}>
              <Button variant="contained" onClick={onSave}>Save</Button>
              <Button variant="contained" className="cancel-btn" onClick={() => setShowForm(false)}>Close</Button>
            </Box>
          </CardContent>
        </Card>
      }
    </>
  )
}