import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useReportsCtx } from '../../../context';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import UserListItem from './UserListItem';
import UserListHeader from './UserListHeader';
import NoUsersMessage from './NoUsersMessage';
import { useGlobal } from '../../../../context/global';

const useStyles = makeStyles({
  container: {
    margin: '0 30px 0 30px',
    border: '1px solid #00000029',
    borderRadius: 5,
    maxHeight: 335,
  },
});

const UserList = ({ report }) => {
  const classes = useStyles();
  const { users: globalUsers } = useGlobal();
  const { users: fetchedUsers, setUnassignedUsers, getAssignedUsers, assignedUsers } = useReportsCtx();
  const users = fetchedUsers.length > 0 ? fetchedUsers : globalUsers;

  // const rows = useMemo(
  //   () =>
  //     users?.flatMap((user) =>
  //       user.roles
  //         ?.filter((role) => role.firm_id === firm.id)
  //         .map((role) => ({
  //           label: user.label,
  //           role: role.name,
  //           id: user.id,
  //           role_id: role.id,
  //         }))
  //     ),
  //   [users, report]
  // );

  // const noUsers = rows && rows.length === 0;

  // useEffect(() => {
  //   getAssignedUsers(report)
  //   const assignedUserIds = new Set(assignedUsers.map((row) => row.id));
  //   const unassigned = users.filter(
  //     (user) => !assignedUserIds.has(user.id) && user.status === 'active'
  //   );

  //   setUnassignedUsers(unassigned);
  // }, [assignedUsers, users, setUnassignedUsers]);

  useEffect(() => {
    getAssignedUsers(report)
    const assignedUserIds = new Set(assignedUsers.map((row) => row.id));
    const unassigned = users.filter(
      (user) => !assignedUserIds.has(user.id) && user.status === 'active'
    );

    setUnassignedUsers(unassigned);
  }, []);

  return (
    <div className={classes.container}>
      <TableContainer sx={{ maxHeight: 335 }}>
        <Table>
          {assignedUsers.length == 0 ? (
            <NoUsersMessage />
          ) : (
            <>
              <UserListHeader/>
              <TableBody>
                {assignedUsers.map((row) => (
                  <UserListItem key={row.id} row={row} report={report} />
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserList;
