import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Typography, Paper, Divider, Button } from '@mui/material';

import InputText from '../../../board/components/_basic_filters/InputText';
import InputSelect from '../../../board/components/_basic_filters/InputSelect';
import InputAutocomplete from '../../../board/components/_basic_filters/InputAutocomplete';

export default ({ classes, default_filter, onChange, firms, roles }) => {
  const [data, setData] = useState({ ...default_filter });

  useEffect(() => {}, [roles]);

  const onChangeLocal = (new_data) => {
    setData({ ...data, ...new_data });
    // onChange({...data, ...new_data});
  };

  const applyFilter = () => {
    onChange(data);
  };

  const rolesOptions = (roles) => {
    return roles.map((r) => ({
      value: r,
      label: r,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      applyFilter();
    }
  };

  return (
    <Box onKeyPress={handleKeyPress}>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant={'h6'}>Filters</Typography>
        <Divider className={classes.margin_bottom} />
        <Box display="flex" justifyContent="space-between">
          <InputText
            width={'20%'}
            name="match_firstname"
            title="First Name"
            onChange={onChangeLocal}
          />
          <InputText
            width={'20%'}
            name="match_lastname"
            title="Last Name"
            onChange={onChangeLocal}
          />
          <InputText
            width={'20%'}
            name="match_email"
            title="Email"
            onChange={onChangeLocal}
          />
          <InputSelect
            width={'20%'}
            name="match_any_role"
            title="Role"
            options={rolesOptions(roles)}
            onChange={onChangeLocal}
            default_value=""
          />
          <InputAutocomplete
            width={'20%'}
            name="match_any_firm"
            title="Firm"
            options={firms}
            onChange={onChangeLocal}
            default_value=""
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" marginTop="15px">
          <Button variant="contained" color="success" onClick={applyFilter}>
            Apply Filter
          </Button>
        </Box>
        <a id="reload_all_users" onClick={() => onChange(data)}></a>
      </Paper>
    </Box>
  );
};
